<template>

    <div class="b-xs-12 align-center" v-show="temInformativo">
        <div class="informativos" style="width: 650px; margin-bottom: 15px; display:inline-block">
            <h1 class="titulo-info">Informativos SCI</h1>
            <div id="informativo-box">
            </div>
            <div class="botoes-informativo text-center">
                <a :href="linkInformativos" style="display:none" class="bt box-informativos bt-ver-todos-info">Ver todos</a>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: ['temInformativo', 'linkInformativos']
}

</script>