<template>
    <div id="login-check"></div>
</template>


<script>

export default {
    name: 'login-check',
    data() {
        return {
        }
    },
    mounted() {
        let router  = this.$router;
        let route   = this.$route;
        let sweet   = this.$sweet;
        let cookies = this.$cookies;
        let parser  = new UAParser();

        this.$socket.emit('login-auth', {
            atendimentoId: this.$route.query.i,
            pessoaId: this.$route.query.pessoa_id,
            loginAdicional: this.$route.query.login_adicional,
            ehAdicional: this.$route.query.eh_adicional,
            nome: this.$route.query.nome,
            from: this.$route.query.from,
            infraestrutura: this.$route.query.infra,
            linha: this.$route.query.linha
        }, (err, auth) => {
            if (err) {
                if (typeof err == 'object') {
                    sweet.alert('Atenção', 'Não foi possível se conectar com o servidor', 'error');
                } else {
                    sweet.alert(
                        'Atenção',
                        err,
                        'warning',
                        'OK',
                        true,
                        function() {
                            window.close();
                        }
                    );
                }
                return;
            }

            let ua = parser.getResult();

            auth.userAgent = `Browser: ${ua.browser.name} - v ${ua.browser.version}` +
                `OS: ${ua.os.name} - v ${ua.os.version}` +
                `UA: ${ua.ua}`;

            if (route.query.from === 'intranet' && auth.dados.pessoaId >= 80000) {
                auth.ehAtendente = true;
	            if (process.env.VUE_APP_ENV === 'dev') {
                    cookies.set('auth', auth);
                } else {
                    cookies.set('auth', auth, null, null, window.location.host);
                }

                this.$socket.emit('attendent-connect');
                router.push('/atendente');
            } else {
                auth.ehAtendente = false;
	            if (process.env.VUE_APP_ENV === 'dev') {
                    cookies.set('auth', auth);
                } else {
                    cookies.set('auth', auth, null, null, window.location.host);
                }

                router.push(
                    { 
                      path: '/cliente',
                     query: {
                         'sistemaLinha': this.$route.query.sistemaLinha 
                        }
                    }
                );
            }
        });
    }
};

</script>
