<template>

    <div id="box-avisos" v-if="temAvisos" style="display: none; width: 100%; max-width: 1200px; padding: 15px;">
        <h1 class="h1">Avisos importantes - (clique em cima do título para visualizar)</h1>
        <badger-accordion class="accordion">
            <badger-accordion-item v-for="aviso in avisos" :key="aviso.id" @open="marcaAvisoVisto(aviso.id)">
                <template slot="header">
                    <i class="aviso-novo" v-if="!verificaVisto(aviso)">NOVO!</i>
                    {{ converteDataAviso(aviso.dataMaior) }} - {{ aviso.titulo }}
                </template>
                <template slot="content">
                    <p v-html="aviso.texto"></p>
                </template>
                <br class="clear" />
            </badger-accordion-item>
        </badger-accordion>
    </div>

</template>

<script>

import * as _ from 'lodash';
import {BadgerAccordion, BadgerAccordionItem} from 'vue-badger-accordion';

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');

export default {
    props: ['temAvisos'],
    data() {
        let auth = this.$cookies.get('auth');

        return {
            pessoaId: auth.dados.pessoaId,
            avisos: [],
        }
    },
    components: {
        BadgerAccordion,
        BadgerAccordionItem,
    },
    methods: {
        verificaVisto: function (aviso) {
            return aviso.visualizados.indexOf(this.pessoaId) !== -1;
        },
        marcaAvisoVisto: function (id) {
            let vm = this;
            let auth = vm.$cookies.get('auth');

            _.forEach(this.avisos, function (obj) {
                if (obj.id == id) {
                    obj.visualizados.push(vm.pessoaId);

                    if (typeof auth.dados.usuarioAdicionalId != 'undefined') {
                        obj.visualizadosAdicionais.push(auth.dados.usuarioAdicionalId);
                    }
                }
            });

            this.$socket.emit('aviso-marca-visto', {pessoaId: auth.dados.pessoaId, avisoId: id});
        },
        converteDataAviso: function (val) {
            let date = val.split(' ');
            let data = exibe_data(date);

            if (typeof date[1] == 'undefined') return data;

            let hora = date[1];

            if (hora != '00:00:01' && hora != '23:59:59') return exibe_data(date, true);

            return data;
        },
        buscaAvisos: function () {
            let vm = this;
            let auth = this.$cookies.get('auth');

            vm.avisos = [];

            vm.$socket.emit('aviso-busca', auth, dados => {
                if (dados.avisos.length) vm.$parent.temAvisos = true;

                _.forEach(dados.avisos, function (obj) {
                    let visualizados = _.find(dados.visualizados, {avisoId: obj.id});
                    obj.texto = String(entities.decode(obj.texto)).replace(new RegExp('\r?\n','g'), '<br />');
                    obj.visualizados = [];

                    if (eh_aviso_interno(obj)) {
                        obj.texto = '<b style="color: red">Atenção: esse aviso está aparecendo apenas para funcionários'+
                            ' e/ou revendas da SCI. Essa mensagem não está visível para os clientes.</b><br />'+
                            obj.texto;
                    }

                    if (typeof visualizados != 'undefined') {
                        visualizados = _.map(visualizados.visualizados.split(','), Number);
                        obj.visualizados = visualizados;
                    }

                    vm.avisos.push(obj);
                });

                setTimeout(function () {
                    $('.bt-avisos').trigger('click');
                }, 500);
            });

        },
    },
    mounted() {
        let vm = this;

        $('#accordion').accordion();

        this.$nextTick(function () {
            vm.buscaAvisos();
        });

        this.$socket.on('aviso-busca', function () {
            $('.accordion dt').remove();
            $('.accordion dd').remove();

            vm.buscaAvisos();
        });
    },
}

</script>