<template>
    <div id="app">

        <svg aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <symbol id="icon-attachment" viewBox="0 0 32 32">
                    <title>attachment</title>
                    <path d="M20.807 10.22l-2.030-2.029-10.15 10.148c-1.682 1.681-1.682 4.408 0 6.089s4.408 1.681 6.090 0l12.18-12.178c2.804-2.802 2.804-7.346 0-10.148-2.802-2.803-7.347-2.803-10.149 0l-12.788 12.787c-0.009 0.009-0.019 0.018-0.027 0.026-3.909 3.909-3.909 10.245 0 14.153 3.908 3.908 10.246 3.908 14.156 0 0.009-0.009 0.016-0.018 0.026-0.027l0.001 0.001 8.729-8.728-2.031-2.029-8.729 8.727c-0.009 0.008-0.018 0.018-0.026 0.026-2.784 2.783-7.312 2.783-10.096 0-2.783-2.783-2.783-7.31 0-10.093 0.010-0.009 0.019-0.018 0.028-0.026l-0.001-0.002 12.79-12.786c1.678-1.679 4.411-1.679 6.090 0s1.678 4.411 0 6.089l-12.18 12.178c-0.56 0.56-1.47 0.56-2.030 0-0.559-0.559-0.559-1.47 0-2.029l10.15-10.149z"></path>
                </symbol>
            </defs>
        </svg>
        <!--[if IE]>
        <div class="alerta-navegador">
            <p>
                <i class="icon icon-warning"></i>
                Você está utilizando o navegador Internet Explorer para acessar a atendimento on-line.<br />
                Alguns recursos e funcionalidades disponíveis no atendimento on-line podem não funcionar
                corretamente com sua versão do Internet Explorer, pois ele é muito antiga.<br />
                Recomendamos que o acesso ao Integra NET seja realizado através de um dos navegadores a
                seguir: Google Chrome, Mozilla Firefox, Opera, Safari ou Microsoft Edge, com a versão
                mais atual disponível.
            </p>
        </div>
        <![endif]-->
        <div id="box-suporte">
            <a href="javascript:;" onclick="close_view_suporte()" class="bt-close-view-suporte">
                <i class="icon icon-close"></i>
            </a>
            <div id="box-view-suporte" ref="viewsuporte"></div>
        </div>
        <div id="box-conversa">
            <a href="javascript:;" onclick="close_view_conversa()" class="bt-close-view-suporte">
                <i class="icon icon-close"></i>
            </a>
            <div id="box-view-conversa" ref="viewconversa"></div>
        </div>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>

export default {
    name: 'app',
    data() {
        return {}
    },
    created() {
        this.verifica_login();
    },
    beforeUpdate() {
        this.verifica_login();
    },
    mounted() {
        if (('Notification' in window)) {
            Notification.requestPermission(function (permission) {
                if (!('permission' in Notification)) Notification.permission = permission;
            });
        }
    },
    updated() {
        let logado = this.$cookies.isKey('auth');

        if (!logado) document.getElementsByTagName('body')[0].className = 'login';

        if (this.$route.name != 'home' &&
            this.$route.name != 'login' &&
            this.$route.name != 'logout'
        ){
            document.getElementsByTagName('body')[0].classList.remove('login');
        } else {
            document.getElementsByTagName('body')[0].className = 'login';
        }
    },
    methods: {
        destroy: function () {
            this.$cookies.remove('auth');
            this.$cookies.remove('dados_form');
            this.$router.push('/');
        },
        verifica_login: function () {
            // Todas as telas vão passar por esse script antes de carregar o próprio view
            // Verifica se tem a session de login
            let route  = this.$route;
            let router = this.$router;
            let logado = this.$cookies.isKey('auth');

            if (!logado) {
                if (route.name != 'home' &&
                    route.name != 'login' &&
                    route.name != 'logout' &&
                    route.name != 'offline'
                ){
                    this.$sweet.alert('Atenção', 'Você precisa estar logado para acessar esta página', 'error');
                }

                if (route.name != 'home' &&
                    route.name != 'offline' &&
                    route.name != 'login'
                ){
                    router.push('/');
                }

                return;
            }

            // Só faz a verificação do login se não estiver nessas páginas
            if (route.name != 'home' &&
                route.name != 'login' &&
                route.name != 'logout'
            ){
                document.getElementsByTagName('body')[0].classList.remove('login');
            }

            window.addEventListener('beforeunload', this.destroy);

            this.$socket.on('disconnect', () => {
                if(route.name != 'home') {
                    const auth = this.$cookies.get('auth');
                    this.$sweet.alert('Atenção',
                        'Sua conexão do chat foi perdida. Por favor retorne ao chat que nós iremos dar prioridade para continuar o seu atendimento.',
                        'error',
                        'OK',
                        true,
                        () => {
                            if(auth.dados.pessoaId>80000){
                                
                                this.$router.push('/home');
                            }
                        }
                    );
                }
            });
        }
    }
};
</script>

<style>

</style>
