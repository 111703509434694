<template>
    <fieldset class="avaliacao-chat" :ref="'avaliacao'+atendimentoId">
        <legend>Avalie a conversa*</legend>
        <div class="campo fields">
            <div class="stars">
                <div class="star star1"></div>
                <div class="star star2"></div>
                <div class="star star3"></div>
                <div class="star star4"></div>
                <div class="star star5"></div>
            </div>
            <div class="label-star">
                {{ avaliacao }}
            </div>
            <form onsubmit="return false;">
                <input type="radio" name="avaliacao" class="s1" hidden value="Péssimo" />
                <input type="radio" name="avaliacao" class="s2" hidden value="Ruim" />
                <input type="radio" name="avaliacao" class="s3" hidden value="Satisfatório" />
                <input type="radio" name="avaliacao" class="s4" hidden value="Bom" />
                <input type="radio" name="avaliacao" class="s5" hidden value="Ótimo"/>
            </form>
        </div>
        <div class="av-com-1 campo fields">
            <label>Comentário (caso queira complementar sua avaliação):</label>
        </div>
        <div class="av-com-2 campo obrigatorio" style="display: none;">
            <label>Justifique sua avaliação (mínimo 15 caracteres):</label>
        </div>
        <div class="campo fields">
            <textarea name="comentario" maxlength="2000"></textarea><br />
            <small>Máximo 2000 caracteres.</small>
        </div>
    </fieldset>
</template>

<script>
export default {
    props: ['info', 'atendimentoId'],
    data() {
        return {
            ref: null,
            avaliacao: null
        }
    },
    methods: {
    },
    mounted() {
        let vm = this;

        this.$nextTick(function () {
            vm.ref = vm.$refs[`avaliacao${vm.atendimentoId}`];

            $(vm.ref).find('.star').click(function() {
                const $this = $(vm.ref);
                $this.find('.star').removeClass('star-on');
                $this.find('[name="avaliacao"]').removeProp('checked');
                $this.find('[name="avaliacao"]').removeAttr('checked');

                if ($(this).hasClass('star1') || $(this).hasClass('star2')) {
                    $this.find('.av-com-1').hide();
                    $this.find('.av-com-2').show();
                } else {
                    $this.find('.av-com-1').show();
                    $this.find('.av-com-2').hide();
                }

                if ($(this).hasClass('star1')) {
                    $this.find('.star1').addClass('star-on');
                    let obj = $this.find('[name="avaliacao"].s1');
                    vm.avaliacao = obj.val();
                    obj.attr('checked', 'checked');
                    obj.prop('checked', true);
                } else if ($(this).hasClass('star2')) {
                    $this.find('.star1').addClass('star-on');
                    $this.find('.star2').addClass('star-on');
                    let obj = $this.find('[name="avaliacao"].s2');
                    vm.avaliacao = obj.val();
                    obj.attr('checked', 'checked');
                    obj.prop('checked', true);
                } else if ($(this).hasClass('star3')) {
                    $this.find('.star1').addClass('star-on');
                    $this.find('.star2').addClass('star-on');
                    $this.find('.star3').addClass('star-on');
                    let obj = $this.find('[name="avaliacao"].s3');
                    vm.avaliacao = obj.val();
                    obj.attr('checked', 'checked');
                    obj.prop('checked', true);
                } else if ($(this).hasClass('star4')) {
                    $this.find('.star1').addClass('star-on');
                    $this.find('.star2').addClass('star-on');
                    $this.find('.star3').addClass('star-on');
                    $this.find('.star4').addClass('star-on');
                    let obj = $this.find('[name="avaliacao"].s4');
                    vm.avaliacao = obj.val();
                    obj.attr('checked', 'checked');
                    obj.prop('checked', true);
                } else if ($(this).hasClass('star5')) {
                    $this.find('.star').addClass('star-on');
                    let obj = $this.find('[name="avaliacao"].s5');
                    vm.avaliacao = obj.val();
                    obj.attr('checked', 'checked');
                    obj.prop('checked', true);
                }
            });
        });
    }
}
</script>

<style scoped>
    .avaliacao-chat {
        margin: 0 -11px;
    }
    .avaliacao-chat legend {
        color: #CC3300;
        font-size: 13px !important;
        text-indent: -10px;
        font-style: italic;
    }
    .av-com-2 { display: none; }

    .stars { float: left }
</style>

