<template>

    <div id="login">

        <div class="container">

            <div class="align-center">
                <h1 class="h1">Atendimento on-line SCI</h1>
                <p>
                    Acesso exclusivo para Clientes, Colaboradores e Revendas da <br />SCI Sistemas Contábeis.<br /><br />
                </p>
            </div>

            <form onsubmit="return false">
                <div class="campo">
                    <label for="usuario">Informe seu login de Usuário (login da Área do Cliente SCI)</label>
                    <input class="text input_login" id="usuario" autofocus v-model="input.login" />
                </div>

                <div class="campo">
                    <label for="senha">Senha</label>
                    <input type="password" class="text" id="senha" v-model="input.senha" />
                </div>

                <div class="botao align-center">
                    <input type="submit" class="bt" value="Entrar" v-on:click="login()" />
                    &nbsp;&nbsp;
                    <input type="button" class="bt" value="Fechar" onclick="window.close();" />
                </div>
            </form>

        </div>

    </div>

</template>

<script>

export default {
    name: 'login',
    data() {
        return {
            input: {
                login: '',
                senha: ''
            }
        }
    },
    methods: {
        acessoAtendente(auth) {
            let vm = this;

            vm.$socket.emit('login-move', {
                pessoaId: auth.dados.pessoaId,
                representanteOriginalId: auth.dados.representanteOriginalId
            }, function (res) {
                if (typeof res.config !== 'undefined') {
                    auth.ehAtendente = true;
                    auth.config = res.config;
	                if (process.env.VUE_APP_ENV === 'dev') {
		                vm.$cookies.set('auth', auth);                    
                    } else {
                        vm.$cookies.set('auth', auth, null, null, window.location.host);
                    }
                    vm.$socket.emit('attendent-connect');
                    vm.$router.push('/atendente');
                } else {
                    return vm.$sweet.alert( 'Atenção', 'Ocorreu um erro ao acessar', 'error');
                }
            });
        },
        login() {
            let vm = this;
            let router = this.$router;
            let sweet = this.$sweet;
            let cookies = this.$cookies;
            let parser  = new UAParser();

            let infraestrutura, linha;

            switch (window.location.hostname) {

            	case 'infra.visual.chat.local':
	            case 'infra.visual.chat.sci.com.br': {

		            infraestrutura = true;
	            	linha = 'Visual';

	            	break;

		        }

	            case 'infra.unico.chat.local':
	            case 'infra.unico.chat.sci.com.br': {

		            infraestrutura = true;
	                linha = 'Unico';

	            }

            }

            this.$socket.emit('login-auth', {
                login: this.input.login,
                senha: this.input.senha,
	            infraestrutura,
	            linha,
                from: 'intranet'
            }, (err, auth) => {
                if (err) {
                    senha.value = '';
                    return sweet.alert( 'Atenção', err, 'error');
                }

                let ua = parser.getResult();

                auth.userAgent = `Browser: ${ua.browser.name} - v ${ua.browser.version}` +
                    `OS: ${ua.os.name} - v ${ua.os.version}` +
                    `UA: ${ua.ua}`;

                if (auth.dados.pessoaId >= 80000) {
                    if (auth.dados.representanteOriginalId === 1) {
                        vm.acessoAtendente(auth);
                        return;
                    }

                    this.$sweet.confirm(
                        '',
                        'Selecione o seu tipo de acesso',
                        () => {
                            auth.ehAtendente = false;
	                        if (process.env.VUE_APP_ENV === 'dev') {
		                        cookies.set('auth', auth);
                            } else {
		                        cookies.set('auth', auth, null, null, window.location.host);
                            }

                            router.push('/cliente');
                        },
                        () => {
                            vm.acessoAtendente(auth)
                        },
                        'warning',
                        'Solicitar atendimento para Matriz',
                        'Atendente/Operador'
                    );
                } else {
                    auth.ehAtendente = false;
	                if (process.env.VUE_APP_ENV === 'dev')
		                cookies.set('auth', auth);
	                else
		                cookies.set('auth', auth, null, null, window.location.host);
                    router.push('/cliente');
                }
            });
        }
    }
}
</script>

<style>
.container {
    margin-top: 15px;
}
</style>
