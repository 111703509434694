<template>
    <div id="atendente">
        <span class="hidden"> {{ exibeSistemaOperador() }} </span>

        <div class="b-xs-12">
            <h2 class="h2">Configurações de atendimento</h2>
            <br/>
            <span class="float-right" v-if="loginAtendente == 'maximiliano.furtado'">
                <a href="#" class="bt" @click="debug">Debug</a>
            </span>
            <span class="float-right" v-if="atualizaAvisos">
                <a href="#" class="bt" @click="atualizaAvisosGeral">Atualiza avisos</a>
            </span>
            <p>
                Olá <b>{{ nomeAtendente }}</b>.
                <br />
                Selecione abaixo os canais e sistemas que você desejar efetuar suporte:
            </p>
        </div>

        <br class="clear"/>
        <br class="clear"/>

        <form onsubmit="return false">
            <div class="b-xs-12">
                <h4 class="title">Canais de atendimento</h4>
                <div class="campo">
                    <input type="radio" id="canal_cliente_c" name="canal"
                        :checked="checkCanal == 'C'" value="C"/>&nbsp;
                    <label for="canal_cliente_c" style="font-weight: normal">Clientes</label>
                    &nbsp;&nbsp;
                    <input type="radio" id="canal_cliente_p" name="canal"
                        :checked="checkCanal == 'P'" value="P"/>&nbsp;
                    <label for="canal_cliente_p" style="font-weight: normal">Revendas</label>
                    &nbsp;&nbsp;
                    <input type="radio" id="canal_cliente_a" name="canal"
                        :checked="checkCanal == 'A'" value="A"/>&nbsp;
                    <label for="canal_cliente_a" style="font-weight: normal">Ambos</label>
                </div>
            </div>

            <br class="clear"/>
            <br class="clear"/>

	        <div class="b-xs-12">
		        <h4 class="title">Sistemas</h4>
	        </div>

			<!-- Sistemas visual -->
	        <div v-if="this.atende === 'Visual'">

		        <div class="b-xs-4">
			        <h5 class="title">Linha VISUAL</h5>
			        <a href="javascript:;" @click="marcaTudo('visual')">Marcar tudo</a> |
			        <a href="javascript:;" @click="marcaTudo('visual', true)">Desmarcar tudo</a>
			        <br/><br/>
			        <div class="campo" v-for="sistema in sistemasVisual" :key="sistema.sistemaId">
				        <input type="checkbox" name="sistema[]" :id="'visualsistemas_'+ sistema.sistemaId" data-tipo="visual"
				               :value="sistema.sistemaId"
				               :checked="sistemasConfig.indexOf(sistema.sistemaId) !== -1"/>&nbsp;
				        <label :for="'visualsistemas_'+ sistema.sistemaId" style="font-weight: normal">{{ sistema.nome }}</label>
			        </div>
			        <br />
			        <div class="b-xs-12">
				        <h5 class="title">TI/INFRAESTRUTURA/CLOUD</h5>
				        <div class="campo">
					        <input type="radio" name="sistema_infra" value="9991"
					               :checked="sistemaInfra === 9991"/>&nbsp;
					        <label style="font-weight: normal">Visual</label>
					        &nbsp;&nbsp;
					        <input type="radio" name="sistema_infra" value="9992"
					               :checked="sistemaInfra === 9992"/>&nbsp;
					        <label style="font-weight: normal">Único</label>
					        &nbsp;&nbsp;
					        <input type="radio" name="sistema_infra" value="9993"
					               :checked="sistemaInfra === 9993"/>&nbsp;
					        <label style="font-weight: normal">Ambos</label>
					        &nbsp;&nbsp;
					        <input type="radio" name="sistema_infra" value="9994"
					               :checked="sistemaInfra === 9994"/>&nbsp;
					        <label style="font-weight: normal">Não atendo</label>
				        </div>
			        </div>
		        </div>

	        </div>
	        <!-- Sistemas visual fim -->

			<!-- Sistemas web -->
	        <div v-if="this.atende === 'Web'">

		        <div class="b-xs-6">
			        <h5 class="title">Tecnologias WEB</h5>
			        <a href="javascript:;" @click="marcaTudo('web')">Marcar tudo</a> |
			        <a href="javascript:;" @click="marcaTudo('web', true)">Desmarcar tudo</a>
			        <br/><br/>
			        <div v-for="aColuna in sistemasWeb" class="b-xs-4">
				        <div v-for="(aSistema, titulo) in aColuna">
					        <h5 class="title">{{ titulo }}</h5>
					        <div class="campo" v-for="sistema in aSistema" :key="sistema.sistemaId">
						        <input type="checkbox" name="sistema[]" :id="'sistema_'+ sistema.sistemaId" data-tipo="web"
						               :value="sistema.sistemaId"
						               :checked="sistemasConfig.indexOf(sistema.sistemaId) !== -1"/>&nbsp;
						        <label :for="'sistema_'+ sistema.sistemaId" style="font-weight: normal">
							        {{ sistema.sistemaId === 132 ? 'SCI APP 4.0' : sistema.nome }}
						        </label>
					        </div>
				        </div>
			        </div>
		        </div>

	        </div>
	        <!-- Sistemas web fim -->

	        <!-- Sistemas unico fim -->
	        <div v-if="this.atende === 'Unico'">

		        <div class="b-xs-4">
			        <h5 class="title">Ambiente contábil ÚNICO</h5>
			        <a href="javascript:;" @click="marcaTudo('unico')">Marcar tudo</a> |
			        <a href="javascript:;" @click="marcaTudo('unico', true)">Desmarcar tudo</a>
			        <br/><br/>
			        <div class="campo" v-for="sistema in sistemasUnico" :key="sistema.sistemaId">
				        <input type="checkbox" name="sistema[]" :id="'unicosistemas_'+ sistema.sistemaId" data-tipo="unico"
				               :value="sistema.sistemaId"
				               :checked="sistemasConfig.indexOf(sistema.sistemaId) !== -1"/>&nbsp;
				        <label :for="'unicosistemas_'+ sistema.sistemaId" style="font-weight: normal">{{ sistema.nome }}</label>
			        </div>
			        <br>
		        </div>

	        </div>
	        <!-- Sistemas unico fim -->

	        <div class="b-xs-12">
                <div class="botao align-center">
                    <input type="submit" class="bt bt-salvar" value="Entrar" v-on:click="salvar()"/>
                    &nbsp;&nbsp;
                    <input type="button" class="bt" value="Fechar" onclick="window.close();"/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

export default
{
    name: 'atendente',
    data() {
        let auth  = this.$cookies.get('auth');
        let canal = '';

        switch (auth.dados.canal) {
            case 'C': canal = 'C'; break;
            case 'P': canal = 'P'; break;
            case 'A': canal = 'A'; break;
        }

        return {
            nomeAtendente: auth.dados.nome,
            loginAtendente: auth.dados.login,
            checkCanal: canal,
            sistemasVisual: [],
            sistemasWeb: [],
            sistemasUnico: [],
            sistemasConfig: [],
            sistemaInfra: 9994,
            temBoxInfo: false,
            consultaAtendimento: auth.dados.consultaAtendimento,
            atualizaAvisos: auth.dados.atualizaAvisos,
	        atende: process.env.VUE_APP_ATENDE
        }
    },
    methods: {
        marcaTudo: function (tipo, desmarcar = false) {
            if (!desmarcar) {
                $('[data-tipo="'+tipo+'"]').prop('checked', true);
            } else {
                $('[data-tipo="'+tipo+'"]').prop('checked', false);
            }
        },
        salvar: function () {
            let vm = this;
            let auth = this.$cookies.get('auth');
            let sistemas = [];

            $('.bt-salvar').attr('disabled', 'disabled');

            $('[name="sistema[]"]:checked').each( function () {
                let val = Number($(this).val());

                if (sistemas.indexOf(val) < 0) sistemas.push(val);
            });

            sistemas.push(Number($('[name="sistema_infra"]:checked').val()));

            // Feito dessa forma para tentar pegar ou contornar o erro onde de vez em quando os sistemas do usuário vem todos desmarcados.
            setTimeout(function() {
                if (sistemas.length <= 0) {
                    $('.bt-salvar').removeAttr('disabled');

                    vm.$sweet.alert('Atenção', 'Você deve selecionar os sistemas para atendimento', 'error');
                    return;
                }

                if (!$('[name="canal"]:checked').val()) {
                    $('.bt-salvar').removeAttr('disabled');

                    vm.$sweet.alert('Atenção', 'Você deve selecionar o canal de atendimento', 'error');
                    return;
                }

                // Caso marque o sistema SCI App, adiciona também o 129 na lista
                if (sistemas.indexOf(132) >= 0) {
                    sistemas.push(129);
                }

                vm.$socket.emit('login-salva-config',
                    {
                        pessoaId: auth.dados.pessoaId,
                        revendaId: auth.dados.representanteOriginalId,
                        permissoesImplantacao: {
                            atendeImplantacaoAguardando: auth.dados.atendeImplantacaoAguardando,
                            atendeImplantacaoSemChat: auth.dados.atendeImplantacaoSemChat,
                            atendeImplantado: auth.dados.atendeImplantado,
                            atendeImplantacaoComChat: auth.dados.atendeImplantacaoComChat
                        },
                        nome: auth.dados.nome,
                        canal: $('[name="canal"]:checked').val(),
                        sistemas: sistemas,
                    }, function (err) {
                        if (err) {
                            $('.bt-salvar').removeAttr('disabled');

                            vm.$sweet.alert('Atenção', err, 'error');
                            return;
                        }

                        if (!auth.config) auth.config = {};

                        auth.config.canal = $('[name="canal"]:checked').val();

		                sistemas.push(127);

                        if (sistemas.includes(9993)) {
                            sistemas.push(9991);
                            sistemas.push(9992);
                        }

                        auth.config.sistemas = sistemas;

                        if (this.temBoxInfo) { auth.config.boxInfoFixo = true; }

		                if (process.env.VUE_APP_ENV === 'dev')
			                vm.$cookies.set('auth', auth)
		                else
			                vm.$cookies.set('auth', auth, null, null, window.location.host);
                        vm.$router.push('/atendimento');
                    }
                );
            }, 500);
        },
        consultaAtendimentos: function () {
            this.$router.push('/consulta-atendimentos');
        },
        debug: function () {
            this.$router.push('/debug');
        },
        carregaSistemas: async function () {
            let retorno  = {
                visual: [],
                web: [],
                unico: []
            };

            let auth = this.$cookies.get('auth');
            let sistemas = await new Promise(resolve => {
                this.$socket.emit('sistema-atendimento', {}, (result) => {
                    resolve(result)
                });
            });

            let ordemVisual  = [];
            ordemVisual[19]  = 1;
            ordemVisual[16]  = 2;
            ordemVisual[11]  = 3;
            ordemVisual[13]  = 4;
            ordemVisual[9]   = 5;
            ordemVisual[108] = 6;

            let ordemWeb  = [];
            ordemWeb[30]  = 0;
            ordemWeb[41]  = 1;
            ordemWeb[212] = 2;
            ordemWeb[42]  = 3;
            ordemWeb[106] = 4;
            ordemWeb[132] = 5;
            ordemWeb[40]  = 6;
            ordemWeb[0]   = 7;
            ordemWeb[43]  = 8;
            ordemWeb[33]  = 9;
            ordemWeb[32]  = 10;
            ordemWeb[105] = 11;
            ordemWeb[38]  = 12;
            ordemWeb[31]  = 13;
            ordemWeb[45]  = 14;
            ordemWeb[34]  = 15;
            ordemWeb[107] = 16;
	        ordemWeb[123] = 17;
			ordemWeb[133] = 18;

            //LGPD
			ordemWeb[208] = 19;
            //Syndkos
			ordemWeb[202] = 23;

            for (let key in sistemas) {
                let sistema = sistemas[key];
                
                // Troca nome do sistema Infraestrutura
                if (sistema.sistemaId === 999) {
                    sistema.nome = 'TI/INFRASTRUTURA/CLOUD';
                }

                if (this._SISTEMA_ID_VISUAL.indexOf(sistema.sistemaId) >= 0) {
                    retorno.visual[ordemVisual[sistema.sistemaId]] = sistema;
                }

                if (this._SISTEMA_ID_WEB.indexOf(sistema.sistemaId) >= 0) {
                    
                    retorno.web[ordemWeb[sistema.sistemaId]] = sistema
                }

                if (this._SISTEMA_ID_UNICO.indexOf(sistema.sistemaId) >= 0) {
                    retorno.unico.push(sistema);
                }
            }

            // Por algum motivo as vezes entrava valores null nos arrays, o código a baixo filtra esses valores
            retorno.visual = retorno.visual.filter((val) => { return val != null });
            retorno.web = retorno.web.filter((val) => { return val != null });
            retorno.unico = retorno.unico.filter((val) => { return val != null });
            
            this.sistemasVisual = retorno.visual;
            this.sistemasWeb = await this.ordenaSistemaWeb(retorno.web);
            this.sistemasUnico = retorno.unico;
            let sistemasConfig = [];
            
            if (typeof auth.config != 'undefined' && auth.config != null) {
                let config = auth.config;

                if (!this.temBoxInfo && config.boxInfoFixo) this.temBoxInfo = true;

                for (let key in config.sistemas) {
                    sistemasConfig.push(config.sistemas[key]);
                }
            }

            this.sistemasConfig = sistemasConfig;

            if (this.sistemasConfig.indexOf(9991) !== -1) this.sistemaInfra = 9991;
            if (this.sistemasConfig.indexOf(9992) !== -1) this.sistemaInfra = 9992;
            if (this.sistemasConfig.indexOf(9993) !== -1) this.sistemaInfra = 9993;

            if (this.sistemasConfig.indexOf(9991) !== -1 && this.sistemasConfig.indexOf(9992) !== -1)
                this.sistemaInfra = 9993;
        },
        atualizaAvisosGeral: function () {
            this.$socket.emit('atualiza-avisos');
        },
        exibeSistemaOperador: function () {
            $('#nomeSistema').text('ATENDIMENTO ON-LINE - OPERADOR');
        },
	    async ordenaSistemaWeb(aSistema) {
            
			let json = [
			    {
				    'Autoatendimento 24h': [],
				    'Autoatendimento fiscal': [],
			    },
			    {
				    'Autoatendimento folha': [],
				    'Backup na nuvem': [],
                    'Syndkos Web': [],
                    'LGPD10': [],

			    }
			]

			aSistema.map(item => {
               
				if ([100, 106, 41, 42, 132, 40, 30, 215, 212].includes(item.sistemaId)){

					json[0]['Autoatendimento 24h'].push(item);
                }else if ([38, 104, 123, 45, 31, 105].includes(item.sistemaId)){

                    json[0]['Autoatendimento fiscal'].push(item);
                }else if ([43, 33, 133].includes(item.sistemaId)) {

					if (item.sistemaId === 133) item.nome = "APP RH NET (APP, Ponto e Despesas)";

					json[1]['Autoatendimento folha'].push(item);
				}else if ([107].includes(item.sistemaId)){

					json[1]['Backup na nuvem'].push(item);
                }else if (item.sistemaId==208){

                    json[1]['LGPD10'].push(item);

                }else if (item.sistemaId==202){
                    
                    json[1]['Syndkos Web'].push(item);
                }
			});

			return json;
	    }
    },
    beforeMount() {

        this.carregaSistemas();

    },
    mounted() {
        let auth = this.$cookies.get('auth');
        let usuario = `\
            ${auth.dados.pessoaId} - ${auth.dados.nome} \
            [ REVENDA: ${auth.dados.representanteOriginalId} - \
             ${auth.dados.nomeRepresentanteOriginal} ] \
        `;

        $('.relogio').addClass('com-login');
        $('#nome_atendimento').html(`<span class="nome_login float-right">${usuario}</span>`);
    }
}

</script>
