<template>

    <div id="conversa">

        <div id="box-avisos" v-if="temAvisos" style="display: none; width: 100%; max-width: 1200px; padding: 15px;">
            <h1 class="h1">Avisos importantes - (clique em cima do título para visualizar)</h1>
            <badger-accordion class="accordion">
                <badger-accordion-item v-for="aviso in avisos" :key="aviso.id" @open="marcaAvisoVisto(aviso.id)">
                    <template slot="header">
                        <i class="aviso-novo" v-if="!aviso.visto">NOVO!</i>
                        {{converteDataAviso(aviso.dataMaior)}} - {{aviso.titulo}}
                    </template>
                    <template slot="content">
                        <p v-html="aviso.texto"></p>
                    </template>
                    <br class="clear" />
                </badger-accordion-item>
            </badger-accordion>
        </div>

        <div class="b-xs-12 lista">
            <span class="mensagem"
               v-for="( item, index ) in conversa"
               :key="index"
               v-html="converteHtml( item )"
            >
            </span>
        </div>

        <div class="b-xs-12 box-mensagem">

            <form onsubmit="return false" autocomplete="off">

                <div class="campo flex-text">
                    <input type="text" name="texto" id="texto" autocomplete="off" autofocus
                           maxlength="1000" placeholder="Digite sua mensagem ou copie e cole seu print aqui e aperte Enter para enviar..." />
                    <input type="file" multiple id="uploader_input" @change="changeFiles"
                           accept=".txt,.TXT,.pdf,.PDF,.xml,.XML,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.mp4,.MP4,.gif,.GIF,.re,.RE,.sd,.SD,.m00,.m01,.m02,.m03,.m04,.m05,.m06,.m07,.m08,.m09,.m10,.m11,.m12,.M00,.M01,.M02,.M03,.M04,.M05,.M06,.M07,.M08,.M09,.M10,.M11,.M12"
                           style="display: none;" />
                    <a href="#" title="Enviar arquivo (até 25MB | Tipos: TXT, PDF, XML, JPG, PNG, JPEG, MP4, GIF, Sefip (RE), Caged (M99), Seguro Desemprego (SD))"
                       id="uploader_button">
                        <svg class="icon icon-attachment"><use xlink:href="#icon-attachment"></use></svg>
                    </a>
                </div>

                <div class="campo" :style="{ display: ( files && files.length > 0 ) ? 'block' : 'none' }">
                    <p><b>Arquivos que serão enviados</b></p>
                    <ul>
                        <li v-for="file in files" :key="file.lastModified">
                            {{ file.name }} - {{ formataBytes( file.size ) }}
                            [<div :class="[ 'progresso', 'file'+ file.lastModified ]"><span class="bar"></span></div>]
                            <span :class="[ 'perc_progresso', 'file'+ file.lastModified ]">0%</span>
                        </li>
                    </ul>
                    <button id="uploader_submit" type="button">Enviar arquivos</button>
                </div>

                <div class="campo align-center">
                    <div class="float-left">
                        <a :href="'https://areadocliente.sci10.com.br/modulo/faq/view.php?sistemaId='+sistemaOriginal+linhaSistema"
                           target="_blank" class="bt bt-acao faq" title="Enquanto você está sendo atendido, aproveite e consulte o nosso FAQ! Ele pode resolver a sua situação também">
                            <i class="icone"><img src="/imagens/ico-faq.png" /></i>
                            <span class="texto">FAQ</span>
                        </a>
                    </div>

                    <div class="float-left check-rolar">
                        <input type="checkbox" name="rolar" v-model="rolar" />
                        <label>&nbsp;Rolar conversa automaticamente</label>
                    </div>

                    <div class="float-right botao-encerrar">
                        <a href="javascript:;" title="Finalizar atendimento" class="bt bt-acao" style="font-size: .9rem"
                           @click="abreAvaliacao(true)">
                            <i class="icone"><img src="/imagens/ico-sair.png" /></i>
                            <span class="texto">AVALIAR E ENCERRAR</span>
                        </a>
                    </div>

                    <div class="float-right" v-if="temAvisos">
                        <a href="javascript:;" title="Avisos" class="bt bt-acao bt-avisos" style="font-size: .9rem"
                           data-fancybox="avisos" data-src="#box-avisos">
                            <i class="icone"><img src="/imagens/ico-aviso.png" /></i>
                            <span class="texto">AVISOS</span>
                        </a>
                    </div>

                    <div class="float-right">
                        <a href="javascript:;" title="Orientações" class="bt bt-acao"
                           style="font-size: .9rem;padding: 1px 6px 11px 6px;" @click="alertaRegras">
                            <span class="texto">ORIENTAÇÕES</span>
                        </a>
                    </div>

                    <div class="float-right">
                        <input type="submit" @click="enviaMensagem" value="Enviar" style="display: none;" />
                    </div>
                </div>

            </form>

        </div>

        <div id="avaliacao" style="display: none;">
            <span class="label-encerrado"></span><br />
            Informe abaixo sua avaliação quanto ao atendimento prestado:<br />
            <div class="campo">
                <div class="stars">
                    <div class="star star1"></div>
                    <div class="star star2"></div>
                    <div class="star star3"></div>
                    <div class="star star4"></div>
                    <div class="star star5"></div>
                </div>
                <div class="label-star"></div>
                <input type="radio" name="avaliacao" class="s1" hidden value="Péssimo" />
                <input type="radio" name="avaliacao" class="s2" hidden value="Ruim" />
                <input type="radio" name="avaliacao" class="s3" hidden value="Satisfatório" />
                <input type="radio" name="avaliacao" class="s4" hidden value="Bom" />
                <input type="radio" name="avaliacao" class="s5" hidden value="Ótimo"/>
            </div>
            <div class="av-com-1 campo">
                <label>Deixe seu comentário:</label>
            </div>
            <div class="av-com-2 campo obrigatorio" style="display: none;">
                <label>Informe a justificativa de sua avaliação:</label>
            </div>
            <textarea name="comentario" maxlength="2000"></textarea>
            <br />
            Máximo 2000 caracteres
            <br />
            <div class="erro-avaliacao">
                <b></b>
            </div>
        </div>

    </div>

</template>


<script>
require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');

import {BadgerAccordion, BadgerAccordionItem} from 'vue-badger-accordion';

const Entities = require('html-entities').AllHtmlEntities;
const blobutil = require('blob-util');
const pasteImg = require('../models/PasteImage.js');

const entities = new Entities();


export default {
    name: 'conversa',

    data() {
        let params = this.$route.params;
        let auth = this.$cookies.get('auth');
        let linhaSistema = auth.atendimento.sistemaLinha == '1' ? 'w' : 'u';

        if (this._SISTEMA_ID_WEB.indexOf(auth.atendimento.sistemaId) >= 0) {
            linhaSistema = 'e';
        }
        if (auth.atendimentoId != params.sala) {
            this.$sweet.alert( 'Atenção', 'Erro na validação da sua sessão de atendimento', 'error');
            this.$router.push('/');
            return;
        }

        let sistema = auth.atendimento.sistemaOriginal;

        return {
            atendimentoId: params.sala,
            pessoaId: auth.dados.pessoaId,
            conversa: [],
            rolar: true,
            files: [],
            semInteracao: false,
            tempoInativo: '00:00:00',
            encerraInativo: '00:00:00',
            timerInativo: null,
            timerEncerrar: null,
            encerradoInativo: false,
            nomeAtendente: '',
            sistemaOriginal: sistema,
            linhaSistema: '&linha='+linhaSistema,
            temAvisos: false,
            avisos: [],
            encerradoAvaliacao: false,
        }
    },
    asyncComputed: {
        conversa: async function() {
            let vm = this;
            let params = vm.$route.params;

            let conversa = await new Promise((resolve) => {
                vm.$socket.emit(
                    'conversa-carrega',
                    params.sala,
                    res => {
                        resolve(res.mensagens);
                        vm.nomeAtendente = res.dados.atendente;
                    }
                );
            });

            aviso(conversa, vm.nomeAtendente);

            return conversa;
        },
    },
    components: {
        BadgerAccordion,
        BadgerAccordionItem
    },
    methods: {
        enviaMensagem: function() {
            let campoTexto = $('#texto');
            let texto = String(remove_html(campoTexto.val())).trim().substr(0, 1000);

            if (texto == '') return;

            let vm = this;
            let params = vm.$route.params;
            let auth = this.$cookies.get('auth');

            vm.$socket.emit('conversa-grava', {
                mensagem: texto,
                atendimentoId: params.sala,
                auth: auth,
                isAtendente: 0,
            }, err => {
                vm.resetMensagem(err);
            });
        },
        resetMensagem: function (err) {
            let vm = this;
            let params = vm.$route.params;

            if (err) vm.$sweet.alert('Atenção', err, 'error');

            $('#texto').val('').focus();

            clearInterval(vm.timerInativo);
            clearInterval(vm.timerEncerrar);

            if (vm.semInteracao) {
                vm.$socket.emit(
                    'conversa-inativo-remove', {
                        atendimentoId: params.sala,
                        tipo: 'cliente'
                    }
                );
            }

            vm.semInteracao = false;
        },
        converteHtml: function(val) {
            return entities.decode(val);
        },
        finalizaAtendimento: function(botao = false) {
            let vm = this;
            const auth = this.$cookies.get('auth');

            let msg = `Tem certeza que deseja finalizar este atendimento?<br />
                A conversa será encerrada e você poderá consultar ela na
                sua tela, caso tenha ficado alguma dúvida ou queira salvar
                a conversa.<br />
                Após encerrar o atendimento, você poderá avaliar o
                atendimento prestado.
            `;

            this.$sweet.confirm(
                msg,
                'Atenção',
                function() {
                    vm.$socket.emit('encerra-botao', {atendimentoId: auth.atendimentoId});

                    clearInterval(vm.timerInativo);
                    clearInterval(vm.timerEncerrar);

                    $('.box-mensagem').remove();

                    if (botao) {
                        $('.label-encerrado').text('Você encerrou o atendimento.');
                    }
                },
                function(){},
                'warning',
                'Confirmar'
            );
        },
        validaComentario: function () {
            $('.swal-button--enviar').attr('disabled', 'disabled');
            $('.swal-button--enviar').prop('disabled', true);

            let avaliacao = $('.swal-modal [name="avaliacao"]:checked').val();
            let comentario = entities.encode($('.swal-modal [name="comentario"]').val());

            if (!avaliacao) {
                return null;
            }

            if ((avaliacao == 'Ruim' || avaliacao == 'Péssimo')
                && (!comentario || comentario.length < 15)
            ) {
                $('.erro-avaliacao b').text('Informações insuficientes na justificativa de sua avaliação');
                return null;
            }

            $('.erro-avaliacao b').text('');
            $('.swal-button--enviar').removeAttr('disabled');
            $('.swal-button--enviar').removeProp('disabled');
        },
        abreAvaliacao: function(encerrarAtendimento = false) {
            let vm = this;
            let auth = this.$cookies.get('auth');

            vm.encerradoAvaliacao = encerrarAtendimento;
            vm.$sweet.html($('#avaliacao').html(), {
                'enviar': {
                    'text': 'Enviar',
                    'funcao': function() {
                        let avaliacao = $('.swal-modal [name="avaliacao"]:checked').val();
                        let comentario = entities.encode($('.swal-modal [name="comentario"]').val());

                        let params = {
                            atendimentoId: vm.atendimentoId,
                            avaliacao: avaliacao,
                            comentario: comentario,
                            tipo: 'Cliente'
                        };

                        vm.$socket.emit('conversa-avaliacao', params, function(data) {
                            $('.avaliacao-text').remove();
                        });

                        vm.$sweet.confirm(`
                            Sua janela fechará após clicar no botão OK, ou você pode
                            clicar em cancelar e copiar ou salvar a conversa deste atendimento.
                            `,
                            'Obrigado, pela avaliação',
                            function() {
                                $('.botao-encerrar').remove();
                            },
                            function() {
                                window.close();
                            },
                            'success',
                            'Cancelar',
                            'OK'
                        );

                        $('.abre-avaliacao').unbind('click');

                        if (encerrarAtendimento) {
                            vm.$socket.emit('encerra-botao', {atendimentoId: auth.atendimentoId});

                            clearInterval(vm.timerInativo);
                            clearInterval(vm.timerEncerrar);

                            $('.box-mensagem').remove();
                        }
                    }
                },
                'cancelar': {
                    'text': 'Cancelar',
                    'funcao': function () {
                        return false;
                    }
                }
            });

            vm.$nextTick(function () {
                $('[name="comentario"]').keydown(vm.validaComentario);
                $('.swal-button--enviar').attr('disabled', 'disabled');

                $('.star').click(function() {
                    $('.star').removeClass('star-on');
                    $('[name="avaliacao"]').removeProp('checked');
                    $('[name="avaliacao"]').removeAttr('checked');

                    if ($(this).hasClass('star1') || $(this).hasClass('star2')) {
                        $('.av-com-1').hide();
                        $('.av-com-2').show();
                    } else {
                        $('.av-com-1').show();
                        $('.av-com-2').hide();
                    }

                    let obj = null;

                    if ($(this).hasClass('star1')) {
                        $('.star1').addClass('star-on');
                        obj = $('[name="avaliacao"].s1');
                        obj.attr('checked', 'checked');
                        obj.prop('checked', true);
                    } else if ($(this).hasClass('star2')) {
                        $('.star1').addClass('star-on');
                        $('.star2').addClass('star-on');
                        obj = $('[name="avaliacao"].s2');
                        obj.attr('checked', 'checked');
                        obj.prop('checked', true);
                    } else if ($(this).hasClass('star3')) {
                        $('.star1').addClass('star-on');
                        $('.star2').addClass('star-on');
                        $('.star3').addClass('star-on');
                        obj = $('[name="avaliacao"].s3');
                        obj.attr('checked', 'checked');
                        obj.prop('checked', true);
                        $('.erro-avaliacao b').text('');
                    } else if ($(this).hasClass('star4')) {
                        $('.star1').addClass('star-on');
                        $('.star2').addClass('star-on');
                        $('.star3').addClass('star-on');
                        $('.star4').addClass('star-on');
                        obj = $('[name="avaliacao"].s4');
                        obj.attr('checked', 'checked');
                        obj.prop('checked', true);
                        $('.erro-avaliacao b').text('');
                    } else if ($(this).hasClass('star5')) {
                        $('.star').addClass('star-on');
                        obj = $('[name="avaliacao"].s5');
                        obj.attr('checked', 'checked');
                        obj.prop('checked', true);
                        $('.erro-avaliacao b').text('');
                    }

                    $('.label-star').text(obj.val());

                    vm.validaComentario();
                });
            });
        },
        converteHora: function(val) {
            let data = new Date(val);

            let vlrOptions  = {
                'lang': 'pt-BR',
                'options': {
                    hour: '2-digit',
                    minute: '2-digit'
                }
            };

            return data.toLocaleTimeString(vlrOptions.lang, vlrOptions.options);
        },
        converteDataAviso: function(val) {
            let date = val.split(' ');
            let data = exibe_data(date);

            if (typeof date[1] == 'undefined') return data;

            let hora = date[1];

            if (hora != '00:00:01' && hora != '23:59:59') {
                return exibe_data(date, true);
            }

            return data;
        },
        changeFiles: function() {
            this.files = [];

            let input = document.getElementById('uploader_input');
            let tamanhoTotal = 0;
            let files = [];
            let fileTypes = [
                'text/plain',
                // tipos de mime para xml
                'application/xml',
                'text/xml',
                'application/xhtml+xml',
                'application/atom+xml',
                'application/xslt+xml',
                'application/mathml+xml',
                'application/rss+xml',
                // fim xml
                'application/pdf',
                'image/jpeg',
                'image/png',
                'image/x-png',
                'image/x-citrix-png',
                'video/mp4',
                'image/gif',
            ];
            let rejeitados    = 0;

            if ('files' in input ) {
                if (input.files.length > 0) {
                    for (var i = 0; i < input.files.length; i++) {
                        let file = input.files[i];
                        tamanhoTotal += file.size;
                        if (file.type === '') {
                            let reExt = /(?:\.([^.]+))?$/;
                            let novaExt = [
                                're', 'sd', 'm01', 'm02', 'm03', 'm04', 'm05',
                                'm06', 'm07', 'm08', 'm09', 'm10', 'm11', 'm12',
                            ];
                            // Se o type tiver vazio e for as extensões acima, libera o envio
                            let fileName = String(file.name).toLowerCase();
                            if (novaExt.indexOf(reExt.exec(fileName)[1]) >= 0) {
                                files.push( file );
                            } else {
                                rejeitados++;
                            }
                        } else {
                            if (fileTypes.indexOf(file.type) >= 0) {
                                files.push(file);
                            } else {
                                rejeitados++;
                            }
                        }
                    }
                }

                if (rejeitados) {
                    let msgErro = 'Seus arquivos não foram inseridos pois alguns possuem um formato não suportado';
                    this.$sweet.alert( 'Atenção', msgErro, 'warning' );
                    this.files = [];
                }
            }
            // Limita o tamanho máximo da transferência para 25MB
            if (tamanhoTotal > 26214400) {
                let msg = `Tamanho total dos arquivos ultrapassa o limite permitido.<br />
                    O máximo permitido é de 25MB por transferência`;

                this.$sweet.alert('Atenção', msg, 'error');
                this.files = [];
            } else {
                this.files = files;
            }
        },
        formataBytes: function(bytes, decimal) {
            if (bytes == 0) return '0 Bytes';

            let k = 1024,
                dm = decimal <= 0 ? 0 : decimal || 2,
                sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));

            return `${parseFloat((bytes / Math.pow(k, i) ).toFixed(dm))} ${sizes[i]}`;
        },
        info: async function() {
            let vm = this;
            let params = vm.$route.params;

            return await new Promise((resolve) => {
                vm.$socket.emit('busca-atendimento', params.sala, res => resolve(res))
            });
        },
        /**
         * Aviso de inatividade
         * @param tempoInativo
         * @param encerraInativo
         * @param msAviso
         * @return void
         */
        avisoInativo: function (tempoInativo, encerraInativo, msAviso) {
            let vm = this;
            let auth = this.$cookies.get('auth');
            let usuario = `${auth.dados.pessoaId} - ${auth.dados.nome}`.toUpperCase();
            let sistema = auth.atendimento.sistemaNome;
            let atendente = vm.nomeAtendente;

            if (auth.dados.pessoaId < 80000) {
                usuario = `${usuario} - ${auth.atendimento.nome}`.toUpperCase();
            }

            let msg = `${auth.atendimento.nome}, você está sem interagir no
                atendimento online com o atendente ${atendente} para o sistema
                ${sistema}. Caso você não interagir em breve, seu atendimento
                será encerrado por inatividade. Evite filas investindo em
                treinamento e sendo breve no atendimento`;

            let params = vm.$route.params;

            vm.$socket.emit('conversa-inativo',{
                msg: `Cliente ${usuario} está a mais de
                    ${exibe_tempo_inativo(tempoInativo)} sem interagir no
                    atendimento.`,
                atendimentoId: params.sala,
                cliente: false,
            });

            vm.$socket.emit('conversa-inativo', {
                msg,
                atendimentoId: params.sala,
                cliente: true,
                hideAtendente : true,
            });

            let msg2 = `Atendimento sem interação do CLIENTE há mais de
                ${exibe_tempo_inativo(tempoInativo)}`;

            vm.$socket.emit('conversa-alerta-inativo', {
                atendimentoId: params.sala,
                tipo: 'cliente',
                msg: msg2
            });

            vm.semInteracao = true;
            vm.$sweet.confirm(
                msg,
                'Atenção',
                function() {
                    clearInterval(vm.timerInativo);
                    clearInterval(vm.timerEncerrar);

                    if (msAviso > 0) {
                        vm.timerInativo = setTimeout(
                            function () {
                                vm.avisoInativo(tempoInativo, encerraInativo, msAviso)
                            }
                            , msAviso
                        );
                    }
                },
                function() {
                    vm.finalizaAtendimento(true);
                },
                'warning',
                'Voltar para a conversa',
                'Finaliza o atendimento',
            );

            let msEncerra = time_to_ms(encerraInativo);

            if (encerraInativo && msEncerra > 0) {
                vm.timerEncerrar = setInterval(async function () {
                    $('.swal-modal .timer-inativo').text(exibe_tempo_inativo(ms_to_time(msEncerra, true), true));

                    msEncerra = msEncerra - 500;

                    if (msEncerra <= 0) {
                        vm.encerradoInativo = true;

                        let msg3 = `O seu atendimento foi encerrado
                            automaticamente por inatividade. Evite filas
                            investindo em treinamento e sendo breve no
                            atendimento.`;

                        vm.conversa.push(`<p><br />${exibe_data(new Date(), true, true)} - ${msg3}</p>`);

                        clearInterval(vm.timerEncerrar);

                        aviso([`<br />${msg3}`], 'Atenção!');

                        vm.$socket.emit('conversa-encerra-inatividade', params.sala);

                        vm.$sweet.alert('Atenção', msg3, 'warning');

                        $('.box-mensagem').remove();
                    }
                }, 500);
            }
        },
        alertaRegras: function () {
            let auth = this.$cookies.get('auth');

            this.$socket.emit('cliente-regras-atendimento', 999, (res) => {
                let texto = res.texto.replace(new RegExp('\r?\n','g'), '<br />');

                if (auth.dados.clienteInativo) {
                    texto = texto.replace('#chatClienteInativo#', exibe_tempo_inativo(auth.dados.clienteInativo));
                }
                if (auth.dados.encerraInativo) {
                    texto = texto.replace('#chatEncerraInativo#', exibe_tempo_inativo(auth.dados.encerraInativo));
                }
                if (auth.dados.clienteInativoUnico) {
                    texto = texto.replace('#chatClienteInativoUnico#', exibe_tempo_inativo(auth.dados.clienteInativoUnico));
                }
                if (auth.dados.encerraInativoUnico) {
                    texto = texto.replace('#chatEncerraInativoUnico#', exibe_tempo_inativo(auth.dados.encerraInativoUnico));
                }

                this.$sweet.alert('Orientações', texto, 'info');
            });
        },
        marcaAvisoVisto: function (id) {
            let vm = this;
            let auth = vm.$cookies.get('auth');

            _.forEach(this.avisos, obj => {
                if (obj.id == id) {
                    obj.visto = true;

                    if (typeof auth.dados.usuarioAdicionalId != 'undefined') {
                        obj.visualizadosAdicionais.push(auth.dados.usuarioAdicionalId);
                    } else {
                        obj.visualizados.push(vm.pessoaId);
                    }
                }
            });

            if (typeof auth.dados.usuarioAdicionalId != 'undefined') {
                this.$socket.emit('aviso-marca-visto', {pessoaId: vm.pessoaId, avisoId: id, usuarioId: auth.dados.usuarioAdicionalId});
            } else {
                this.$socket.emit('aviso-marca-visto', {pessoaId: vm.pessoaId, avisoId: id});
            }
        },
        buscaAvisos: function () {
            let vm = this;
            let auth = this.$cookies.get('auth');

            vm.avisos = [];

            vm.$socket.emit('aviso-busca', auth, function (dados) {
                if (dados.avisos.length) vm.temAvisos = true;

                _.forEach(dados.avisos, function (obj) {
                    let visualizados = _.find(dados.visualizados, {avisoId: obj.id});
                    obj.texto = String(entities.decode(obj.texto)).replace(new RegExp('\r?\n','g'), '<br />');
                    obj.visto = false;
                    obj.visualizados = [];

                    if (eh_aviso_interno(obj)) {
                        obj.texto = `<b style="color: red">Atenção: esse aviso
                            está aparecendo apenas para funcionários e/ou
                            revendas da SCI. Essa mensagem não está visível
                            para os clientes.</b><br />${obj.texto}`;
                    }

                    if (typeof visualizados != 'undefined') {
                        visualizados = _.map(visualizados.visualizados.split(','), Number);
                        obj.visualizados = visualizados;
                    }

                    let visualizadosAdicionais = _.find(dados.visualizadosAdicionais, {avisoId: obj.id});
                    obj.visualizadosAdicionais = [];

                    if (typeof visualizadosAdicionais != 'undefined') {
                        visualizadosAdicionais = _.map(visualizadosAdicionais.visualizados.split(','), Number);
                        obj.visualizadosAdicionais = visualizadosAdicionais;
                    }

                    if (typeof auth.dados.usuarioAdicionalId == 'undefined' && obj.visualizados.indexOf(vm.pessoaId) !== -1) {
                        obj.visto = true;
                    }

                    if (typeof auth.dados.usuarioAdicionalId != 'undefined') {
                        if (obj.visualizadosAdicionais.indexOf(auth.dados.usuarioAdicionalId) !== -1) {
                            obj.visto = true;
                        }
                    }

                    vm.avisos.push(obj);
                });
            });
        }
    },
    beforeDestroy() {
        this.$socket.off('conversa-atualiza');
        this.$socket.off('atendimento-finalizado');
        this.$socket.off('aviso-atualiza');
        this.$socket.off('siofu_complete');
        this.$socket.off('atendimento-derrubado');

        pasteImg.removeAllListeners();
        pasteImg.removeListener('pasting-image', evt => undefined);
        pasteImg.removeListener('paste-image', evt => undefined);
    },
    mounted() {
        let vm = this;
        let params = vm.$route.params;
        let auth = this.$cookies.get('auth');

        $('#texto').focus();

        window.onpopstate = function (e) {};

        let funcIniciaTimer = function () {
            // Inicia o timer de inatividade do cliente
            if (vm.tempoInativo != '' &&
                vm.tempoInativo != null &&
                vm.tempoInativo != false &&
                vm.tempoInativo != '00:00:00'
            ) {
                let msTempoInativo = time_to_ms(vm.tempoInativo);

                clearInterval(vm.timerInativo);
                clearInterval(vm.timerEncerrar);

                if (msTempoInativo > 0) {
                    vm.timerInativo = setTimeout(
                        function () {
                            vm.avisoInativo(vm.tempoInativo, vm.encerraInativo, msTempoInativo)
                        }, msTempoInativo
                    );
                }
            }
        }

        vm.$socket.on('conversa-atualiza', ({isAtendente}) => {
            vm.$socket.emit('conversa-carrega', params.sala, res => {
                vm.conversa = res.mensagens;
                vm.nomeAtendente = res.dados.atendente;
                // Verifica se a última mensagem foi do atendente
                if (isAtendente) {
                    aviso(res.mensagens, vm.nomeAtendente);
                    new Audio('/mp3/novaMensagem.wav').play().catch(error => {});
                    // Inicia o timer de inatividade do cliente
                    funcIniciaTimer();
                }

            });
        });

        vm.$socket.on('atendimento-finalizado', data => {
            if (typeof data.transferencia != 'undefined') {
                vm.$sweet.alert('Atenção', `Seu atendimento foi transferido para o sistema ${data.sistema.nome}`, 'warning');

                $('.box-mensagem').remove();

                vm.$socket.emit('cliente-transferencia', {
                    atendimentoId: params.sala,
                    sistema: data.sistema,
                    auth,
                }, function (atendimentoId, dados, err) {
                    if (err) vm.$sweet.alert('Atenção', err, 'warning');

                    auth.atendimento.sistemaLinha = dados.form.linha_sistema;
                    auth.atendimento.sistemaNome = data.sistema.nome;
                    auth.atendimento.sistemaId = data.sistema.agrupador;
                    auth.atendimento.sistemaOriginal = data.sistema.sistemaId;
                    auth.atendimentoId = atendimentoId;

	                if (process.env.VUE_APP_ENV === 'dev')
		                vm.$cookies.set('auth', auth)
	                else
		                vm.$cookies.set('auth', auth, null, null, window.location.host);

                    vm.$router.push({name: 'sala-espera', params: {'duvida': dados.form.duvida}});
                });

                clearInterval(vm.timerInativo);
                clearInterval(vm.timerEncerrar);
                return;
            }

            if (typeof data.inativo != 'undefined' && data.inativo) {
                vm.conversa.push(data.msg2);
                vm.$sweet.alert('Atenção', `O seu atendimento foi encerrado
                    automaticamente por inatividade. Evite filas investindo em
                    treinamento e sendo breve no atendimento.`, 'warning');

                $('.box-mensagem').remove();

                clearInterval(vm.timerInativo);
                clearInterval(vm.timerEncerrar);

                return;
            }

            if (!vm.encerradoInativo && !vm.encerradoAvaliacao) {
                vm.$sweet.alert('Atenção', remove_html(data.msg), 'warning');
                vm.conversa.push(data.msg);

                let labelEncerrado = $('.label-encerrado').text();

                if (!labelEncerrado) {
                    $('.label-encerrado').text('Atendimento encerrado pelo atendente.');
                }

                $('.box-mensagem').remove();

                clearInterval(vm.timerInativo);
                clearInterval(vm.timerEncerrar);
            }

            if (!vm.encerradoAvaliacao) {
                vm.abreAvaliacao();
            }
        });

        // Verifica as informações de atendimento para iniciar o timer de inatividade
        this.info().then(function (chatRow) {
            vm.tempoInativo = chatRow.linha_sistema == 2 ? auth.dados.clienteInativoUnico : auth.dados.clienteInativo;
            vm.encerraInativo = chatRow.linha_sistema == 2 ? auth.dados.encerraInativoUnico : auth.dados.encerraInativo;

            funcIniciaTimer();
        });

        this.$nextTick(function () {
            vm.buscaAvisos();
        });

        vm.$socket.on('atualiza-avisos', function () {
            $('.accordion dt').remove();
            $('.accordion dd').remove();

            vm.buscaAvisos();

            setTimeout(function () {
                $('.bt-avisos').trigger('click');
            }, 500);
        });

        // PRT SCREEN + CTRL+V
        pasteImg.on('pasting-image', function() {
            $('#texto')
                .attr('placeholder', 'Enviando imagem, aguarde...')
                .attr('disabled', 'disabled');
        });

        pasteImg.on('paste-image', async (image) => {
            if (image != null && ['image/png', 'image/jpeg'].indexOf(image.type) >= 0) {
                let blob   = await blobutil.arrayBufferToBlob(image, image.type);
                let base64 = await blobutil.blobToBase64String(blob);

                vm.$socket.emit(
                    'conversa-salva-print', {
                        src: base64,
                        type: image.type,
                        atendimentoId: params.sala
                    }, data => {
                        $('#texto').removeAttr('disabled');
                        $('#texto').attr('placeholder', 'Digite sua mensagem ou copie e cole seu print aqui e aperte Enter para enviar...');

                        if (data === false) {
                            vm.$sweet.alert('Atenção', 'A imagem que você está tentando enviar não é válida', 'warning');
                            return;
                        }

                        let msg = `<a href="${data}" data-fancybox style="text-decoration:none">
                            <img src="${data}" style="max-width: 300px;" /></a>`;

                        vm.$socket.emit('conversa-grava', {
                            mensagem: msg,
                            atendimentoId: params.sala,
                            auth
                        }, function(err) {
                            funcIniciaTimer();
                            vm.resetMensagem(err);
                        });
                    }
                );

                return;
            }

            $('#texto').removeAttr('disabled');
            $('#texto').attr('placeholder', 'Digite sua mensagem ou copie e cole seu print aqui e aperte Enter para enviar...');
            $('#texto').focus();
        });

        // Uploader
        let uploader = new SocketIOFileUpload(this.$socket);
            uploader.useBuffer = false;  // false => usa base64 para transferência
            uploader.maxFileSize = 26214400; // Limitação de arquivos de até 50MB
            uploader.chunkSize = 1024 * 100; // Quebra o arquivo em vários pedaços de 100KB para o progressbar

        $('#uploader_button').on('click', function() {$('#uploader_input').trigger('click')});

        $('#uploader_submit').on('click', function() {
            uploader.submitFiles(document.getElementById('uploader_input').files);
        });

        uploader.addEventListener('start', function(event) {
            event.file.meta.atendente = 0;
            event.file.meta.atendimentoId = params.sala;
            $('#uploader_submit').attr('disabled', 'disabled');
            vm.resetMensagem(null);
        });

        let inicioUpload = [];

        uploader.addEventListener('progress', function(event) {
            let loaded = event.bytesLoaded;
            let total = event.file.size;
            let perc = Math.round(loaded / total * 100);
            let arquivo = _.find(vm.files, {'name': event.file.name});

            if (typeof inicioUpload[arquivo.name] == 'undefined') {
                inicioUpload[arquivo.name] = new Date();
            }

            $('.progresso.file'+ arquivo.lastModified +' .bar').css('width', perc +'%');

            // Tempo faltando
            let segundosPassados = (new Date().getTime() - inicioUpload[arquivo.name].getTime()) / 1000;
            let bytesSegundo = segundosPassados ? loaded / segundosPassados : 0 ;
            let bytesFaltando = total - loaded;

            let segundosFaltando = segundosPassados ? Math.ceil(bytesFaltando / bytesSegundo) : 0;
                segundosFaltando = new Date(segundosFaltando * 1000).toISOString().substr(11, 8);

            let textoProgresso   = perc +'% - Tempo estimado: ';
                textoProgresso  += segundosFaltando +' - ';
                textoProgresso  += vm.formataBytes(bytesSegundo, 2) +'/s';

            $('.perc_progresso.file'+ arquivo.lastModified).text(textoProgresso);
        });

        uploader.addEventListener('error', function(event) {
            let msg = `Houve um erro durante o upload dos arquivos:<br />
                <b>${event.message}</b>`;

            vm.$sweet.alert('Atenção', msg, 'error');
        });

        let uploadedFiles = 0;

        vm.$socket.on('siofu_complete', function() {
            funcIniciaTimer();

            uploadedFiles++;

            if (uploadedFiles >= vm.files.length) {
                vm.files = [];
                $('#uploader_input').val('');
                $('#uploader_submit').removeAttr('disabled');
            }
        });

        vm.$socket.on('atendimento-derrubado', function () {
            let msgAviso = `Caro usuário, tivemos um problema técnico no nosso
                atendimento, solicitamos que entre novamente no atendimento
                on-line para que possamos lhe atender prontamente.<br />
                Desculpe pelo transtorno.<br />Esta janela fechará
                automaticamente ao clicar no botão OK
            `;

            vm.$sweet.alert('Atenção', msgAviso, 'warning', 'OK', true, function () {
                window.close();
            });
        });
    },
    updated() {
        if (this.rolar) {
            $('html, body').scrollTop(99999);
        }
    }
}

</script>
<style>
    #conversa .hide-cliente { display: none; }
</style>
<style scoped>
    .label-star {
        display: inline-block;
        width: 100%;
        position: relative;
        top: -10px;
    }
    .erro-avaliacao {
        display: inline-block;
        color: red;
        position: relative;
        width: 100%;
        top: 5px;
    }
</style>
