<template>

    <div id="box-totais" v-if="consultaTotaisChat" style="display: none; width: 100%; max-width: 1200px; padding: 15px;">
        <h1 class="h1">Totais</h1>
        <div class="b-xs-12"
                v-if="totais.emEspera && (totais.emEspera.cliente + totais.emEspera.revenda) > 0"
        >
            <b class="titulo-totais" @click="showHide('totaisEspera')" id="totaisEspera">
                Fila de espera - por sistema (clique para abrir/fechar):
            </b>
            <div class="box-totais-tabela" style="display: none">
                <table class="parvus-table-base grid condensed vertical-separator"
                    v-for="(r, revenda_id) in totais.emEspera.porRevenda" :key="revenda_id"
                >
                    <thead>
                        <tr>
                            <th style="text-align: right">
                                {{ revenda(revenda_id) }}
                            </th>
                            <th width="15%" v-show="canal == 'A'">
                                Clientes: {{ r.cliente }}
                            </th>
                            <th width="15%" v-show="canal == 'A'">
                                Revendas: {{ r.revenda }}
                            </th>
                            <th width="15%">
                                Total: {{ r.cliente + r.revenda }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s, sistema_id) in r.porSistema" :key="sistema_id"
                        >
                            <td style="text-align: right">
                                {{ sistema(sistema_id) }}
                            </td>
                            <td width="15%" v-show="canal == 'A'">
                                {{ s.cliente }}
                            </td>
                            <td width="15%" v-show="canal == 'A'">
                                {{ s.revenda }}
                            </td>
                            <td width="15%">
                                {{ s.cliente + s.revenda }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br class="clear" />
        </div>
        <div class="b-xs-12"
                v-if="totais.emAtendimento && (totais.emAtendimento.cliente + totais.emAtendimento.revenda) > 0"
        >
            <b class="titulo-totais" @click="showHide('totaisEmAtend')" id="totaisEmAtend">
                Em atendimento - por sistema (clique para abrir/fechar):
            </b>
            <div class="box-totais-tabela" style="display: none">
                <table class="parvus-table-base grid condensed vertical-separator"
                    v-for="(r, revenda_id) in totais.emAtendimento.porRevenda" :key="revenda_id"
                >
                    <thead>
                        <tr>
                            <th style="text-align: right">
                                {{ revenda(revenda_id) }}
                            </th>
                            <th width="15%" v-show="canal == 'A'">
                                Clientes: {{ r.cliente }}
                            </th>
                            <th width="15%" v-show="canal == 'A'">
                                Revendas: {{ r.revenda }}
                            </th>
                            <th width="15%">
                                Total: {{
                                    (['A','C'].indexOf(canal) >= 0 ? r.cliente : 0) +
                                    (['A','P'].indexOf(canal) >= 0 ? r.revenda : 0)
                                }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s, sistema_id) in r.porSistema" :key="sistema_id">
                            <td style="text-align: right">
                                {{ sistema(sistema_id) }}
                            </td>
                            <td width="15%" v-show="canal == 'A'">
                                {{ s.cliente }}
                            </td>
                            <td width="15%" v-show="canal == 'A'">
                                {{ s.revenda }}
                            </td>
                            <td width="15%">
                                {{ s.cliente + s.revenda }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br class="clear" />
        </div>
        <div class="b-xs-12"
                v-if="totais.emAtendimentoAtendente && Object.keys(totais.emAtendimentoAtendente).length > 0"
                id="boxTotaisAtendentes"
        >
            <b class="titulo-totais" @click="showHide('totaisAtend')" id="totaisAtend">
                Atendentes em atendimento (clique para abrir/fechar):
            </b>
            <div class="box-totais-tabela atendentes" style="display: none">
                <span>
                    <b class="titulo-totais-revenda" @click="showHideAtend(0)" id="rev0">
                        Geral (clique para abrir/fechar):
                    </b>
                    <table class="parvus-table-base grid condensed vertical-separator" style="display: none">
                        <thead>
                            <tr>
                                <th style="text-align: right">
                                    {{ revenda(0) }}
                                </th>
                                <th width="15%" v-show="canal == 'A'">
                                    Clientes: {{ totais.emAtendimento.porRevenda['0'].cliente }}
                                </th>
                                <th width="15%" v-show="canal == 'A'">
                                    Revendas: {{ totais.emAtendimento.porRevenda['0'].revenda }}
                                </th>
                                <th width="15%">
                                    Total: {{
                                        (['A','C'].indexOf(canal) >= 0 ? totais.emAtendimento.porRevenda['0'].cliente : 0) +
                                        (['A','P'].indexOf(canal) >= 0 ? totais.emAtendimento.porRevenda['0'].revenda : 0)
                                    }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(s, sistemaId) in totais.emAtendimento.porRevenda['0'].porSistema" :key="sistemaId">
                                <td style="text-align: right">
                                    {{ sistema(sistemaId) }}
                                </td>
                                <td width="15%" v-show="canal == 'A'">
                                    {{ s.cliente }}
                                </td>
                                <td width="15%" v-show="canal == 'A'">
                                    {{ s.revenda }}
                                </td>
                                <td width="15%">
                                    {{ s.cliente + s.revenda }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </span>
                <br class="clear" />
                <span v-for="(aList, revendaId) in totais.emAtendimentoAtendente" :key="revendaId">
                    <b class="titulo-totais-revenda" @click="showHideAtend(revendaId)" :id="'rev'+revendaId">
                        {{ revenda(revendaId) }} (clique para abrir/fechar):
                    </b>
                    <table class="parvus-table-base grid condensed vertical-separator"
                        :style="{'display': revendaIdAtend === Number(revendaId) ? 'table':'none'}"
                        v-for="(a, atendKey) in aList" :key="atendKey"
                    >
                        <thead>
                            <tr>
                                <th style="text-align: right">
                                    {{ a.info.nome }}
                                </th>
                                <th width="15%" v-if="canal == 'A'">
                                    Clientes: {{ a.cliente }}
                                </th>
                                <th v-else>
                                    Tempo
                                </th>
                                <th width="15%" v-if="canal == 'A'">
                                    Revendas: {{ a.revenda }}
                                </th>
                                <th v-else>
                                    Revenda
                                </th>
                                <th width="15%">
                                    Total: {{ a.cliente + a.revenda }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="atendimento in a.porSistema" :key="atendimento.nome" class="em_atendimento">
                                <td style="text-align: right">
                                    {{ atendimento.nome }} - {{ atendimento.sistema }}
                                </td>
                                <td width="15%" class="datahora" :data-datetime="atendimento.dataAtendido">
                                    00:00
                                </td>
                                <td width="15%" colspan="2">
                                    {{ atendimento.revenda }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </span>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: ['consultaTotaisChat', 'canal', 'revendaIdAtend'],
    data() {
        return {
            aguardandoAtendimento: [],
            emAtendimento: [],
            totais: [],
        }
    },
    methods: {
        revenda: function (v) { return this.$parent.revenda(String(v)) },
        sistema: function (v) { return this.$parent.sistema(v) },
        showHide: function (box) {
            $('.titulo-totais:not(#'+box+')').parent().find('.box-totais-tabela').hide();
            $('#'+box).parent().find('.box-totais-tabela').toggle();
        },
        showHideAtend: function (revendaId) {
            $('#boxTotaisAtendentes .titulo-totais-revenda:not(#rev'+revendaId+')').parent().find('table').hide();
            $('#rev'+revendaId).parent().find('table').toggle();
        },
        carregaTotais: function () {
            this.aguardandoAtendimento = this.$parent.aguardandoAtendimento;
            this.emAtendimento = this.$parent.emAtendimento;

            let totais = {
                emEspera: {
                    cliente: 0,
                    revenda: 0,
                    porSistema: {}, // porSistema[sistema_id] = {revenda: 0, cliente: 0}
                    porRevenda: {
                        '0': {
                            'revenda': 0,
                            'cliente': 0,
                            'porSistema': {}
                        }
                    }, // porRevenda[revenda_id] = {revenda: 0, cliente: 0, porSistema[sistema_id]: {revenda: 0, cliente: 0}}
                },
                emAtendimento: {
                    cliente: 0,
                    revenda: 0,
                    porSistema: {}, // porSistema[sistema_id] = {revenda: 0, cliente: 0}
                    porRevenda: {
                        '0': {
                            'revenda': 0,
                            'cliente': 0,
                            'porSistema': {}
                        }
                    }, // porRevenda[revenda_id] = {revenda: 0, cliente: 0, porSistema[sistema_id]: {revenda: 0, cliente: 0}}
                },
                emAtendimentoAtendente: {
                    // emAtendimentoAtendente[revenda_id][pessoa_id] = {info: {}, contagem: 0, porSistema[sistema_id]: {revenda: 0, cliente: 0}}
                },
            };

            _.forEach(this.aguardandoAtendimento, function(chatRow) {
                let revendaReal = Number(chatRow.revenda_chat_nome.split(' - ')[0]);

                if (typeof totais.emEspera.porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emEspera.porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (typeof totais.emEspera.porRevenda[revendaReal] == 'undefined') {
                    totais.emEspera.porRevenda[revendaReal] = {revenda: 0, cliente: 0, porSistema: {}}
                }

                if (typeof totais.emEspera.porRevenda[revendaReal].porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emEspera.porRevenda[revendaReal].porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (chatRow.atendido_id < 80000) {
                    totais.emEspera.cliente++;
                    totais.emEspera.porSistema[chatRow.sistema_original].cliente++;
                    totais.emEspera.porRevenda[revendaReal].cliente++;
                    totais.emEspera.porRevenda[revendaReal].porSistema[chatRow.sistema_original].cliente++;
                } else {
                    totais.emEspera.revenda++;
                    totais.emEspera.porSistema[chatRow.sistema_original].revenda++;
                    totais.emEspera.porRevenda[revendaReal].revenda++;
                    totais.emEspera.porRevenda[revendaReal].porSistema[chatRow.sistema_original].revenda++;
                }

                // Geral
                if (typeof totais.emEspera.porRevenda['0'].porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emEspera.porRevenda['0'].porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (chatRow.atendido_id < 80000) {
                    totais.emEspera.porRevenda['0'].cliente++;
                    totais.emEspera.porRevenda['0'].porSistema[chatRow.sistema_original].cliente++;
                } else {
                    totais.emEspera.porRevenda['0'].revenda++;
                    totais.emEspera.porRevenda['0'].porSistema[chatRow.sistema_original].revenda++;
                }
            });

            _.forEach(this.emAtendimento, function(chatRow) {
                let revendaReal = Number(chatRow.revenda_chat_nome.split(' - ')[0]);

                if (typeof totais.emAtendimento.porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emAtendimento.porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (typeof totais.emAtendimento.porRevenda[revendaReal] == 'undefined') {
                    totais.emAtendimento.porRevenda[revendaReal] = {revenda: 0, cliente: 0, porSistema: {}}
                }

                if (typeof totais.emAtendimento.porRevenda[revendaReal].porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emAtendimento.porRevenda[revendaReal].porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (chatRow.atendido_id < 80000) {
                    totais.emAtendimento.cliente++;
                    totais.emAtendimento.porSistema[chatRow.sistema_original].cliente++;
                    totais.emAtendimento.porRevenda[revendaReal].cliente++;
                    totais.emAtendimento.porRevenda[revendaReal].porSistema[chatRow.sistema_original].cliente++;
                } else {
                    totais.emAtendimento.revenda++;
                    totais.emAtendimento.porSistema[chatRow.sistema_original].revenda++;
                    totais.emAtendimento.porRevenda[revendaReal].revenda++;
                    totais.emAtendimento.porRevenda[revendaReal].porSistema[chatRow.sistema_original].revenda++;
                }

                // Geral
                if (typeof totais.emAtendimento.porRevenda['0'].porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emAtendimento.porRevenda['0'].porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (chatRow.atendido_id < 80000) {
                    totais.emAtendimento.porRevenda['0'].cliente++;
                    totais.emAtendimento.porRevenda['0'].porSistema[chatRow.sistema_original].cliente++;
                } else {
                    totais.emAtendimento.porRevenda['0'].revenda++;
                    totais.emAtendimento.porRevenda['0'].porSistema[chatRow.sistema_original].revenda++;
                }

                // Listagem por atendente
                let atendenteKey = chatRow.atendente_nome.split(' - ');
                let revendaKey = atendenteKey[0];
                let pessoaKey = atendenteKey[2].toLowerCase().replace(/[^a-z]/g, '');

                if (typeof totais.emAtendimentoAtendente[revendaKey] == 'undefined') {
                    totais.emAtendimentoAtendente[revendaKey] = {};
                }

                if (typeof totais.emAtendimentoAtendente[revendaKey][pessoaKey] == 'undefined') {
                    totais.emAtendimentoAtendente[revendaKey][pessoaKey] = {
                        info: {
                            nome: chatRow.atendente_nome
                        },
                        cliente: 0,
                        revenda: 0,
                        porSistema: []
                    }
                }
                if (chatRow.atendido_id < 80000) {
                    let nomeExibe = chatRow.nome_exibicao.split(' - ');
                        nomeExibe = `${nomeExibe[0]} - ${nomeExibe[1]} - ${chatRow.usuario_nome}`;

                    totais.emAtendimentoAtendente[revendaKey][pessoaKey].cliente++;
                    totais.emAtendimentoAtendente[revendaKey][pessoaKey].porSistema.push({
                        pessoaId: chatRow.atendido_id,
                        nome: nomeExibe,
                        dataAtendido: chatRow.data_atendido,
                        revenda: chatRow.revenda_chat_nome,
                        sistema: chatRow.sistema_nome,
                    });
                } else {
                    totais.emAtendimentoAtendente[revendaKey][pessoaKey].revenda++;
                    totais.emAtendimentoAtendente[revendaKey][pessoaKey].porSistema.push({
                        pessoaId: chatRow.atendido_id,
                        nome: chatRow.nome_exibicao,
                        dataAtendido: chatRow.data_atendido,
                        revenda: chatRow.revenda_chat_nome,
                        sistema: chatRow.sistema_nome,
                    });
                }
            });

            // Ordenação da chave de em espera
            totais.emEspera.porSistema = Object.keys(totais.emEspera.porSistema)
                .sort()
                .reduce((finish, cVal) => {
                    finish[cVal] = totais.emEspera.porSistema[cVal];
                    return finish;
                }, {});

            totais.emEspera.porRevenda = Object.keys(totais.emEspera.porRevenda)
                .sort()
                .reduce((finish, cVal) => {
                    finish[cVal] = totais.emEspera.porRevenda[cVal];
                    return finish;
                }, {});

            // Ordenação da chave de em atendimento
            totais.emAtendimento.porSistema = Object.keys(totais.emAtendimento.porSistema)
                .sort()
                .reduce((finish, cVal) => {
                    finish[cVal] = totais.emAtendimento.porSistema[cVal];
                    return finish;
                }, {});
            totais.emAtendimento.porRevenda = Object.keys(totais.emAtendimento.porRevenda)
                .sort()
                .reduce((finish, cVal) => {
                    finish[cVal] = totais.emAtendimento.porRevenda[cVal];
                    return finish;
                }, {});

            // Ordenação da chave de atendimento por atendente revenda e nome
            totais.emAtendimentoAtendente = Object.keys(totais.emAtendimentoAtendente)
                .sort()
                .reduce((finish, revendaKey) => {
                    totais.emAtendimentoAtendente[revendaKey] = Object.keys(totais.emAtendimentoAtendente[revendaKey])
                        .sort()
                        .reduce((finish2, pessoaKey) => {
                            finish2[pessoaKey] = totais.emAtendimentoAtendente[revendaKey][pessoaKey];

                            // Ordena o por sistema pelo código do cliente
                            finish2[pessoaKey].porSistema = _.orderBy(
                                finish2[pessoaKey].porSistema,
                                ['pessoaId'], ['asc']
                            );

                            return finish2;
                        }, {});

                    finish[revendaKey] = totais.emAtendimentoAtendente[revendaKey];

                    return finish;
                }, {});

            this.totais = totais
        },
    },
}

</script>

<style>
    .titulo-totais-revenda {
        display: inline-block;
        width: 100%;
        background: #0d538c;
        padding: 5px;
        color: white;
        cursor: pointer;
        text-transform: uppercase;
    }
</style>