<template>
    <div id="paginacao">
        <div class="parvus-table-acao clearfix">
            <div class="ferramentas">
                <div class="b-xs-12 b-md-12 b-xl-8 align-left align-center-xs align-center-sm">
                    <div class="numRegistros">
                        <label>Exibir:&nbsp;</label>
                        <select class="text parvus-table-paginacao-select" :name="limiteName" tabindex="2">
                            <option value="10" :selected="quantidade == '10'">10</option>
                            <option value="50" :selected="quantidade == '50'">50</option>
                            <option value="100" :selected="quantidade == '100'">100</option>
                            <option value="150" :selected="quantidade == '150'">150</option>
                        </select>
                        &nbsp;
                        <span class="por-pagina">itens por página.</span>
                    </div>
                    <div class="numRegistros">
                        <label>Página:&nbsp;</label>
                        <select :name="paginaName" class="text parvus-table-paginacao-select" v-if="paginas > 0">
                            <option v-for="pagina in paginas" :selected="atual == String(pagina)" :key="pagina">
                                {{ pagina }}
                            </option>
                        </select>
                    </div>
                    <div class="paginador">
                        <span class="paginador_exibindo">
                            Exibindo <b>{{ numMinimo }}</b>-<b>{{ numMaximo }}</b> de
                            <b class="paginador_total">{{ total }}</b> itens.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Paginacao",
        props: ['paginas','total','atual','quantidade','limiteName','paginaName', 'componente'],
        data() {
            let maximo = Number(this.atual) * Number(this.quantidade);
            let minimo = maximo - Number(this.quantidade);

            return {
                numMinimo: minimo <= 0 ? 1 : minimo,
                numMaximo: maximo,
            }
        },
        mounted() {
            let vm = this;

            setTimeout(function () {
                //Troca página
                $('[name="'+vm.paginaName+'"]').change(function () {
                    let val = $(this).val();

                    vm.$parent.alteraFiltro({ pagina: val });

                    if (vm.componente == 'consultaLog') {
                        vm.$parent.buscaLog();
                    } else {
                        vm.$parent.buscaAtendimentos();
                    }

                    vm.numMaximo = Number(val) * Number(vm.quantidade);

                    let minimo = vm.numMaximo - Number(vm.quantidade);

                    vm.numMinimo = (minimo) <= 0 ? 1 : minimo;

                    let targetOffset = $('#app').offset().top;
                    // go top
                    $('html, body').animate({ scrollTop: targetOffset }, {duration: 500});
                });
                //Troca quantidade
                $('[name="'+vm.limiteName+'"]').change(function () {
                    let val = Number($(this).val());

                    vm.$parent.alteraFiltro({ quantidade: val });

                    if (vm.componente == 'consultaLog') {
                        vm.$parent.buscaLog();
                    } else {
                        vm.$parent.buscaAtendimentos();
                    }

                    vm.$parent.paginacao.quantidade = val;
                    vm.numMaximo = Number(vm.atual) * val;

                    let minimo = vm.numMaximo - val;

                    vm.numMinimo = (minimo) <= 0 ? 1 : minimo;

                    let targetOffset = $('#app').offset().top;
                    // go top
                    $('html, body').animate({ scrollTop: targetOffset }, {duration: 500});
                });
            }, 1500);
        },
        updated() {
            let maximo = Number(this.atual) * Number(this.quantidade);
            let minimo = maximo - Number(this.quantidade);

            this.numMinimo = minimo <= 0 ? 1 : minimo;
            this.numMaximo = maximo;
        }
    }
</script>

<style scoped>

</style>
