<template>

    <div v-if="consultaTotaisChat" id="atendentes-dispo" style="display: none; width: 100%; max-width: 1200px; padding: 15px;">
        <h1 class="h1">Atendentes disponíveis - clique no nome do atendente para abrir/fechar</h1>
        <div class="b-xs-12" v-for="a in atendentes" :key="a.pessoaId">
            <b class="titulo-atendentes" @click="showHide(a.pessoaId)">
                {{ a.pessoaId }} - {{ a.nome }} -
                REVENDA {{ a.revendaOriginal }} -
                CONECTADO DESDE: {{exibeData(a.logEntrada, true)}} -
                CANAIS SELECIONADOS:
                {{ a.canal.indexOf('C') >= 0 ? 'Clientes' : ''}}
                {{ a.canal.length > 1 ? ' e ': ''}}
                {{ a.canal.indexOf('P') >= 0 ? 'Revendas' : ''}}
                - EM ATENDIMENTO:
                <span v-show="typeof atendenteTotal[a.pessoaId] != 'undefined'">
                    {{ atendenteTotal[a.pessoaId].total }}
                </span>
            </b>
            <div class="atendente-sistemas flex" style="display: none" :id="'atendente'+a.pessoaId">
                <div class="flex-item"
                    v-if="_SISTEMA_ID_VISUAL.some(id => a.sistemas.includes(id))
                            || a.sistemas.indexOf(9991) >= 0
                            || a.sistemas.indexOf(9992) >= 0
                            || a.sistemas.indexOf(9993) >= 0"
                >
                    <div v-if="_SISTEMA_ID_VISUAL.some(id => a.sistemas.includes(id))">
                        <b>VISUAL</b>:
                        <br />
                        <div v-for="s in a.sistemas" :key="s"
                            v-html="_SISTEMA_ID_VISUAL.indexOf(s) >= 0 ? sistema(s, a.pessoaId) : null">
                        </div>
                        <br />
                    </div>

                    <b v-if="a.sistemas.indexOf(9991) >= 0 || a.sistemas.indexOf(9993) >= 0">
                        TI/INFRASTRUTURA/CLOUD - Visual
                        <span v-show="typeof atendenteTotal[a.pessoaId].sistemas[9991] != 'undefined' && atendenteTotal[a.pessoaId].sistemas[9991] > 0"
                            style="font-style: italic">
                            - <span style="color: red; font-weight: normal;">{{ atendenteTotal[a.pessoaId].sistemas[9991] }}</span>
                        </span>
                        <br />
                    </b>
                    <b v-if="a.sistemas.indexOf(9992) >= 0 || a.sistemas.indexOf(9993) >= 0">
                        TI/INFRASTRUTURA/CLOUD - Único
                        <span v-show="typeof atendenteTotal[a.pessoaId].sistemas[9992] != 'undefined' && atendenteTotal[a.pessoaId].sistemas[9992] > 0"
                            style="font-style: italic">
                            - <span style="color: red; font-weight: normal;">{{ atendenteTotal[a.pessoaId].sistemas[9992] }}</span>
                        </span>
                    </b>
                </div>
                <div class="flex-item" v-if="_SISTEMA_ID_WEB.some(id => a.sistemas.includes(id))">
                    <b>WEB</b>:
                    <br />
                    <div v-for="s in a.sistemas" :key="s"
                        v-html="_SISTEMA_ID_WEB.indexOf(s) >= 0 && s != 132 && s != 129 ? sistema(s, a.pessoaId) : null">
                    </div>
                    <div v-if="a.sistemas.indexOf(129) >= 0 || a.sistemas.indexOf(132) >= 0">
                        SCI APP 4.0
                    </div>
                    <br />
                </div>
                <div class="flex-item" v-if="_SISTEMA_ID_UNICO.some(id => a.sistemas.includes(id))">
                    <b>ÚNICO</b>:
                    <br />
                    <div v-for="s in a.sistemas" :key="s"
                        v-html="_SISTEMA_ID_UNICO.indexOf(s) >= 0 ? sistema(s, a.pessoaId) : null">
                    </div>
                    <br />
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: ['consultaTotaisChat'],
    data() {
        return {
            atendentes: [],
            atendenteTotal: {},
        }
    },
    methods: {
        exibeData: function (v) { return this.$parent.exibeData(v, true); },
        revenda: function (v) { return this.$parent.revenda(v) },
        sistema: function (v, pessoaId) {
            let nomeSistema = this.$parent.sistema(v);
            let totalSistema = this.atendenteTotal[pessoaId].sistemas[v];

            if (typeof totalSistema !== "undefined" && totalSistema > 0) {
                nomeSistema += ` - <span style="color: red; font-style: italic">${totalSistema}</span>`;
            }

            return nomeSistema;
        },
        showHide: function (pessoaId) {
            $('.atendente-sistemas:not(#atendente'+pessoaId+')').hide();
            $('#atendente'+pessoaId).toggle();
        },
        busca: function () {

            let vm = this;
            let config = this.$cookies.get('auth').config;
            let dados = this.$cookies.get('auth').dados;

            vm.$socket.emit('busca-atendentes-online', null, (data) => {

                vm.atendentes = data;
                vm.atendentes = _.orderBy(vm.atendentes,
                    ['revendaOriginal', 'nome'],
                    ['asc', 'asc']
                );

                _.remove(vm.atendentes, item => {

                    if (dados.representanteOriginalId != 1 && item.revendaOriginal != dados.representanteOriginalId)
                        return true;

                    if (config.canal != 'A' && !item.canal.includes(config.canal))
                        return true;

                    return !item.sistemas
                        .some(id => config.sistemas.includes(id));

                });

                vm.atendentes.forEach(atendente => {

                    vm.atendenteTotal[atendente.pessoaId] = {total: 0, sistemas: {}};

                    atendente.sistemas.map(sistemaId => {

                        vm.atendenteTotal[atendente.pessoaId].sistemas[sistemaId] = 0;

                        if (sistemaId === 9993) {

                            vm.atendenteTotal[atendente.pessoaId].sistemas[9991] = 0;
                            vm.atendenteTotal[atendente.pessoaId].sistemas[9992] = 0;

                        }

                    });

                });

                vm.$parent.emAtendimento.forEach(chatRow => {

                    if (typeof vm.atendenteTotal[chatRow.atendente_id] == 'undefined')
                      return;

                    if (chatRow.sistema_id === 999) {

                        if (chatRow.linha_sistema === 1) {
                            vm.atendenteTotal[chatRow.atendente_id].sistemas[9991]++;
                        } else {
                            vm.atendenteTotal[chatRow.atendente_id].sistemas[9992]++;
                        }

                        vm.atendenteTotal[chatRow.atendente_id].total++;

                    } else {

                        if (typeof vm.atendenteTotal[chatRow.atendente_id].sistemas[chatRow.sistema_id] == 'undefined')
                            return;

                        vm.atendenteTotal[chatRow.atendente_id].total++;
                        vm.atendenteTotal[chatRow.atendente_id].sistemas[chatRow.sistema_id]++;

                    }

                });

            });

        },

    }

}

</script>
