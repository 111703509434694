<template>

    <div class="informacoes">
        <h3 class="h3">Informações de atendimento</h3>
        <table class="parvus-table-base grid condensed vertical-separator">
            <thead>
                <tr>
                    <th width="5%" class="align-center parvus-table-coluna-ordena">SITUAÇÃO</th>
                    <th width="10%" class="align-left parvus-table-coluna-ordena">CLIENTE</th>
                    <th class="align-left parvus-table-coluna-ordena">USUÁRIO</th>
                    <th width="5%" class="align-left parvus-table-coluna-ordena">SISTEMA</th>
                    <th width="11%" class="align-left parvus-table-coluna-ordena">REVENDA DE ATENDIMENTO</th>
                    <th width="11%" class="align-left parvus-table-coluna-ordena">REVENDA</th>
                    <th width="3%" class="align-center parvus-table-coluna-ordena">INÍCIO</th>
                    <th width="3%" class="align-center parvus-table-coluna-ordena">TEMPO</th>
                    <th width="15%" class="align-left parvus-table-coluna-ordena">ATENDENTE</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td width="5%">{{ info.situacao }}</td>
                    <td width="10%" class="fila_usuario">{{ info.atendido_nome }}</td>
                    <td class="fila_usuario" align="left">{{ info.contato }}</td>
                    <td width="5%">{{ info.sistema_nome }}</td>
                    <td width="11%">{{ info.revenda_chat_nome }}</td>
                    <td width="11%">{{ info.revenda_pai_nome }}</td>
                    <td width="3%" class="datahora">{{ converteHora(info.data_atendido) }}</td>
                    <td width="3%" class="tempo">00:00</td>
                    <td width="15%">{{ info.atendente_nome }}</td>
                </tr>
            </tbody>
        </table>
        <table class="parvus-table-base grid condensed vertical-separator">
            <thead>
                <tr>
                    <th width="5%" v-show="isUnico" class="align-left parvus-table-coluna-ordena">REF</th>
                    <th width="10%" class="align-left parvus-table-coluna-ordena">IMPLEMENTAÇÃO</th>
                    <th width="8%" v-show="isUnico" class="align-left parvus-table-coluna-ordena">TIPO</th>
                    <th width="8%" class="align-left parvus-table-coluna-ordena">VERSÃO DO SISTEMA</th>
                    <th v-if="info.nome_cliente" class="align-left parvus-table-coluna-ordena">
                        CLIENTE QUE GEROU ATENDIMENTO
                    </th>
                    <th v-if="info.nome_funcionario" class="align-left parvus-table-coluna-ordena">FUNCIONÁRIO</th>
                    <th class="align-left parvus-table-coluna-ordena">SITUAÇÃO/DÚVIDA</th>
                    <th width="32.1%" class="align-left parvus-table-coluna-ordena">PROCEDIMENTOS REALIZADOS</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td width="5%" v-show="isUnico">{{ info.referencial ? 'S' : 'N' }}</td>
                    <td width="10%" class="fila_usuario" align="left">{{ tipoImplantacao(info.implantacao) }}</td>
                    <td width="8%" v-show="isUnico">{{ nomeClassificacao(info.classificacao, info.brasao, info.sistema_id, info.linha_sistema) }}</td>
                    <td width="8%">{{ info.versao }}</td>
                    <td v-if="info.nome_cliente">{{ info.nome_cliente }}</td>
                    <td v-if="info.nome_funcionario">{{ info.nome_funcionario }}</td>
                    <td v-html="String(info.duvida).replace(/[\r\n|\r|\n]/gi, '<br />')"></td>
                    <td width="32.1%" v-html="String(info.procedimento).replace(/[\r\n|\r|\n]/gi, '<br />')"></td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script>

export default {
    props: ['info', 'isUnico'],
    methods: {
        nomeClassificacao: function (classificacao, brasao, sistema, unico) {
            return nome_classificacao(classificacao, sistema, unico);
        },
        tipoImplantacao: function (codigo) {
            return tipo_implantacao(codigo);
        },
        converteHora: function (v) { return this.$parent.converteHora(v); },
    }
}

</script>