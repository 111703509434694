<template>

	<div id="sala-espera">

		<fancybox-avisos
			:temAvisos="temAvisos"
			:avisos="avisos"
		></fancybox-avisos>

		<a href="javascript:;" style="display:none;" id="bt-aviso" data-fancybox="avisos" data-src="#box-avisos">aviso</a>

		<div class="b-xs-12">
			<b>{{ nomeUsuario }}</b>,
			você está em nossa fila de espera. Por favor, aguarde um momento que já vamos lhe atender!
			<a href="javascript:;" @click="alertaRegras" class="float-right bt" style="position: relative;top: -10px;">
				Orientações
			</a>
		</div>

		<div class="b-xs-12 box-fila">
			<div v-if="numeroFilaReal <= 1">
				Você é o próximo a ser atendido no {{ sistemaNome }}.
			</div>
			<div v-else>
				Você é o n&ordm; {{ numeroFilaReal }} na fila para atendimento no {{ sistemaNome }}.
			</div>
			<center style="font-size: 14px; display: table">
				<div style="min-width: 400px; display: table-cell">
					<a :href="'https://areadocliente.sci10.com.br/modulo/faq/view.php?sistemaId='+sistemaOriginal+linhaSistema"
					   target="_blank" class="bt bt-faq"
					   style="position: relative; top: 47px;"
					>
						<i class="ico-faq">
							<img src="/imagens/ico-faq.png" />
							<span>faq sci</span>
						</i>
						<span class="texto">
                            Enquanto você aguarda o atendimento,<br />
                            Consulte o FAQ!<br />
                            Aqui está a solução do seu problema!
                        </span>
					</a>
				</div>
				<div style="display: table-cell; width: 100%; padding: 0 10px;">
					<b style="color: red">
						Situação ou sua dúvida informada*:
						<a href="javascript:void(0);" class="link-ajuda"
						   title="Use o tempo que você está aguardando na fila para formular melhor a sua dúvida e agilizar o seu atendimento. Sua dúvida já é pré-avaliada pela nossa equipe de atendimento enquanto você estiver aguardando, por isso quanto mais detalhes você fornecer, mais rápido e ágil será o seu atendimento."
						>
							<span class="icon-ajuda"></span>
							<span class="visuallyhidden">Ajuda</span>
						</a>
					</b><br />
					<div class="campo">
						<textarea id="texto_duvida" style="height: 72px" v-text="duvida" maxlength="2000"></textarea><br />
						* máximo 2000 caracteres
					</div>
					<input type="button" class="bt" value="Atualizar texto" @click="atualizaDuvida" /><br />
				</div>
			</center>
		</div>

		<br class="clear" />
		<br class="clear" />

		<box-avisos
			:temAvisos="temAvisos"
			:mostraAvisoTodos="mostraAvisoTodos"
			:avisos="avisos"
		></box-avisos>

		<box-avisos-todos
			:temAvisos="temAvisos"
			:mostraAvisoTodos="mostraAvisoTodos"
			:avisos="avisos"
		></box-avisos-todos>

		<br class="clear" />
		<br class="clear" />

		<informativos
			:temInformativo="temInformativo"
			:linkInformativos="linkInformativos"
		></informativos>

	</div>

</template>


<script>
import FancyboxAvisos from './sala-espera/FancyboxAvisos';
import BoxAvisos from './sala-espera/BoxAvisos';
import BoxAvisosTodos from './sala-espera/BoxAvisosTodos';
import Informativos from './sala-espera/Informativos';

require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

let _ = require('lodash');

export default {
	name: 'sala-espera',
	data() {
		this.$parent.verifica_login();
		let auth = this.$cookies.get('auth');
		let linhaSistema = auth.atendimento.sistemaLinha === '1' ? 'w' : 'u';

		if (this._SISTEMA_ID_WEB.indexOf(Number(auth.atendimento.sistemaId)) >= 0) {
			linhaSistema = 'e';
		}

		return {
			atendimentoI: auth.atendimentoId,
			pessoaId: auth.dados.pessoaId,
			numeroFila: 999,
			numeroFilaReal: 999,
			nomeUsuario: auth.atendimento.nome,
			sistemaNome: auth.atendimento.sistemaNome,
			sistemaOriginal: auth.atendimento.sistemaId,
			linhaSistema: '&linha='+linhaSistema,
			temInformativo: false,
			linkInformativos: '/modal/informativossci.php?pessoa_id='+auth.dados.pessoaId+'&todos=1',
			temAvisos: false,
			avisos: [],
			mostraAvisoTodos: false,
			avisoAtual: 0,
			avisoW: '93vw',
			avisoAnimate: {duration: 500, queue: false},
			timerAviso: setTimeout(() => {}, 400),
			duvida: '',
			obsAtendente: '',
		}
	},
	components: {
		FancyboxAvisos,
		BoxAvisos,
		BoxAvisosTodos,
		Informativos,
	},
	methods: {
		marcaAvisoVisto: function (id) {
			let vm = this;
			let auth = vm.$cookies.get('auth');
			let foiVisto = false;

			_.forEach(this.avisos, function (obj) {
				if (obj.id === id) {
					obj.visto = true;

					if (typeof auth.adicional !== 'undefined' && auth.adicional) {
						if (obj.visualizadosAdicionais.indexOf(auth.adicional.id) !== -1)
							foiVisto = true;

						obj.visualizadosAdicionais.push(auth.adicional.id);
					} else {
						if (obj.visualizados.indexOf(vm.pessoaId) !== -1)
							foiVisto = true;

						obj.visualizados.push(vm.pessoaId);
					}
				}
			});

			if (!foiVisto && id) {
				if (typeof auth.adicional != 'undefined' && auth.adicional) {
					this.$socket.emit('aviso-marca-visto', {
						pessoaId: vm.pessoaId,
						avisoId: id,
						usuarioId: auth.adicional.id
					});
				} else {
					this.$socket.emit('aviso-marca-visto', {
						pessoaId: vm.pessoaId,
						avisoId: id
					});
				}
			}
		},
		converteDataAviso: function (val) {

			let date = val.split(' ');
			let data = exibe_data(date[0]);

			if (typeof date[1] == 'undefined')
				return data;

			let hora = date[1];

			if (hora !== '00:00:01' && hora !== '23:59:59')
				return exibe_data(date, true);

			return data;

		},
		alertaRegras: function () {
			let auth = this.$cookies.get('auth');

			this.$socket.emit('cliente-regras-atendimento', 999, (res) => {
				let texto = res.texto.replace(new RegExp('\r?\n','g'), '<br/>');

				if (auth.dados.clienteInativo)
					texto = texto.replace('#chatClienteInativo#', exibe_tempo_inativo(auth.dados.clienteInativo));

				if (auth.dados.encerraInativo)
					texto = texto.replace('#chatEncerraInativo#', exibe_tempo_inativo(auth.dados.encerraInativo));

				if (auth.dados.clienteInativoUnico)
					texto = texto.replace('#chatClienteInativoUnico#', exibe_tempo_inativo(auth.dados.clienteInativoUnico));

				if (auth.dados.encerraInativoUnico)
					texto = texto.replace('#chatEncerraInativoUnico#', exibe_tempo_inativo(auth.dados.encerraInativoUnico));

				this.$sweet.alert('Orientações', texto, 'info');
			});

		},
		buscaAvisos: async function () {
			let vm = this;
			let auth = this.$cookies.get('auth');

			vm.avisos = [];

			await new Promise((resolve) => {
				vm.$socket.emit('aviso-busca', auth, async function (dados) {
					if (dados.avisos.length > 0) vm.temAvisos = true;

					let total = dados.avisos.length;
					let count = 0;
					_.forEach(dados.avisos, function (obj) {
						let visualizados = _.find(dados.visualizados, {avisoId: obj.id});
						obj.texto = String(entities.decode(obj.texto)).replace(new RegExp('\r?\n','g'), '<br />');
						obj.visto = false;
						obj.visualizados = [];

						if (eh_aviso_interno(obj)) {
							obj.texto = `<b style="color: red">Atenção: esse aviso está aparecendo apenas para
								funcionários e/ou revendas da SCI. Essa mensagem não está visível para os
								clientes.</b><br />${obj.texto}`;
						}

						if (typeof visualizados != 'undefined') {
							visualizados = _.map(visualizados.visualizados.split(','), Number);
							obj.visualizados = visualizados;
						}

						let visualizadosAdicionais = _.find(dados.visualizadosAdicionais, {avisoId: obj.id});
						obj.visualizadosAdicionais = [];

						if (typeof visualizadosAdicionais != 'undefined') {
							visualizadosAdicionais = _.map(visualizadosAdicionais.visualizados.split(','), Number);
							obj.visualizadosAdicionais = visualizadosAdicionais;
						}

						if (typeof auth.adicional !== 'undefined' && auth.adicional != null) {
							if (obj.visualizadosAdicionais.indexOf(auth.adicional.id) !== -1)
								obj.visto = true;
						} else {
							if (obj.visualizados.indexOf(vm.pessoaId) !== -1) {
								obj.visto = true;
							}
						}

						vm.avisos.push(obj);
						count++;

						if (count >= total) {
							resolve(true);
						}
					});
				});
			});

			if (vm.avisos.length > 0) {
				vm.avisoAtual = 0;

				let firstBox = $('#avisos-fixo .aviso-box-item:eq(0)');
				let itemAvisoW = Math.ceil(firstBox.outerWidth());
				let itemAvisoH = Math.ceil(firstBox.outerHeight());
				vm.avisoW = itemAvisoW;
				$('#avisos-fixo .aviso-box-hide').css({
					width: itemAvisoW,
					height: itemAvisoH
				});
				$('#box-avisos').css({width: itemAvisoW});
				$('#box-avisos .aviso-box-item').css({width: itemAvisoW});

				let timer = Number(firstBox.data('timer'));

				clearInterval(vm.timerAviso);

				if (timer > 0) {
					vm.timerAviso = setTimeout(function () {
						vm.moveProximoAviso();
					}, timer);
				}

				let objAvisos = $('#avisos-fixo');
				let objAvisosBox = $('#box-avisos');
				let objItem = objAvisos.find('.aviso-box-item:eq(0)');
				let objH1 = objItem.find('h1').outerHeight(true);
				let objP = objItem.find('p').outerHeight(true);
				let h = objH1 + objP + 20;

				objAvisos.find('.aviso-box-hide').css({height: h + 'px'});
				objAvisosBox.find('.aviso-box-hide').css({height: h + 'px'});

				$('#bt-aviso').trigger('click');
				vm.marcaAvisoVisto(vm.avisos[0].id);

			}
		},
		timeToMs: function (time) {
			return time_to_ms(time);
		},
		moveProximoAviso: function () {
		this.avisoAtual++;

		if (this.avisoAtual >= this.avisos.length) this.avisoAtual = 0;

		this.mudaAviso();
		},
		moveAnteriorAviso: function () {
		this.avisoAtual--;

		if (this.avisoAtual < 0) this.avisoAtual++;

		this.mudaAviso();
		},
		mudaAviso: function () {
		let objAvisos = $('#avisos-fixo');

		if ($('#box-avisos').is(':visible')) objAvisos = $('#box-avisos');

		if (this.avisoAtual <= 0) objAvisos.find('.aviso-anterior').hide();
		if (this.avisoAtual > 0) objAvisos.find('.aviso-anterior').show();
		if (this.avisoAtual >= (this.avisos.length-1)) objAvisos.find('.aviso-proximo').hide();
		if (this.avisoAtual < (this.avisos.length-1)) objAvisos.find('.aviso-proximo').show();

		objAvisos.find('.aviso-box-item .h1').removeClass('piscapisca');

		let objItem = objAvisos.find('.aviso-box-item:eq('+this.avisoAtual+')');
		let objH1 = objItem.find('h1').outerHeight(true);
		let objP = objItem.find('p').outerHeight(true);
		let h = objH1+objP+20;  //altura verdadeira
		let move = -1 * (this.avisoW * this.avisoAtual);

		objItem.stop().animate({height: (h-20)+'px'}, this.avisoAnimate);

		objAvisos.find('.aviso-box-item:eq(0)').stop().animate({marginLeft: move+'px'}, this.avisoAnimate);
		objAvisos.find('.aviso-box-hide').stop().animate({height: h+'px'}, this.avisoAnimate);

		objAvisos.find('.aviso-box-item .h1').addClass('piscapisca');

		let timer = Number(objItem.data('timer'));
		let vm = this;

		clearInterval(this.timerAviso);

		if (timer > 0) {
			this.timerAviso = setTimeout(function () {
				vm.moveProximoAviso();
			}, timer);
		}

		this.marcaAvisoVisto(Number(objItem.data('id')));
		},
		verTodosAvisos: function () {
			this.mostraAvisoTodos = true;
			parent.$.fancybox.close();
		},
		closeFancybox: function () {
			parent.$.fancybox.close();
		},
		atualizaDuvida: function () {
			let vm = this;
			let duvida = $('#texto_duvida').val();
			let auth = vm.$cookies.get('auth');

			if (duvida.length <= 15) {
				let msgAlerta = '';
				console.log(vm);

				if (vm.obsAtendente)
					msgAlerta += `<b>Observação da equipe de atendimento: </b><br />`;
				msgAlerta += `<span style="color: red; font-weight: bold;" class="quebra-linha">${vm.obsAtendente}</span><br />`;
				msgAlerta += `O campo dúvida não foi preenchido por completo.<br />Por favor,
                    informe detalhadamente sua dúvida para que possamos melhor atendê-lo.<br />`;

				vm.$sweet.alert('Atenção', msgAlerta, 'error');

				return;
			}

			vm.$socket.emit('cliente-atualiza-duvida', {
				duvida: duvida,
				atendimentoId: auth.atendimentoId
			}, err => {
				if (err) vm.$sweet.alert('Atenção', err, 'error');
			});
		},
	},
	beforeCreate() {
		let vm = this;
		let auth = this.$cookies.get('auth');

		vm.$socket.emit('cliente-busca-posicao', auth.atendimentoId, num => {

			if (vm.numeroFilaReal === 0) {

				vm.numeroFilaReal = num;

			} else {

				vm.numeroFilaReal = vm.numeroFilaReal > num ? num : vm.numeroFilaReal;

			}

		});
	},
	created() {

		let vm = this;
		let auth = this.$cookies.get('auth');

		vm.$socket.emit('cliente-busca-posicao', auth.atendimentoId, num => {

			if (vm.numeroFilaReal === 0) {

				vm.numeroFilaReal = num;

			} else {

				vm.numeroFilaReal = vm.numeroFilaReal > num ? num : vm.numeroFilaReal;

			}

		});

	},
	beforeDestroy() {
		this.$socket.off('abrir-chat');
		this.$socket.off('atualizar-espera');
		this.$socket.off('atualiza-posicao');
		this.$socket.off('atualiza-avisos');
		this.$socket.off('atendimento-derrubado');
		this.$socket.off('alerta-duvida-incompleta');
		this.$socket.off('duvida-aprovada');
		this.$sweet.close()
	},
	mounted() {
		let vm = this;
		let auth = vm.$cookies.get('auth');
		let params = this.$route.params;

		vm.duvida = params.duvida;

		// Quando o atendente abre a solicitação de atendimento do cliente
		vm.$socket.on('abrir-chat', () => {
			parent.$.fancybox.close();
			clearInterval(duvidaTimer);
			vm.$socket.emit('start-chat');
			vm.$router.push('/conversa/'+ auth.atendimentoId).catch(err => {});
		});

		vm.$socket.on('atualiza-posicao-real', () => {

			vm.$socket.emit('cliente-busca-posicao', auth.atendimentoId, num => {

				if (vm.numeroFilaReal === 0)
					vm.numeroFilaReal = num;
				else
					vm.numeroFilaReal = vm.numeroFilaReal > num ? num : vm.numeroFilaReal;

				vm.$socket.emit('cliente-atualiza-posicao', {
					posicaoCliente: vm.numeroFilaReal,
					atendimentoId: auth.atendimentoId
				});

			});

		});

		vm.$socket.on('atualiza-espera', function () {
			vm.$socket.emit('cliente-verifica-fila', auth.atendimentoId, num => {

				if (vm.numeroFila === 0) {
					vm.numeroFila = num;
				} else {
					vm.numeroFila = vm.numeroFila > num ? num : vm.numeroFila;
				}

				vm.$socket.emit('cliente-atualiza-posicao', {
					posicaoCliente: vm.numeroFila,
					atendimentoId: auth.atendimentoId
				});
			});
		});

		vm.$socket.on('atualiza-posicao', () => {
			vm.$socket.emit('cliente-atualiza-posicao', {
				posicaoCliente: vm.numeroFilaReal,
				atendimentoId: auth.atendimentoId
			});
		});

		const web_protocol = APP_BACKEND_URL === 'localhost:3001' ? 'http' : 'https';
		fetch(`${web_protocol}://${APP_BACKEND_URL}/informativos-consulta?pessoa_id=${auth.dados.pessoaId}`)
			.then(response => {
				response.json()
					.then(data => {
						if (data.length) {
							vm.temInformativo = true;

							let btVerTodos  = $('.bt-ver-todos-info');

							btVerTodos.before(
								'<a href="javascript:;" class="bt bt-prox-info" '+
								'onclick="troca_video_info('+auth.dados.pessoaId+',1,'+(data.length-1)+')">'+
								'Anterior</a>'
							);

							troca_video_info(auth.dados.pessoaId, 0, (data.length-1));
						}
					})
			})

		/** Pelo que eu entendi nas pesquisas que fiz, isso detecta os history do js que
		 basicamente também é o que acontece quando usa os botões de navegação do browser
		 https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate */
		window.onpopstate = function (e) {
			vm.$socket.emit('disconnect-fake');
		};

		$('.box-informativos').fancybox({
			type: 'iframe',
			width: 840,
			maxWidth: 840,
			height: 580,
			maxHeight: 580,
			autoSize: false,
			baseClass: 'fancybox-informativos',
		});

		$('[data-fancybox="avisos"]').fancybox({
			hash : false,
			afterClose: function () {
				clearInterval(vm.timerAviso);
				vm.avisoAtual = 0;
				vm.moveAnteriorAviso();
			}
		});

		vm.buscaAvisos();

		vm.$socket.on('atualiza-avisos', function () {
			$('.accordion dt').remove();
			$('.accordion dd').remove();
			vm.buscaAvisos();
		});

		vm.$socket.on('atendimento-derrubado', function () {
			let msgAviso = `Caro usuário, tivemos um problema técnico no nosso
            atendimento, solicitamos que entre novamente no atendimento
            on-line para que possamos lhe atender prontamente.<br />
            Desculpe pelo transtorno.<br />Esta janela fechará automaticamente
            ao clicar no botão OK
            `;

			vm.$sweet.alert('Atenção', msgAviso, 'warning', 'OK', true, () => {
				window.close();
			});
		});

		vm.$socket.on('alerta-duvida-incompleta', msgObs => {
			parent.$.fancybox.close();

			let msgAviso = '';

			if (typeof msgObs != 'undefined' && msgObs) {
				vm.obsAtendente = String(entities.decode(msgObs)).replace(new RegExp('\r?\n','g'), '<br />');

				msgAviso+= `<b>Observação da equipe de atendimento: </b><br />
                    <span style="color: red; font-weight: bold;">
                        ${vm.obsAtendente}
                    </span><br /><br />
                `;
			}

			msgAviso += `Olá ${vm.nomeUsuario}! Observamos que as informações
                digitadas por você no campo da descrição da dúvida
                <span style="color: red; font-weight: bold;">
                    SÃO INSUFICIENTES
                </span>
                para que possamos iniciar o suporte de forma mais ágil.
                Procure detalhar um pouco mais a sua situação. Caso você
                responder em menos de 15 minutos, sua posição na fila de
                atendimento permanecerá a mesma<br /><br />
            `;

			aviso_geral(
				`O texto informado no campo Sua dúvida está INSUFICIENTE. Procure` +
				` informar mais detalhes para que possamos lhe atender melhor.`
			);

			vm.$sweet.alert('Atenção', msgAviso, 'warning', 'OK');
		});

		// Avisar a cada 5 min
		let duvidaTimer = setInterval(function () {
			aviso_geral(
				`Use o tempo que você está aguardando na fila para formular` +
				` melhor a sua dúvida e agilizar o seu atendimento.`
			);
		}, 300000); // 300000 ms - 5 min

		vm.$socket.on('duvida-aprovada', function () {
			clearInterval(duvidaTimer);
		});
	}

}

</script>

<style>
.quebra-linha {word-wrap: break-word;white-space: -moz-pre-wrap;white-space: pre-wrap;}

.fancybox-informativos .fancybox-content {width: 840px !important; height: 580px !important;}
</style>
