let Swal = require('sweetalert');

let Sweet = function() {
};

Sweet.close = function () {
    swal.close();
}

Sweet.alert = function( pr_title,
                        pr_text = '',
                        pr_type = 'success',
                        pr_confirm_button_text = 'OK',
                        pr_confirm_button_show = true,
                        pr_confirm_function    = function() {}
){

    var texto = document.createElement('p');
    texto.innerHTML = pr_text;

    swal({
        title   : pr_title,
        content : texto,
        icon    : pr_type,
        button  : {
            text    : pr_confirm_button_text,
            visible : pr_confirm_button_show
        },
        closeOnClickOutside : false,
        closeOnEsc          : false,
        closeModal          : true,
    }).then(() => {
        pr_confirm_function();
    });
}

Sweet.confirm = function( pr_label,
                          pr_title = 'Você tem certeza?',
                          pr_function,
                          pr_cancel = function () {},
                          pr_type = 'warning',
                          pr_confirm_button_text = 'OK',
                          pr_cancel_button_text = 'Cancelar',
                          pr_danger_mode = false
){

    var texto = document.createElement('p');
    texto.innerHTML = pr_label;

    swal({
        title   : pr_title,
        content : texto,
        icon    : pr_type,
        buttons : {
            confirm : pr_confirm_button_text,
            cancel  : pr_cancel_button_text
        },
        closeOnClickOutside : false,
        closeOnEsc          : false,
        closeModal          : true,
        dangerMode          : pr_danger_mode,
    })
    .then((value) => {
        if( value === true ){
            pr_function();
        } else {
            pr_cancel();
        }
    });
}

Sweet.confirm_duvida = function(mensagem, fn_confirm, fn_aprovar, fn_cancel = function() {}) {

    var texto = document.createElement('p');
    texto.innerHTML = mensagem;

    swal({
        title   : 'Atenção',
        content : texto,
        icon    : 'warning',
        buttons : {
            confirm : 'Enviar',
            aprovar : 'Aprovar dúvida',
            cancel  : true,
        },
        closeOnClickOutside : true,
        closeOnEsc          : true,
        closeModal          : true,
    })
    .then((value) => {
        switch(value) {
            case true: fn_confirm(); break;
            case 'aprovar': fn_aprovar(); break;
            default: fn_cancel(); break;
        }
    });
}

Sweet.html = function(html, botoes) {

    var _html = document.createElement('p');
    _html.innerHTML = html;

    var buttons = {
        confirm : false,
        cancel: false
    };
    var values = [];

    for (bNome in botoes) {
        var b = botoes[bNome];
        buttons[bNome] = {text: b.text, value: bNome}
        values.push(b.value);
    }

    swal({
        title: 'Atenção',
        content: _html,
        icon: 'warning',
        buttons: buttons,
        closeOnClickOutside: true,
        closeOnEsc: true,
        closeModal: false,
    })
    .then(value => {
        if (typeof botoes[value] !== 'undefined') {
            if (typeof botoes[value].funcao === 'function') {
                botoes[value].funcao();
            }
            return false;
        }
    });
}

module.exports = Sweet;