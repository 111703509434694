<template>

    <div id="atendimento">

        <div class="janelas">
            <div class="flex" ref="janelas" id="box-janelas"></div>
        </div>

        <box-avisos
            :temAvisos="temAvisos"
        ></box-avisos>

        <box-totais
            :consultaTotaisChat="consultaTotaisChat"
            :canal="canal"
            :revendaIdAtend="revendaIdAtend"
            ref="totais"
        ></box-totais>

        <atendentes-disponiveis
            :consultaTotaisChat="consultaTotaisChat"
            ref="atendentesdisponiveis"
        ></atendentes-disponiveis>

        <h1 class="h1">
            Clientes na fila de espera:
            {{ !aguardandoAtendimento ? 0 : aguardandoAtendimento.length }}
            <span class="float-right" v-if="consultaAtendimento" style="font-size: .7rem">
                <a href="#" class="bt" @click="consultaAtendimentos">
                    Consulta de atendimentos
                </a>
            </span>
            <span class="float-right" v-if="atualizaAvisos" style="font-size: .7rem">
                <a href="#" class="bt" @click="atualizaAvisosGeral">
                    Atualiza avisos
                </a>
            </span>
            <span class="float-right" v-if="temAvisos" style="font-size: .7rem">
                <a href="javascript:;" class="bt bt-avisos" data-fancybox="avisos" data-src="#box-avisos">
                    Avisos
                </a>
            </span>
            <span class="float-right" v-if="consultaAtendimento" style="font-size: .7rem">
                <a href="#" class="bt" @click="consultaAtendentes">
                    Consulta log atendentes
                </a>
            </span>
            <span class="float-right" v-if="consultaTotaisChat" style="font-size: .7rem">
                <a href="javascript:;" class="bt" data-fancybox="atendentes-dispo" data-src="#atendentes-dispo"
                    @click="buscaAtendentes"
                >
                    Atendentes disponíveis
                </a>
            </span>
            <span class="float-right" v-if="consultaTotaisChat" style="font-size: .7rem">
                <a href="javascript:;" class="bt" data-fancybox="totais" data-src="#box-totais"
                    @click="carregaTotais">
                    Totais
                </a>
            </span>
        </h1>

        <table class="parvus-table-base grid condensed vertical-separator">
            <thead>
                <tr>
                    <th class="align-center parvus-table-coluna-ordena">S</th>
                    <th class="align-left parvus-table-coluna-ordena">CLIENTE</th>
                    <th class="align-left parvus-table-coluna-ordena">REF</th>
                    <th class="align-left parvus-table-coluna-ordena">IMPLANTAÇÃO</th>
                    <th class="align-left parvus-table-coluna-ordena">TIPO</th>
                    <th class="align-left parvus-table-coluna-ordena">USUÁRIO</th>
                    <th class="align-left parvus-table-coluna-ordena">SISTEMA</th>
                    <th class="align-left parvus-table-coluna-ordena">REVENDA DE ATENDIMENTO</th>
                    <th class="align-left parvus-table-coluna-ordena">REVENDA</th>
                    <th class="align-center parvus-table-coluna-ordena">ENTRADA</th>
                    <th class="align-center parvus-table-coluna-ordena">TEMPO</th>
                    <th class="align-center parvus-table-coluna-ordena" v-if="removeAtendimento || voltaAtendimento">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(cliente, index) in aguardandoAtendimento"
                    :key="cliente.atendimento_id"
                    :ref="cliente.atendimento_id"
                    :id="'fila'+cliente.atendimento_id"
                    style="cursor: pointer;"
                    :class="[ 'fila_espera', { 'kaizen-pisca': cliente.classificacao == codigoClassificacao('KAIZEN') || cliente.classificacao == codigoClassificacao('ENTERPRISE') && cliente.atendido_id < 80000}]"
                    :title="'<p style=\'text-align: left\'><b>Dúvida:</b> '+ (visualizaDuvida == 1 ? cliente.duvida : 'Você não possui permissão para ver as dúvidas do cliente.') +
                            (visualizaDuvida == 1 ? '<br/><b>Procedimento:</b> '+ (cliente.procedimento.length > 1 && visualizaDuvida == 1 ? cliente.procedimento:'') : '') +'</p>'"
                    :oldtitle="'<p style=\'text-align: left\'><b>Dúvida:</b> '+ (visualizaDuvida == 1 ? cliente.duvida : 'Você não possui permissão para ver as dúvidas do cliente.') +
                            (visualizaDuvida == 1 ? '<br/><b>Procedimento:</b> '+ (cliente.procedimento.length > 1 && visualizaDuvida == 1 ? cliente.procedimento:'') : '') +'</p>'"
                    :data-linha-sistema="cliente.linha_sistema"
                >
                    <td @click="atenderCliente(cliente, index)" align="center">{{ cliente.situacao }}</td>
                    <td @click="atenderCliente(cliente, index)" align="left" class="fila_usuario">{{ cliente.nome_exibicao }}</td>
                    <td @click="atenderCliente(cliente, index)" align="center">{{ cliente.referencial ? 'S' : 'N' }}</td>
                    <td @click="atenderCliente(cliente, index)" align="left" class="fila_usuario">{{ tipoImplantacao(cliente.implantacao) }}</td>
                    <td @click="atenderCliente(cliente, index)" align="left" style="white-space: nowrap">
                        {{
                            nomeClassificacao(
                                cliente.atendido_id,
                                cliente.classificacao,
                                cliente.brasao,
                                cliente.sistema_id,
                                cliente.linha_sistema
                            )
                        }}
                    </td>
                    <td @click="atenderCliente(cliente, index)" align="left" class="fila_usuario">{{ cliente.contato }}</td>
                    <td @click="atenderCliente(cliente, index)" align="left">
                        {{ cliente.sistema_nome }}
                    </td>
                    <td @click="atenderCliente(cliente, index)" align="left">{{ cliente.revenda_chat_nome }}</td>
                    <td @click="atenderCliente(cliente, index)" align="left">{{ cliente.revenda_pai_nome }}</td>
                    <td @click="atenderCliente(cliente, index)" align="center" class="datahora" :data-datetime="cliente.data_entrada">
                        {{ converteHora(cliente.data_entrada) }}

                    </td>
                    <td @click="atenderCliente(cliente, index)" align="center" >
	                    <div class="tempo">00:00</div>
	                    <div v-if="cliente.retorno_em_quinze_minutos" style="color: red;">
		                    <b>Retorno 15 min</b>
	                    </div>
                    </td>
                    <td v-if="removeAtendimento || voltaAtendimento" aling="center">
                        <i v-if="removeAtendimento" class="icon icon-excluir" style="color: #d00" @click="removeClienteTravado(cliente.atendimento_id)"></i>
                        <i v-if="voltaAtendimento && !cliente.duvida_aprova" class="icon icon-backup" style="color: #00d" @click="moveClienteForm(cliente)"></i>
                    </td>
                </tr>
                <tr v-if="!aguardandoAtendimento || aguardandoAtendimento.length === 0">
                    <td colspan="12" class="align-center">
                        Nenhum cliente em espera.
                    </td>
                </tr>
            </tbody>
        </table>

        <br class="clear"/>

        <h1 class="h1">
            Clientes em atendimento:
            {{ !emAtendimento ? 0 : emAtendimento.length }}
        </h1>

        <table class="parvus-table-base grid condensed vertical-separator">
            <thead>
                <tr>
                    <th class="align-center parvus-table-coluna-ordena">S</th>
                    <th class="align-left parvus-table-coluna-ordena">CLIENTE</th>
                    <th class="align-left parvus-table-coluna-ordena">REF</th>
                    <th class="align-left parvus-table-coluna-ordena">IMPLANTAÇÃO</th>
                    <th class="align-left parvus-table-coluna-ordena">TIPO</th>
                    <th class="align-left parvus-table-coluna-ordena">USUÁRIO</th>
                    <th class="align-left parvus-table-coluna-ordena">SISTEMA</th>
                    <th class="align-left parvus-table-coluna-ordena">REVENDA DE ATENDIMENTO</th>
                    <th class="align-left parvus-table-coluna-ordena">REVENDA</th>
                    <th class="align-center parvus-table-coluna-ordena">INÍCIO</th>
                    <th class="align-center parvus-table-coluna-ordena">TEMPO</th>
                    <th class="align-left parvus-table-coluna-ordena">ATENDENTE</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="cliente in emAtendimento"
                    :key="cliente.atendimento_id"
                    :id="'fila'+cliente.atendimento_id"
                    @click="verConversa || pessoaId == cliente.atendente_id ? visualizarConversa(cliente) : null"
                    :class="[ 'fila_atendimento', { 'pointer': verConversa || pessoaId == cliente.atendente_id }]"
                >
                    <td align="center">{{ cliente.situacao }}</td>
                    <td align="left" class="fila_usuario">{{ cliente.nome_exibicao }}</td>
                    <td align="center">{{ cliente.referencial ? 'S' : 'N' }}</td>
                    <td align="left" class="fila_usuario">{{ tipoImplantacao(cliente.implantacao) }}</td>
                    <td align="left" style="white-space: nowrap">
                        {{
                            nomeClassificacao(
                                cliente.atendido_id,
                                cliente.classificacao,
                                cliente.brasao,
                                cliente.sistema_id,
                                cliente.linha_sistema
                            )
                        }}
                    </td>
                    <td align="left" class="fila_usuario">{{ cliente.contato }}</td>
                    <td align="left">
                        {{ cliente.sistema_nome }}
                    </td>
                    <td align="left">{{ cliente.revenda_chat_nome }}</td>
                    <td align="left">{{ cliente.revenda_pai_nome }}</td>
                    <td align="center" class="datahora" :data-datetime="cliente.data_atendido">
                        {{ converteHora(cliente.data_atendido) }}
                    </td>
                    <td align="center" class="tempo">00:00</td>
                    <td align="left">{{ cliente.atendente_nome }}</td>
                </tr>
                <tr v-if="!emAtendimento || emAtendimento.length == 0">
                    <td colspan="12" class="align-center">
                        Nenhum cliente em atendimento.
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script>

import BoxAvisos from './atendimento/BoxAvisos';
import BoxTotais from './atendimento/BoxTotais';
import AtendentesDisponiveis from './atendimento/AtendentesDisponiveis';

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();
const blobutil = require('blob-util');
const paste_img = require('../models/PasteImage.js');

let _ = require('lodash');

export default {
    name: 'atendimento',
    data() {
        let auth = this.$cookies.get('auth');

        return {
            verConversa: auth.dados.visualizaConversaAberta,
            pessoaId: auth.dados.pessoaId,
            aguardandoAtendimento: [],
            emAtendimento: [],
            consultaAtendimento: auth.dados.consultaAtendimento,
            removeAtendimento: auth.dados.removeFilaEsperaChat,
            atualizaAvisos: auth.dados.atualizaAvisos,
            voltaAtendimento: auth.dados.voltaAtendimentoCliente,
            temAvisos: false,
            consultaTotaisChat: auth.dados.consultaTotaisChat,
            grupoUsuario: auth.dados.grupoUsuariosId,
            sistemasAtendimento: auth.config.sistemas,
            canal: auth.config.canal,
            revendaIdAtend: auth.dados.representanteId,
            sistemas: [],
            revendas: [],
            permissoes: [],
            visualizaDuvida: auth.dados.visualizaDuvida,
        }
    },
    components: {
        BoxAvisos,
        BoxTotais,
        AtendentesDisponiveis,
    },
    methods: {
        converteHora: function (val) {
            let data = new Date(val);

            let vlr_options  = {
                'lang': 'pt-BR',
                'options': {
                    hour   : '2-digit',
                    minute : '2-digit'
                }
            };
            return data.toLocaleTimeString(vlr_options.lang, vlr_options.options);
        },
        atenderCliente: function (chatRow, index) {
            let vm = this;
            let auth = vm.$cookies.get('auth');

            if (!auth.dados.visualizaTodaConversaAberta && index > 0) {
                vm.$sweet.alert('Atenção', 'Você deve atender sempre o primeiro da fila', 'warning');
                return;
            }

            vm.$socket.emit('atendente-atender', {chatRow, auth: auth}, err => {
                if (err) {
                    vm.$sweet.alert('Atenção', err, 'error');
                    return;
                }

                let nome = chatRow.atendido_nome;
                // @REMIND: janela_ativa é um global que está no public/js/scripts.js
                janela_ativa = chatRow.atendimento_id;

                if ((chatRow.usuario_nome !== chatRow.atendido_nome) && chatRow.usuario_nome) {
                    nome = `${chatRow.atendido_nome} - ${chatRow.usuario_nome}`;
                }

                nome += ` - ${vm.nomeClassificacao(
                    chatRow.atendido_id,
                    chatRow.classificacao,
                    chatRow.brasao,
                    chatRow.sistema_id,
                    chatRow.linha_sistema
                )}`;

                let janelas = new vm.$janelas({
                    propsData: {
                        atendimentoId: chatRow.atendimento_id,
                        nomeAtendido: nome,
                        naoFinaliza  : false,
                        temSciCloud : chatRow.tem_sci_cloud,
                    }
                });

                // Adiciona o usuário na fila de atendido
                chatRow.data_atendido  = new Date();
                chatRow.atendente_nome = `${auth.dados.pessoaId} - ${auth.dados.nome}`;

                janelas.$mount();

                vm.$refs.janelas.appendChild(janelas.$el);
                vm.$refs[chatRow.atendimento_id] = janelas;

                $('.janela-chat').unbind('click').click(function () {
                    janela_ativa = $(this).attr('id');
                });
            });
        },
        visualizarConversa: function (chatRow, finaliza = false) {
            let vm = this;
            let auth = vm.$cookies.get('auth');

            if ($(`.janelas #${chatRow.atendimento_id}`).length <= 0) {
                janela_ativa = chatRow.atendimento_id;

                let auth = this.$cookies.get('auth');

                this.$socket.emit(
                    'atendente-visualiza',
                    {atendimentoId: chatRow.atendimento_id, auth},
                    err => {
                        if (err) vm.$sweet.alert('Atenção', err, 'error')
                    }
                );

                let nome = chatRow.atendido_nome;

                if (chatRow.usuario_nome && chatRow.usuario_nome != chatRow.atendido_nome) {
                    nome = `${chatRow.atendido_nome} - ${chatRow.usuario_nome}`;
                }

                nome += ` - ${this.nomeClassificacao(
                    chatRow.atendido_id,
                    chatRow.classificacao,
                    chatRow.brasao,
                    chatRow.sistema_id,
                    chatRow.linha_sistema
                )}`;

                if (!finaliza && chatRow.atendente_id == auth.dados.pessoaId) {
                    finaliza = true;
                }

                let janela = new this.$janelas({
                    propsData: {
                        atendimentoId: chatRow.atendimento_id,
                        nomeAtendido: nome,
                        finaliza,
                        temSciCloud: chatRow.tem_sci_cloud,
                    }
                });

                janela.$mount();

                $('#box-janelas').append(janela.$el);

                this.$refs[chatRow.atendimento_id] = janela;

                $('.janela-chat').unbind('click').click(function () {
                    janela_ativa = $(this).attr('id');
                });
            }
        },
        buscaAguardandoAtendimento: async function () {
            let auth = this.$cookies.get('auth');

            return await new Promise(resolve => {
                this.$socket.emit('atendente-em-espera', auth, res => resolve(res));
            });
        },
        buscaEmAtendimento: async function () {
            let auth = this.$cookies.get('auth');

            return await new Promise(resolve => {
                this.$socket.emit('atendente-em-atendimento', auth, res => resolve(res));
            });
        },
        nomeClassificacao: function (pessoa_id, classificacao, brasao, sistema, unico) {
            if (pessoa_id >= 80000) {
                return 'REVENDA';
            } else {
                return nome_classificacao_brasao(classificacao, brasao, sistema, unico);
            }
        },
        codigoClassificacao: function (nome) {
            return codigo_classificacao(nome);
        },
        consultaAtendimentos: function () {
            this.$router.push('/consulta-atendimentos');
        },
        consultaAtendentes: function () {
            this.$router.push('/consulta-atendentes');
        },
        carregaAtendimentos: async function () {
            let aguardando = await this.buscaAguardandoAtendimento();
            let emAtendimento = await this.buscaEmAtendimento();
            return this.filtraAtendimentos(aguardando, emAtendimento)
        },validaPermissaoAtendente:function(classificacao,permissao,item){

            let sistemasAtendidosTecWeb = ['208','209','213','214','202','203','204','206','207'];

            if(typeof permissao[classificacao] == 'undefined'){
                return false;
            }

            if(permissao[classificacao].includes(item.sistema_id)){
                return true;
            }

            if(
                permissao['NORMAL'].includes(
                vm.trocaLgpSyndkosPeloPrincipal(item.sistema_id))
                && 
                sistemasAtendidosTecWeb.includes(item.sistema_id)){

                return true;
            }
        },
        filtraAtendimentos: function (aguardando, emAtendimento) {
            let vm = this;
            let auth = this.$cookies.get('auth');
           
            
            if (auth.dados && auth.dados.implantacaoSituacao !== null) {
                const permissoesImplantacao = {
                    0: auth.dados.atendeImplantacaoAguardando,
                    1: auth.dados.atendeImplantacaoSemChat,
                    2: auth.dados.atendeImplantado,
                    3: auth.dados.atendeImplantacaoComChat,
                }

                aguardando = aguardando.filter(elem => permissoesImplantacao[elem.implantacao]);
                emAtendimento = emAtendimento.filter(elem => permissoesImplantacao[elem.implantacao]);
            }
            // Se não tem configuração de permissões, não mostra os atendimentos
            if (typeof vm.permissoes !== 'undefined' && vm.permissoes.length <= 0)
                return;

            _.remove(aguardando,  (item)=> {
                // Não precisa filtrar as revendas nas permissões
                if (item.atendido_id >= 80000 || item.sistema_id == 999) return false;

                let classificacao = nome_classificacao(item.classificacao, item.sistema_id, item.linha_sistema);
	            const found_permission = vm.permissoes.some(permissao => {
		            return vm.validaPermissaoAtendente(classificacao,permissao,item);
	            })
                return !found_permission;
            });

            _.remove(emAtendimento,  (item)=> {
                // Não precisa filtrar as revendas nas permissões
                if (item.atendido_id >= 80000 || item.sistema_id == 999) return false;

                let classificacao = nome_classificacao(item.classificacao, item.sistema_id, item.linha_sistema);
                const found_permission = vm.permissoes.some(permissao => {
		            return vm.validaPermissaoAtendente(classificacao,permissao,item);
	            })
                return !found_permission;
            });

            vm.aguardandoAtendimento = aguardando;
            vm.emAtendimento = emAtendimento;

            setTimeout(function () {
                $('.parvus-table-base tr').qtip({
                    position: {
                        my: 'top center',
                        at: 'bottom center',
                    }
                });
            }, 100);

            return {aguardando, emAtendimento};
        },validaPermissaoAtendente:function(classificacao,permissao,item){

            const SYNDKOS_LGPD_ID = [202,208]

            if(SYNDKOS_LGPD_ID.includes(Number(item.sistema_id)) &&
               permissao['NORMAL'].includes(item.sistema_id)
            ){
                return true;
            }
            
            if (!(classificacao in permissao)) {
                return false;
            }
            
            if(permissao[classificacao].includes(item.sistema_id)){
                return true;
            }

        },
        atualizaAvisosGeral: function () {
            this.$socket.emit('atualiza-avisos');
        },
        tipoImplantacao: function (codigo) {
            return tipo_implantacao(codigo);
        },
        removeClienteTravado: function (atendimentoId) {
            let vm = this;

            this.$sweet.confirm('Este atendimento será removido sem histórico', 'Tem certeza disso?', function () {

                vm.$socket.emit('atendente-remove-travado', atendimentoId, function (msg) {
                    if (msg) vm.$sweet.alert('Atenção', msg, 'error');
                });

            });
        },
        moveClienteForm: function (cliente) {
            let vm = this;
            let auth = this.$cookies.get('auth');
            let msg_aviso = 'Ao retornar o cliente para a tela inicial, ele permanecerá na '+
                ' fila de atendimento  e se não responder em 15 minutos, ele poderá ser removido '+
                'automaticamente. Tem certeza de que deseja realizar esse processo? '+
                'Caso necessário, informe no campo abaixo alguma orientação adicional para o cliente'+
                '<br /><b>Observações para o cliente</b>'+
                '<a href="javascript:void(0);" class="link-ajuda" '+
                ' title="Além da mensagem informada no campo abaixo, caso informada, '+
                ' será exibido para o cliente a seguinte mensagem: \r\n'+
                cliente.usuario_nome+', nossa equipe de atendimento avaliou '+
                'que as informações digitadas por você no campo Descreva brevemente '+
                'a situação ou sua dúvida SÃO INSUFICIENTES '+
                'para que possamos analisar a sua situação. Você será redirecionado novamente '+
                'para a tela inicial para detalhar melhor sua dúvida.">'+
                '<span class="icon-ajuda"></span><span class="visuallyhidden">Ajuda</span></a>'+
                '<br /><div class="campo"><textarea name="obs_atendente"></textarea></div><br />';

            this.$sweet.confirm_duvida(msg_aviso, function () {
                let mensagem = '';

                if ($('[name="obs_atendente"]').val() != '') {
                    mensagem = $('[name="obs_atendente"]').val();
                }

                vm.$socket.emit('atendente-duvida-incompleta', {
                    atendimentoId: cliente.atendimento_id,
                    mensagem,
                    auth
                }, err => {
                    if (err) vm.$sweet.alert('Atenção', err, 'warning');
                });
            }, function () {
                vm.$socket.emit('atendente-aprova-duvida', cliente.atendimento_id, err => {
                    if (err) vm.$sweet.alert('Atenção', err, 'warning');
                });
            });
        },
        sistema: function (id) {
            if (id == 999) return 'TI/INFRAESTRUTURA/CLOUD - Visual';
            if (id == 9991) return 'TI/INFRAESTRUTURA/CLOUD - Visual';
            if (id == 9992) return 'TI/INFRAESTRUTURA/CLOUD - Único';

            let s = _.find(this.sistemas, function (v) {
                return v.sistemaId == id;
            });

            if (!s) return id;

            return s.nome;
        },
        revenda: function (id) {
            if (id == '0') return 'Geral';

            let split = id.split(',');

            let r = _.find(this.revendas, function (v) {
                return v.representanteId == split[0];
            });

            if (!r) return split[0];

            return split[0]+' - '+r.nome;
        },
        carregaTotais: function () {
            this.$refs.totais.carregaTotais();
        },
        exibeData: function (date, hora) {
            return exibe_data(date, hora);
        },
        buscaAtendentes: function () {
            let vm = this;
            this.$refs.atendentesdisponiveis.busca();
            vm.$forceUpdate();
        },
        inserePermissao: function (permissoes, linhaSistema, classificacao, sistemaId) {
            if (!permissoes)
                return undefined;

            if (!permissoes[linhaSistema])
                permissoes[linhaSistema] = {};

            if (!permissoes[linhaSistema][classificacao])
                permissoes[linhaSistema][classificacao] = []

            if (!permissoes[linhaSistema][classificacao].includes(sistemaId))
                permissoes[linhaSistema][classificacao].push(sistemaId);

            return permissoes;
        }
    },
    beforeDestroy() {
        this.$socket.off('atendimento-transferido');
        this.$socket.off('atualiza-atendimentos');
        this.$socket.off('alerta-inativo');
        this.$socket.off('alerta-inativo-remove');
        this.$socket.off('atualiza-avisos');
        this.$socket.off('info-sci');

        paste_img.removeAllListeners();
        paste_img.removeListener('pasting-image', evt => undefined);
        paste_img.removeListener('paste-image', evt => undefined);
    },
    mounted() {
        let vm = this;
        let auth = this.$cookies.get('auth');
        let audio = new Audio('/mp3/novo.mp3');
            audio.volume = 0.6;

        vm.buscaAtendentes();

        vm.$socket.emit('atendente-permissoes', vm.pessoaId, data => {
            data.map(data => {
                let linha = 1;
                let classificacao = data.classificacao;

                if (['Único', 'WebUnico', 'Novo Visual'].indexOf(data.linha) >= 0)
                    linha = 2

                if (['syndkos','lgpd'].indexOf(data.linha) >= 0)
                    linha = 3

                if (typeof vm.permissoes[linha] == 'undefined')
                    vm.permissoes[linha] = [];

                if (typeof vm.permissoes[linha][classificacao] == 'undefined')
                    vm.permissoes[linha][classificacao] = [];

                vm.permissoes[linha][classificacao].push(data.sistema_id);
       
                if (data.classificacao == 'ÚNICO') {
                    if (typeof vm.permissoes[linha]['NOVO VISUAL'] == 'undefined') {
                        vm.permissoes[linha]['NOVO VISUAL'] = [];
                    }
                    vm.permissoes[linha]['NOVO VISUAL'].push(data.sistema_id);
                }
            });


            // Adiciona os sistemas INFRA, nas permissões de atendimento
            // @TODO: Isso também está implementado no serverV3/components/Login.ts:156
            if (auth.config.sistemas.includes(9993)) {
                vm.permissoes = this.inserePermissao(vm.permissoes, 1, 'NORMAL', 999);
                vm.permissoes = this.inserePermissao(vm.permissoes, 2, 'KAIZEN', 999);
                vm.permissoes = this.inserePermissao(vm.permissoes, 2, 'ADVANCED', 999);
                vm.permissoes = this.inserePermissao(vm.permissoes, 2, 'DIAMOND', 999);
                vm.permissoes = this.inserePermissao(vm.permissoes, 2, 'ÚNICO', 999);
                vm.permissoes = this.inserePermissao(vm.permissoes, 2, 'NUMBER ONE', 999);
            } else {
                if (auth.config.sistemas.includes(9991))
                    vm.permissoes = this.inserePermissao(vm.permissoes, 1, 'NORMAL', 999);
                if (auth.config.sistemas.includes(9992)) {
                    vm.permissoes = this.inserePermissao(vm.permissoes, 2, 'KAIZEN', 999);
                    vm.permissoes = this.inserePermissao(vm.permissoes, 2, 'ADVANCED', 999);
                    vm.permissoes = this.inserePermissao(vm.permissoes, 2, 'DIAMOND', 999);
                    vm.permissoes = this.inserePermissao(vm.permissoes, 2, 'ÚNICO', 999);
                    vm.permissoes = this.inserePermissao(vm.permissoes, 2, 'NUMBER ONE', 999);
                }
            }

            vm.carregaAtendimentos();
            calcula_tempo_fila();
        });

        /*setInterval(function () {
            if (vm.aguardandoAtendimento.length > 0) {
                audio.play().catch(error => {});
                aviso_entrada('Existem clientes aguardando atendimento');
            }
        }, 200);*/

        this.$socket.on('atendimento-transferido', ({clienteNome, atendenteId, chatRow}) => {
            if (atendenteId === auth.dados.pessoaId) {
                vm.$sweet.alert('Atenção', `Você foi transferido para o atendimento de ${clienteNome}`, 'warning');
                vm.visualizarConversa(chatRow, true);
            }
        });

        this.$socket.on('atualiza-atendimentos', () => vm.carregaAtendimentos());

        //Atualiza a lista de atendimentos a cada 5 seg
        setInterval(() => {
            calcula_tempo_fila();
        }, 10000);

        this.$socket.on('alerta-inativo', ({atendimentoId, tipo, msg}) => {
            if (tipo == 'cliente') {
                $('#fila'+atendimentoId).addClass('alerta cliente');

                if (typeof vm.$refs[atendimentoId] !== 'undefined') {
                    vm.$refs[atendimentoId].inativoCliente = true;
                }
            } else {
                $('#fila'+atendimentoId).addClass('alerta atendente');
            }

            $('#fila'+atendimentoId).attr('title', msg);

            setTimeout(function () {
                $('.parvus-table-base tr').qtip({
                    position: {
                        my: 'top center',
                        at: 'bottom center',
                    }
                });
            }, 100 );
        });

        this.$socket.on('alerta-inativo-remove', ({atendimentoId, tipo}) => {
            if (tipo == 'cliente') {
                $('#fila'+atendimentoId).removeClass('alerta cliente');

                if (typeof vm.$refs[atendimentoId] !== 'undefined') {
                    vm.$refs[atendimentoId].inativoCliente = false;
                }
            } else {
                $('#fila'+atendimentoId).removeClass('alerta atendente');
            }

            $('#fila'+atendimentoId).removeAttr('title');
            $('#fila'+atendimentoId).removeAttr('oldtitle');

            setTimeout(function () {
                $('.parvus-table-base tr').qtip({
                    position: {
                        my: 'top center',
                        at: 'bottom center',
                    }
                });
            }, 100 );
        });

        this.$socket.emit('busca-sistema', null, data => {
            vm.sistemas = data;
        });

        this.$socket.emit('busca-revenda', null, data => {
            vm.revendas = data;
        });

        // PRT SCREEN + CTRL+V
        paste_img.on('pasting-image', function(e) {
            let objJanela = $('#'+janela_ativa);
            let textoInput = objJanela.find('.box-mensagem [name="texto"]');

            textoInput
                .attr('placeholder', 'Enviando imagem, aguarde...')
                .attr('disabled', 'disabled');
        });

        paste_img.on('paste-image', async (input) => {
            let objJanela = $('#'+janela_ativa);
            let textoInput = objJanela.find('.box-mensagem [name="texto"]');

            if (input != null && ['image/png', 'image/jpeg'].indexOf(input.type) >= 0) {
                let blob   = await blobutil.arrayBufferToBlob(input, input.type);
                let base64 = await blobutil.blobToBase64String(blob);

                vm.$socket.emit('conversa-salva-print', {
                        src: base64,
                        type: input.type,
                        atendimentoId: janela_ativa
                    }, data => {
                        textoInput.removeAttr('disabled');
                        textoInput.attr('placeholder', 'Digite sua mensagem ou copie e cole seu print aqui e aperte Enter para enviar...');

                        if (data === false) {
                            vm.$sweet.alert('Atenção', 'A imagem que você está tentando enviar não é válida', 'warning');
                            return;
                        }

                        let msg = '<a href="'+ data +'" data-fancybox style="text-decoration:none">';
                        msg+= '<img src="'+ data +'" style="max-width: 300px;"/>';
                        msg+= '</a>';

                        vm.$socket.emit('conversa-grava', {
                            mensagem: msg,
                            atendimentoId: janela_ativa,
                            auth,
                            isAtendente: true,
                        }, function(err) {
                            vm.$refs[janela_ativa].resetMensagem(err);
                        });
                    }
                );

                return;
            }

            textoInput.removeAttr('disabled');
            textoInput.attr('placeholder', 'Digite sua mensagem ou copie e cole seu print aqui e aperte Enter para enviar...');
            textoInput.focus();
        });
    }
}

let calcula_tempo_fila = () => {

    $('.fila_espera').each(function () {
        let start = new Date($(this).find('.datahora').data('datetime'));
        let end   = new Date();
        let diff  = end.valueOf() - start.valueOf();
        let tempo = ms_to_time(diff);

        //Coloca o alerta na fila de espera se tiver ha mais de 20 min aguardando
        if (diff >= 1200000) $(this).addClass('alerta');

        $(this).find('.tempo').text(tempo);
    });

    $('.fila_atendimento').each(function () {
        let start = new Date($(this).find('.datahora').data('datetime'));
        let end   = new Date();
        let diff  = end.valueOf() - start.valueOf();
        let tempo = ms_to_time(diff);

        $(this).find('.tempo').text(tempo);
    });

    $('.box-totais-tabela.atendentes tr.em_atendimento').each(function () {
        let start = new Date($(this).find('.datahora').data('datetime'));
        let end   = new Date();
        let diff  = end.valueOf() - start.valueOf();
        let tempo = ms_to_time(diff);

        $(this).find('.datahora').text(tempo);
    });
}

</script>

<style>
    .titulo-totais,
    .titulo-atendentes {
        display: inline-block;
        width: 100%;
        background: #961c1c;
        padding: 5px;
        color: white;
        cursor: pointer;
        text-transform: uppercase;
    }
    .flex { display: flex;}
    .flex-item {
        flex: 1 0 33%
    }
</style>
