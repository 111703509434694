<template>

    <div class="b-xs-12" id="avisos-fixo" v-if="temAvisos && !mostraAvisoTodos" style="border: 1px solid #b9b9b9;background: #eaeaea;">
        <div class="aviso-box-hide">
            <div class="aviso-box-scroll">
                <div class="aviso-box-item" v-for="aviso in avisos" :key="aviso.id"
                    :data-timer="timeToMs(aviso.tempoExibe)"
                    :data-id="aviso.id"
                >
                    <h1 class="h1 piscapisca" style="padding: 5px 10px 0 10px;font-size: 1.3rem;">
                        Avisos importantes - {{converteDataAviso(aviso.dataMaior)}} - {{aviso.titulo}}
                    </h1>
                    <p v-html="aviso.texto"></p>
                </div>
            </div>
        </div>
        <div class="aviso-box-nav">
            <a href="javascript:;" v-if="avisos.length > 1" class="bt aviso-anterior" @click="moveAnteriorAviso()" style="display: none">Anterior</a>
            <a href="javascript:;" v-if="avisos.length > 1" class="bt aviso-proximo" @click="moveProximoAviso()">Próximo</a>
            <a href="javascript:;" v-if="avisos.length > 1" class="bt" @click="verTodosAvisos()">Ver todos</a>
        </div>
    </div>

</template>

<script>

export default {
    props: ['temAvisos', 'mostraAvisoTodos', 'avisos'],
    methods: {
        timeToMs: function(v) { return this.$parent.timeToMs(v) },
        converteDataAviso: function (v) { return this.$parent.converteDataAviso(v) },
        moveAnteriorAviso: function () { this.$parent.moveAnteriorAviso() },
        moveProximoAviso: function () { this.$parent.moveProximoAviso() },
        verTodosAvisos: function () { this.$parent.verTodosAvisos() },
        closeFancybox: function () { this.$parent.closeFancybox() },
    }
}

</script>