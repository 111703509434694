<template>

    <div id="suporte-web">
        <div class="lista-suporte-web" v-for="suporte in suportes" :key="suporte.dado.id">
            <h3 class="h3">
                Suporte nº {{ suporte.dado.id }} - Aberto por {{ suporte.dado.atendente }} em {{ suporte.dado.data }}
            </h3>
            <table class="parvus-table-base grid condensed vertical-separator">
                <thead>
                    <tr>
                        <th class="align-left parvus-table-coluna-ordena">SISTEMA</th>
                        <th class="align-left parvus-table-coluna-ordena">PROBLEMA</th>
                        <th class="align-left parvus-table-coluna-ordena">FUNCIONALIDADE</th>
                        <th class="align-left parvus-table-coluna-ordena">MOTIVO</th>
                        <th class="align-center parvus-table-coluna-ordena" width="100px">AÇÕES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ suporte.dado.sistema }}</td>
                        <td>{{ suporte.dado.problema }}</td>
                        <td>{{ suporte.dado.funcionalidade }}</td>
                        <td>{{ suporte.dado.motivo }}</td>
                        <td>
                            <a href="javascript:;" class="bt" @click="vincular( suporte )">VINCULAR</a>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <b>Interações</b>
                            <p v-for="interacao in suporte.interacoes" :key="interacao.id"
                               :class="[ 'interacoes', {
                                        'reticencias': ( ( interacao.match( /<br/g ) || [] ).length ) > 2
                                    }]"
                            >
                                <i class="icon icon-plus"
                                   v-show="((interacao.match(/<br/g) || []).length) > 2"></i>
                                <span v-html="interacao" class="texto"></span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
        </div>

    </div>

</template>

<script>

export default {
    name: "SuporteWebView",
    props: ['pessoaId', 'atendimentoId', 'sistemaId' ],

    data() {
        return {
            suportes: {},
        }
    },
    methods: {
        vincular: function(data) {
            let obj = $('#'+this.atendimentoId);
            let dado = data.dado;
            // Objetos input
            let objProblema = obj.find('[name="problema"');
            let objFuncionalidade = obj.find('[name="funcionalidade"');
            let objMotivo = obj.find('[name="motivo"');
            let objTelefone = obj.find('[name="telefone_contato"');
            let objEmail = obj.find('[name="email_contato"');
            // Remove o selected dos objetos atuais caso tenham
            objProblema.find(':selected').removeProp('selected');
            objFuncionalidade.find(':selected').removeProp('selected');
            objMotivo.find(':selected').removeProp('selected');
            // Marca as novas opções
            objProblema.find('[value="'+ dado.problema_id +'"]').prop('selected', true);
            objFuncionalidade.find('[value="'+dado.funcionalidade_id+'"]').prop('selected', true);
            objMotivo.find('[value="'+dado.motivo_id+'"]').prop('selected', true);
            objTelefone.val(dado.contato_telefone);
            objEmail.val(dado.contato_email);
            // Dispara o Change nos campos para atualizar o select2
            objProblema.trigger('change');
            objFuncionalidade.trigger('change');
            objMotivo.trigger('change');
            // Insere o ID do suporte para inserir como uma nova interação
            obj.find('[name="suporte_id"]').val(dado.id);
            // Fecha o view dos suportes
            close_view_suporte();
        }
    },
    beforeMount: async function()
    {
        let vm = this;
        let auth = vm.$cookies.get('auth');
        let suportes = [];
        // Faz a consulta dos suportes com Promise para dar tempo de preencher a variável no final
        await new Promise(resolve => {
            vm.$socket.emit('busca-suporte-aberto-completo', {
                pessoaId: vm.pessoaId,
                revendaId: auth.dados.representanteOriginalId,
                sistemaId: vm.sistemaId
            }, function(res) {
                suportes = res;
                resolve(true);
            });
        });

        let retorno = {};
        // Monta a variável para exibir no html
        // com Promise para dar tempo de preencher a variável no final
        let i = 1;
        _.forEach(suportes, data => {
            if (typeof retorno[data.id] == 'undefined') {
                retorno[data.id] = {
                    id: data.id,
                    dado: data,
                    interacoes: []
                };

                retorno[data.id].interacoes.push(data.interacao.replace( /\r\n/g, '<br />' ));
            } else {
                retorno[data.id].interacoes.push(data.interacao.replace( /\r\n/g, '<br />' ));
            }

            i++;
        });
        // Reordena as chaves por id em ordem decrescente
        vm.suportes = _.orderBy(retorno, ['id'], ['desc']);
    },
    mounted() {
        // As vezes demora um pouco pro Vue montar o HTML então tem que setar as ações nos objetos html depois
        // de um certo tempo para terem efeito.
        setTimeout(() => {
            $('#suporte-web .interacoes .icon').on('click', function() {
                $(this).toggleClass('icon-plus');
                $(this).toggleClass('icon-minus');
                $(this).parent('.interacoes').toggleClass('reticencias');
            });
        }, 1000);
    }
}

</script>

<style scoped>
</style>
