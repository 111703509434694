<template>

    <div id="box-avisos" v-if="temAvisos" style="display: none; width: 93vw; padding: 0px; border: 1px solid #b9b9b9;background: #eaeaea;">
        <div class="aviso-box-hide">
            <div class="aviso-box-scroll">
                <div class="aviso-box-item" v-for="aviso in avisos" :key="aviso.id"
                    :data-timer="timeToMs(aviso.tempoExibe)"
                    :data-id="aviso.id"
                >
                    <h1 class="h1 piscapisca" style="padding: 5px 10px 0 10px;font-size: 1.3rem;">
                        Avisos importantes - {{converteDataAviso(aviso.dataMaior)}} - {{aviso.titulo}}
                    </h1>
                    <p v-html="modificarLinks(aviso.texto)" @click="abrirLink"></p>
                </div>
            </div>
        </div>
        <div class="aviso-box-nav">
            <a href="javascript:;" v-if="avisos.length > 1" class="bt aviso-anterior" @click="moveAnteriorAviso()" style="display: none">Anterior</a>
            <a href="javascript:;" v-if="avisos.length > 1" class="bt aviso-proximo" @click="moveProximoAviso()">Próximo</a>
            <a href="javascript:;" v-if="avisos.length > 1" class="bt" @click="verTodosAvisos()">Ver todos</a>
            <a href="javascript:;" class="bt" @click="closeFancybox()">Fechar</a>
        </div>
    </div>

</template>

<script>

export default {
    props: ['temAvisos', 'avisos'],
    methods: {
        timeToMs: function(v) { return this.$parent.timeToMs(v) },
        converteDataAviso: function (v) { return this.$parent.converteDataAviso(v) },
        moveAnteriorAviso: function () { this.$parent.moveAnteriorAviso() },
        moveProximoAviso: function () { this.$parent.moveProximoAviso() },
        verTodosAvisos: function () { this.$parent.verTodosAvisos() },
        closeFancybox: function () { this.$parent.closeFancybox() },
        modificarLinks(textoHtml) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(textoHtml, 'text/html');
            const links = doc.querySelectorAll('a');
      
        links.forEach(link => {
            const url = link.getAttribute('href');
            const textContent = link.textContent;
            const div = document.createElement('div');

            div.textContent = textContent;
            div.setAttribute('style', 'color:#2388da; cursor:pointer; display:inline-flex;');
            div.setAttribute('data-url', url);
            link.replaceWith(div);
      });
      
      return doc.body.innerHTML;
    },

    abrirLink(event) {
      const url = event.target.getAttribute('data-url');
      if (url) {
        window.open(url, '_blank');
      }
    },

    }
}

</script>