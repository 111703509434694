<template>
    <div id="form-cliente">

        <div class="container" style="top: -10px; position: relative;">

            <label class="hidden"> {{ exibeSistemaCliente() }} </label>

            <div v-if="!chatOnline" class="b-xs-12">

                <div class="alerta-almoco">

                    O horário de Atendimento Online é de segunda à sexta-feira entre
                    <b>{{ horaInicial }}</b>
                    <span v-if="horaAlmocoInicial != 'null' && horaAlmocoFinal != 'null'">
                        até <b>{{ horaAlmocoInicial }}</b>
                        e <b>{{ horaAlmocoFinal }}</b> até
                    </span>
                    <span v-else> e </span>
                    <b>{{ horaFinal }}</b>
                    (horário de Brasília)

                </div>

                <br class="clear" />

                <div v-if="!ehAtendente">

                    <p>Para enviar sua dúvida a SCI, utilize a opção Suporte na Área de Cliente.</p>
                    <div class="b-xs-c align-center">
                        <div class="botao">
                            <a href="https://areadocliente.sci10.com.br/modulo/suporte/form.php" class="bt">
                                Clique aqui para abrir um novo suporte
                            </a>
                        </div>
                    </div>

                </div>

            </div>

            <div v-else class="b-xs-12">

                <div class="b-xs-12"> <h4 class="title">Informe seu nome e selecione o sistema que você deseja suporte:</h4> </div>

                <br class="clear" />

                <form onsubmit="return false;">

                    <div class="b-xs-12">
                        <div v-if="ehAdicional" class="campo obrigatorio">
                            <label for="nome">Seu nome completo</label>
                            <input type="text" name="nome" id="nome" readonly class="fake_disabled" :value="auth.adicional.nome"/>
                            <input type="hidden" id="eh_adicional" name="eh_adicional" value="1"/>
                            <input type="hidden" name="usuario_adicional_id" :value="auth.adicional.id" />
                            <input type="hidden" id="login" name="usuario_adicional_login" :value="auth.adicional.login" />
                        </div>
                        <div v-else-if="ehAtendente" class="campo obrigatorio">
                            <label for="nome">Seu nome completo</label>
                            <input type="text" name="nome" id="nome" readonly class="fake_disabled" :value="auth.dados.nomeCompleto"/>
                            <input type="hidden" id="login" :value="auth.dados.login"/>
                        </div>
                        <div v-else class="campo obrigatorio">
                            <label for="nome">Seu nome completo</label>
                            <input type="hidden" v-model="nomeAtendimento" name="nome_atendimento"/>
                            <input type="hidden" v-model="usuarioAdicionalId" name="usuario_adicional_id"/>
                            <select2 name="nome" id="nome" @input="carregaSistemas">
                                <option value="">-- Selecione --</option>
                                <option v-for="usuario in usuarios"
                                    :value="usuario.login"
                                    :key="usuario.id"
                                    :data-id="usuario.id"
                                    :selected="usuarioAdicionalLogin == usuario.login"
                                > {{ usuario.nome }} </option>
                            </select2>
                        </div>
                    </div>

                    <div class="b-xs-6">
                        <div class="campo obrigatorio">
                            <label for="sistema">Sistema desejado para atendimento</label>
                            <select2 id="sistema" name="sistema" :disabled="sistemaDisabled" @change="checkReturn" @input="verificaLinha">
                                <option value="">-- Selecione --</option>
                                <option v-for="sistema in sistemas" :value="sistema.value"
                                        :key="sistema.sistemaId"
                                        :data-original="sistema.sistemaId"
                                        :data-name="sistema.label"
                                        :data-atendendo="sistema.atendendoNome ? sistema.atendendoNome : null"
                                        :data-atendimento="sistema.atendimentoId ? sistema.atendimentoId : null"
                                        :data-atendido="sistema.onChat ? 1 : 0"
                                        :data-disconnected="sistema.disconnected ? 1 : 0"
                                        :disabled="sistema.disabled"
                                        :selected="sistemaAtendimentoOriginal == sistema.sistemaId">
                                    {{ sistema.label }} {{ sistema.atendendoNome ? ` (${sistema.atendendoNome} em atendimento)`: '' }}
                                </option>
                            </select2>
                        </div>
                    </div>

                    <div class="b-xs-6" v-show="exibeLinhaEsocial">
                        <div class="campo obrigatorio">
                            <label for="linha_esocial">Sua dúvida é de folha ou eSocial?</label>
                            <select2 id="linha_esocial" name="linha_esocial">
                                <option value="0" :selected="!valueLinhaEsocial">-- Selecione --</option>
                                <option value="1" :selected="valueLinhaEsocial == 1">Folha</option>
                                <option value="3" :selected="valueLinhaEsocial == 3">eSocial</option>
                            </select2>
                        </div>
                    </div>

                    <div class="b-xs-6" v-show="exibeLinhaSistema">
                        <div class="campo obrigatorio">
                            <label for="linha_sistema">Selecione a linha de sistemas que deseja atendimento</label>
                            <select2 id="linha_sistema" name="linha_sistema" @input="trocaLinha">
                                <option value="0">-- Selecione --</option>
                                <option value="1" :selected="valueLinhaSistema == 1">Linha VISUAL</option>
                                <option value="2" :selected="valueLinhaSistema == 2">ambiente contábil ÚNICO</option>
                                <option value="3" :selected="valueLinhaSistema == 3">Tecnologias Web</option>
                            </select2>
                        </div>
                    </div>

                    <br class="clear" />

                    <div class="b-xs-2">
                        <div id="versaoObrigatorio" :class="['campo', {'obrigatorio': obrigaVersao}]">
                            <label for="versao">
                                Versão do sistema
                                <a href="javascript:void(0);" class="versao-aviso link-ajuda"
                                   v-show="obrigaVersao"
                                   title="">
                                    <span class="icon-ajuda"></span>
                                    <span class="visuallyhidden">Ajuda</span>
                                </a>
                            </label>
                            <input type="hidden" id="versao_especial" name="versao_especial" value="0"/>
                            <input type="text" class="" id="versao" name="versao" maxlength="20"
                                   @blur="verificaVersao" disabled/>
                        </div>
                    </div>

                    <div v-if="ehAtendente" class="b-xs-6">
                        <div class="campo">
                            <label for="nome_cliente">
                                Cliente que gerou este atendimento <i>(Digite o código ou nome para filtrar)</i>
                            </label>
                            <select2 id="nome_cliente" name="nome_cliente">
                                <option value="">-- Selecione --</option>
                                <option v-for="cliente in clientes" :value="cliente.nome" :key="cliente.pessoaId">
                                    {{ cliente.nome }}
                                </option>
                            </select2>
                        </div>
                    </div>
                    <div v-if="ehAtendente" class="b-xs-4">
                        <div class="campo">
                            <label for="nome_funcionario">Nome do funcionário do cliente</label>
                            <input type="text" class="" id="nome_funcionario" name="nome_funcionario"/>
                        </div>
                    </div>

                    <br class="clear" />

                    <div class="b-xs-6">
                        <div class="campo obrigatorio">
                            <label for="duvida">
                                Descreva de forma bem elaborada e detalhada a situação ou sua dúvida
                                <a href="javascript:void(0);" class="link-ajuda"
                                    title="O preenchimento deste campo é fundamental, pois ele é avaliado pela nossa equipe no pré-atendimento, se for detectado falta de informação, o atendimento pode ser devolvido para preenchimento mais explicativo.">
                                    <span class="icon-ajuda"></span>
                                    <span class="visuallyhidden">Ajuda</span>
                                </a>
                            </label>
                            <textarea id="duvida" name="duvida" maxlength="2000" style="height: 90px !important"></textarea>
                            <sup>* máximo 2000 caracteres</sup>
                        </div>
                    </div>

                    <div class="b-xs-6">
                        <div :class="['campo', {'obrigatorio': obrigaProcedimento}]">
                            <label for="procedimento">
                                Informe os procedimentos já realizados ou parâmetros verificados
                                <a href="javascript:void(0);" class="link-ajuda"
                                    title="O preenchimento deste campo é fundamental, pois ele é avaliado pela nossa equipe no pré-atendimento, se for detectado falta de informação, o atendimento pode ser devolvido para preenchimento mais explicativo.">
                                    <span class="icon-ajuda"></span>
                                    <span class="visuallyhidden">Ajuda</span>
                                </a>
                            </label>
                            <textarea id="procedimento" name="procedimento" maxlength="2000" style="height: 90px !important"></textarea>
                            <sup>* máximo 2000 caracteres</sup>
                        </div>
                    </div>

                    <div class="b-xs-12">
                        <p class="aviso-orientacoes">
                            <span class="texto" v-html="regrasDeAtendimento"></span><br/>
                            <b>Para iniciar o atendimento on-line, você deve marcar o campo Ciente ao lado <span class="icono-arrow2-left"></span></b>
                            <input type="checkbox" id="ciente" name="ciente" v-model="ciente" style="margin-left: 10px" />
                            <label for="ciente" style="font-weight: 300; color: red">&nbsp;Ciente das orientações acima</label>
                        </p>
                    </div>

                    <div class="b-xs-12">
                        <div class="campo align-center">
                            <input type="button" class="bt" value="Entrar" id="bt_entrar" :disabled="!ciente" @click="salaEspera"/>
                            &nbsp;&nbsp;
                            <input type="button" class="bt" value="Fechar" onclick="window.close();"/>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
import select2 from './Select2.vue';

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

export default
{
    name: 'form-cliente',
    data() {
        let vm   = this;
        let auth = this.$cookies.get('auth');

        $('.versao-aviso').attr('title', '');
        $('[name="versao"]').unmask().attr('placeholder', '');

        let sistemasWebNaoVerifica = this._SISTEMA_ID_WEB;

        sistemasWebNaoVerifica = _.remove(sistemasWebNaoVerifica, function(v) {
            return [31, 40, 45].indexOf(v) === -1;
        });

        this.$socket.emit('cliente-regras-atendimento', 999, (res) => {
            if (!res.texto) return;

            let texto = res.texto.replace(new RegExp('\r?\n','g'), '<br/>');

            if (auth.dados.clienteInativo)
                texto = texto.replace('#chatClienteInativo#', exibe_tempo_inativo(auth.dados.clienteInativo));

            if (auth.dados.encerraInativo)
                texto = texto.replace('#chatEncerraInativo#', exibe_tempo_inativo(auth.dados.encerraInativo));

            if (auth.dados.clienteInativoUnico)
                texto = texto.replace('#chatClienteInativoUnico#', exibe_tempo_inativo(auth.dados.clienteInativoUnico));

            if (auth.dados.encerraInativoUnico)
                texto = texto.replace('#chatEncerraInativoUnico#', exibe_tempo_inativo(auth.dados.encerraInativoUnico));

            vm.regrasDeAtendimento = texto;
        });

        return {
            chatOnline: true,
            horaInicial: String(auth.dados.horaInicial).substring(0, 5),
            horaFinal: String(auth.dados.horaFinal).substring(0, 5),
            horaAlmocoInicial: String(auth.dados.horaAlmocoInicial).substring(0, 5),
            horaAlmocoFinal: String(auth.dados.horaAlmocoFinal).substring(0, 5),
            ehAtendente: auth.dados.pessoaId >= 80000,
            ehAdicional: auth.adicional !== null,
            auth: auth,
            ciente: false,
            exibeLinhaSistema: false,
            exibeLinhaEsocial: false,
            valueLinhaSistema: 1,
            nomeAtendimento: '',
            usuarioAdicionalId: '',
            usuarioAdicionalLogin: '',
            formEnviado: false,
            regrasDeAtendimento: '',
            sistemasWebNaoVerifica: sistemasWebNaoVerifica,
            obrigaCompleto: false,
            sistemaSelecionado: 0,
            sistemaAtendimentoOriginal: 0,
            valueLinhaEsocial: 0,
            formLinhaSistema: null,
            obrigaVersao: false,
            obrigaProcedimento: true,
            sistemaLinha:String(this.$route.query.sistemaLinha)
        }
    },
    components: {
        select2
    },
    asyncComputed: {
        sistemaDisabled: function () {
            return !this.ehAtendente && !this.ehAdicional;
        },
        usuarios: function () {
            let socket = this.$socket;
            let dados = this.auth.dados;

            if (dados.pessoaId < 80000 && this.auth.adicional === null) {
                return new Promise((resolve) => {
                    socket.emit('cliente-todos-adicional', dados.pessoaId, res => resolve(res));
                });
            }

            return [];
        },
        sistemas: function () {
            if (!this.ehAtendente && !this.ehAdicional)
                return [{ nome: '-- Selecione --' }];

            let socket = this.$socket;
            let loginAdicional = null;

            if (this.ehAdicional)
                loginAdicional = this.auth.adicional.login;

            if (!loginAdicional && this.usuarioAdicionalLogin)
                loginAdicional = this.usuarioAdicionalLogin;

            return new Promise((resolve) => {
                socket.emit('cliente-sistema', {
                    pessoaId: this.auth.dados.pessoaId,
                    loginAdicional: loginAdicional,
                    infraestrutura: this.auth.dados.infraestrutura,
                    linha: this.auth.dados.linha,
                    sistemaLinha:this.sistemaLinha
                }, res => resolve(res));
            });
        },
        clientes: function () {
            let socket = this.$socket;
            let dados = this.auth.dados;

            if (dados.pessoaId >= 80000) {
                return new Promise(resolve => {
                    socket.emit(
                        'cliente-clientes-revenda',
                        dados.representanteOriginalId,
                        res => resolve(res));
                });
            }
        }
    },
    methods: {
        getClientName: function () {
            return this.ehAdicional || this.ehAtendente ? $('#nome').val() : $('input[name="nome_atendimento"]').val();
        },
        carregaSistemas: async function () {
			const dados = this.auth.dados;
            $('#sistema').val('').trigger('change');

			if (dados.linha_sistema !== 3 && dados.linha !== 'Ambos')
				this.exibeLinhaSistema = false;

            this.exibeLinhaEsocial = false;

            let $this  = $('select#nome');
            let socket = this.$socket;
            let loginAdicional = $this.val();

            if (!loginAdicional &&
                typeof this.auth.adicional.login != 'undefined' &&
                this.auth.adicional.login != null &&
                this.auth.adicional.login != ''
            ) {
                loginAdicional = this.auth.adicional.login;
            }

            if (!loginAdicional && this.usuarioAdicionalLogin) {
                loginAdicional = this.usuarioAdicionalLogin;
            }

            if (!this.obrigaCompleto) {
                this.nomeAtendimento = $this.find(':selected').text().trim();
                this.usuarioAdicionalId = $this.find(':selected').data('id');
            }

            let comboSistemas = await new Promise((resolve) => {
                socket.emit('cliente-sistema', {
                    pessoaId: this.auth.dados.pessoaId,
                    loginAdicional: loginAdicional,
                }, res => resolve(res));
            });

            if (comboSistemas.length > 0) {
                this.sistemas = comboSistemas;
                this.sistemaDisabled = false;
            } else {
                this.sistemas = [];
                this.sistemaDisabled = true;
            }
        },
        checkReturn: async function(event) {
            if (!event.target){
                return
            }
            const selectedIndex = event.target.selectedIndex;
            const selectedOption = event.target.options[selectedIndex];
            const occupiedClientName = selectedOption.dataset.atendendo
            const atendimentoId = selectedOption.dataset.atendimento;
            const systemName = selectedOption.dataset.name;
            const disconnected = Number(selectedOption.dataset.disconnected);
            const onChat = Number(selectedOption.dataset.atendido);
            if (!occupiedClientName || !atendimentoId) {
                return
            }

            const clientName = this.getClientName();
            if (occupiedClientName === clientName) {
                if (disconnected) {
                    this.$sweet.confirm(
                        `Deseja continuar com o seu atendimento do<br />sistema ${systemName}?`,
                        'Atenção',
                        () => {},
                        () => {
                            
                            this.$socket.emit('client-connect', {
                                atendimentoId,
                                onChat,
                            })
                        },
                        'warning',
                        'QUERO ATENDIMENTO DE OUTRO SISTEMA',
                        'VOU CONTINUAR COM O MEU ATENDIMENTO',
                    );
                } else {
                    this.$sweet.alert(
                        'Atenção',
                        `Já existe uma solicitação de atendimento<br />para o sistema solicitado com o usuário ${occupiedClientName}<br />Por favor, solicite atendimento para outro sistema.`,
                        'warning',
                        'OK',
                    );
                }
            } else {
                if (disconnected) {
                    this.$sweet.alert(
                        'Atenção',
                        `Parece que ${occupiedClientName} foi desconectado recentemente<br />e ainda estamos aguardando seu retorno.<br /><br /><br />Por favor aguarde até 2 minutos e tente novamente.`,
                        'warning',
                        'OK',
                        true,
                        () => {window.close()}
                    );
                } else {
                    this.$sweet.alert(
                        'Atenção',
                        `Já existe uma solicitação de atendimento<br />para o sistema solicitado com o usuário ${occupiedClientName}<br />Por favor, solicite atendimento para outro sistema.`,
                        'warning',
                        'OK',
                    );
                }
            }
            return;
        },
        verificaLinha: async function (event) {
            let sistemaId = $('#sistema').val();
            let pessoaId = this.auth.dados.pessoaId;
            let socket = this.$socket;

            this.checkReturn(event);

            if (this.obrigaCompleto) sistemaId = this.sistemaSelecionado;

            if (Number(sistemaId) === 999) $('#versaoObrigatorio').removeClass('obrigatorio');
            if ( sistemaId === "" ) {
                $("#versao").attr('disabled','disabled');
            } else {
                $("#versao").removeAttr('disabled');
            }

            // Aplica a máscara na versão do sistema
            this.obrigaVersao = true;

            if ([40, 45].indexOf(Number(sistemaId)) >= 0) {
                $('.versao-aviso').attr('title', 'Informe a versão do sistema no formato 9.99.9.9, inclusive o ponto (.)');
                $('[name="versao"]').mask('0.00.#9.#9', {placeholder: '9.99.9.9'});
            } else if (this._SISTEMA_ID_UNICO.indexOf(Number(sistemaId)) >= 0) {
                $('.versao-aviso').attr('title', 'Informe a versão do sistema no formato 9.99.9.9, inclusive o ponto (.)');
                $('[name="versao"]').mask('0.00.#9.#9', {placeholder: '9.99.9.9'});
            } else if (this._SISTEMA_ID_VISUAL.indexOf(Number(sistemaId)) >= 0) {
                $('.versao-aviso').attr('title', 'Informe a versão do sistema no formato 9.99x, inclusive o ponto (.)');
                $('[name="versao"]').mask('0.00S', {placeholder: '9.99x'});
            } else if ([31].indexOf(Number(sistemaId)) >= 0) {
                $('.versao-aviso').attr('title', 'Informe a versão do sistema no formato 9.99x, inclusive o ponto (.)');
                $('[name="versao"]').mask('0.00S', {placeholder: '9.99x'});
            } else {
                this.obrigaVersao = false;
                $('.versao-aviso').attr('title', '');
                $('[name="versao"]').unmask().attr('placeholder', '');
            }

            let linhaSistema = await new Promise((resolve) => {
                socket.emit('sistema-linha', {pessoaId, sistemaId}, res => resolve(res));
            });

			if (linhaSistema === 3)
				this.exibeLinhaSistema = true;

            if (this._SISTEMA_ID_UNICO.indexOf(Number(sistemaId)) >= 0) {
                this.valueLinhaSistema = 2;
            } else if (this._SISTEMA_ID_VISUAL.indexOf(Number(sistemaId)) >= 0) {
                this.valueLinhaSistema = 1;
            } else {
                if (!this.exibeLinhaSistema && sistemaId !== "" ) {
                    /** Se não for um cliente que tem as duas linhas de sistema para selecionar
                     Faz a verificação de quais tipos de sistema ele tem para marcar padrão a linha do sistema */
                    let sistemas = [];

                    // Cria o array com os sistemas disponível
                     _.forEach(this.sistemas, function(val) {
                        sistemas.push(val.value)
                    });

                    /** Filtra no array de sistemas se algum das linhas ele possui
                     Se achar não achar nenhum sistema igual a variável será igual a variável sistemas */
                    let sistemasUnico  = _.difference(sistemas, this._SISTEMA_ID_UNICO);
                    let sistemasVisual = _.difference(sistemas, this._SISTEMA_ID_VISUAL);
                    
                    let sistemasAtendidosTecWeb = [208,202];

                    if(sistemasAtendidosTecWeb.includes(Number(sistemaId))){

                        this.valueLinhaSistema = 3;
                    } else if (sistemasUnico.length < sistemas.length && sistemasVisual.length == sistemas.length) {
                        // Se tem só sistema do tipo Único, marca sempre como linha Único

                        this.valueLinhaSistema = 2;
                    }
                    // Se tem só sistema Visual, marca sempre como linha Visual
                    else if (sistemasUnico.length == sistemas.length && sistemasVisual.length > 0 && sistemasVisual.length < sistemas.length) {

                        this.valueLinhaSistema = 1;
                    }
                    // Se tem os 2 sistemas exibe a campo para selecionar qual linha escolher
                    else if (sistemasUnico.length < sistemas.length && sistemasVisual.length < sistemas.length) {

                        this.valueLinhaSistema = 0
                        this.exibeLinhaSistema = true;
                    }else {
                        // Se não tem Visual e nem Único marca o visual

                        this.valueLinhaSistema = 1;
                    }
                } else {
                    // Se não por padrão sempre marca o visual
                    this.valueLinhaSistema = 1;
                }
            }

            if (this.obrigaCompleto && this.formLinhaSistema) {
                this.valueLinhaSistema = this.formLinhaSistema;
                $('#linha_sistema').val(this.formLinhaSistema);
            } else {
	            $('#linha_sistema').val(this.valueLinhaSistema);
            }

            if (this.auth.dados.infraestrutura) {

                // Ajuste realizado após a separação das linhas Unico e Visual
                const linhaSistemaNovo = this.auth.dados.linha == 'Visual' ? 1 : 2;
                $('#linha_sistema').val(linhaSistemaNovo);

            }

            $('#linha_sistema').trigger('change');

            let originalId = $('#sistema :selected').data('original');

            if (this.obrigaCompleto) originalId = this.sistemaAtendimentoOriginal;

            this.exibeLinhaEsocial = ([19, 35, 52].indexOf(Number(originalId)) >= 0);
        },
        verificaVersao: async function () {
            let vm = this;
            let sistemaId = Number($('#sistema').val());
            let versaoCliente = $('[name="versao"]').val().toLowerCase();

            if ([999, 129, 132].indexOf(sistemaId) === false) return null;

            $('[name="versao"]').val(versaoCliente);

            if (sistemaId != '' && versaoCliente != '' &&
                this.sistemasWebNaoVerifica.indexOf(sistemaId) === -1
            ) {
                this.$socket.emit('sistema-verifica-versao', sistemaId, dado => {
                    //Verifica se é uma versão mais atual para mostrar o aviso de atualização
                    let msg = '';
                    let compare = 0;

                    if (vm._SISTEMA_ID_VISUAL.indexOf(sistemaId) >= 0 || [31].indexOf(sistemaId) >= 0) {
                        compare = vm.compareVersion(versaoCliente, dado.versao, {lexicographical: true});
                    } else {
                        compare = vm.compareVersion(versaoCliente, dado.versao);
                    }

                    switch (compare) {
                        case -1:
                            msg = `Já existe uma nova versão do ${dado.nome} disponível para atualização
                                , a versão ${dado.versao} de ${exibe_data(dado.dataVersao)}.
                                Recomendamos que você atualize o mais breve possível o ${dado.nome}.
                            `;
                            break;
                        case 0:
                            break;
                        case 1:
                            msg = `Esta não é uma versão oficial do ${dado.nome}. Você está utilizando
                                uma versão especial do ${dado.nome}?
                            `;
                            break;
                    }

                    if (msg !== '' && compare !== 1) {
                        vm.$sweet.alert('Atenção', msg, 'warning', 'OK', true, function () {
                            $('[name="duvida"]').focus();
                        });
                    } else if (compare === 1) {
                        vm.$sweet.confirm(
                            msg,
                            'Atenção',
                            function () {
                                $('[name="versao_especial"]').val(1);
                            },
                            function () {
                                $('[name="versao_especial"]').val(0);
                                $('[name="versao"]').val('');
                            },
                        );
                    }
                });
            }
        },
        compareVersion: function (v1, v2, options) {
            let lexicographical = options && options.lexicographical,
                zeroExtend = options && options.zeroExtend,
                v1parts = v1.split('.'),
                v2parts = v2.split('.');

            function isValidPart(x) {
                return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
            }

            if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) return NaN;

            if (zeroExtend) {
                while (v1parts.length < v2parts.length) v1parts.push("0");
                while (v2parts.length < v1parts.length) v2parts.push("0");
            }

            if (!lexicographical) {
                v1parts = v1parts.map(Number);
                v2parts = v2parts.map(Number);
            }

            for (let i = 0; i < v1parts.length; ++i) {
                if (v2parts.length === i) {
                    return 1;
                }

                if (v1parts[i] === v2parts[i]) {
                    continue;
                } else if (v1parts[i] > v2parts[i]) {
                    return 1;
                } else {
                    return -1;
                }
            }

            if (v1parts.length !== v2parts.length) return -1;

            return 0;
        },
        trocaLinha: function () {
            if (this.obrigaCompleto) return;

            this.valueLinhaSistema = Number($('#linha_sistema').val());

            // Chama a função de aviso da infraestrutura de acordo com o alinha do sistema selecionado pelo cliente/automaticamente
            if (Number($('#sistema').val()) === 999) {
                if (this.valueLinhaSistema == 1) this.mensagemInfraestrutura(1000);
                if (this.valueLinhaSistema == 2) this.mensagemInfraestrutura(1001);
            }
        },
        salaEspera: async function () {
            let vm = this;
            let dados = this.auth.dados;
            let sistemaId = $('#sistema').val();
            let versao = $('#versao').val()

            // Para burlar a verificação da versão
            if (Number(sistemaId) === 999) versao = '0.0';

            vm.ciente = false;

            if (sistemaId !== '' && $('#nome').val() !== '' && $('#duvida').val() != '')
            {
                if (vm.obrigaVersao && $('#versao').val() == ''){
                    vm.$sweet.alert( 'Atenção', 'Preencher todos os campos do formulário', 'error' );
                    return;
                }

                if (vm.obrigaProcedimento && $('#procedimento').val() == ''){
                    vm.$sweet.alert( 'Atenção', 'Preencher todos os campos do formulário', 'error' );
                    return;
                }

                if (($('#linha_sistema').is(':visible') && $('#linha_sistema').val() == 0) ||
                    ($('#linha_esocial').is(':visible') && $('#linha_esocial').val() == 0)
                ){
                    vm.$sweet.alert( 'Atenção', 'Preencher todos os campos do formulário', 'error' );
                    return;
                }

                // Faz a verificação do horário de atendimento do sistema
                let reqDate = '';

                reqDate = new Date().getTime();

                let hoje = new Date(reqDate);
                let hojeTime = hoje.getTime();
                let diaAtual = String(hoje.getDate()).padStart(2, '0');
                let mesAtual = String(hoje.getMonth()+1).padStart(2, '0');
                let anoAtual = hoje.getFullYear();
                let dataAtual = anoAtual +'-'+ mesAtual +'-'+ diaAtual;

                let horaInicialVisual = (new Date(dataAtual+' '+ dados.horaInicial)).getTime();
                let horaFinalVisual = (new Date(dataAtual+' '+ dados.horaFinal)).getTime();
                let horaInicialUnico = (new Date(dataAtual+' '+ dados.horaInicialUnico)).getTime();
                let horaFinalUnico = (new Date(dataAtual+' '+ dados.horaFinalUnico)).getTime();
                let horaInicialAlmoco = 0;
                let horaFinalAlmoco = 0;

                if (dados.horaAlmocoInicial) {
                    horaInicialAlmoco = (new Date(`${dataAtual} ${dados.horaAlmocoInicial}`)).getTime();
                }

                if (dados.horaAlmocoFinal) {
                    horaFinalAlmoco = (new Date(`${dataAtual} ${dados.horaAlmocoFinal}`)).getTime();
                }

                // Se a hora de entrada estiver entre o período de almoço desativa o chat.
                if (horaInicialAlmoco > 0 && hojeTime >= horaInicialAlmoco && hojeTime <= horaFinalAlmoco && process.env.VUE_APP_ENV !== 'dev') {
                    vm.chatOnline = false;
                    return;
                }
                // Verifica se o sistema é do tipo Único
                if (this._SISTEMA_ID_UNICO.indexOf(Number($('#sistema').val())) >= 0) {
                    vm.horaInicial = String(dados.horaInicialUnico).substring(0, 5);
                    vm.horaFinal = String(dados.horaFinalUnico).substring(0, 5);

                    if (hojeTime <= horaInicialUnico || hojeTime >= horaFinalUnico) {
                        vm.chatOnline = false;
                        return;
                    }

                // Verifica se o sistema é do tipo Web
                } else if (this._SISTEMA_ID_WEB.indexOf(Number($('#sistema').val())) >= 0) {
                    //Se selecionar atendimento para a linha Visual
                    if (vm.valueLinhaSistema === 1) {
                        vm.horaInicial = String(dados.horaInicial).substring(0, 5);
                        vm.horaFinal = String(dados.horaFinal).substring(0, 5);

                        if (hojeTime <= horaInicialVisual || hojeTime >= horaFinalVisual) {
                            vm.chatOnline = false;
                            return;
                        }

                    } else {
                        //Se selecionar atendimento para a linha Único
                        vm.horaInicial = String(dados.horaInicialUnico).substring(0, 5);
                        vm.horaFinal = String(dados.horaFinalUnico).substring(0, 5);

                        if (hojeTime <= horaInicialUnico || hojeTime >= horaFinalUnico) {
                            vm.chatOnline = false;
                            return;
                        }
                    }

                // Verifica os horários de atendimento padrão
                } else if(hojeTime <= horaInicialVisual || hojeTime >= horaFinalVisual) {
                    vm.chatOnline = false;
                    return;
                }

                let dadosForm = this.$route.params;

                if (this.obrigaCompleto && Object.keys(dadosForm).length > 0) {
                    if ($('#duvida').val().length < 15) {
                        let msgAlerta = 'O campo dúvida não foi preenchido por completo.<br />'+
                            'Por favor, informe detalhadamente sua dúvida para que possamos '+
                            'melhor atendê-lo.<br />';

                        if (typeof dadosForm.msgObs != 'undefined' && dadosForm.msgObs) {
                            msgAlerta+= '<b>Observação da equipe de atendimento: </b>'+
                                String(entities.decode(dadosForm.msgObs)).replace(new RegExp('\r?\n','g'), '<br />');
                        }

                        vm.$sweet.alert(
                            'Atenção',
                            msgAlerta,
                            'error'
                        );

                        return;
                    }

                    vm.enviaForm();
                } else {
                    if ($('#duvida').val().length < 15
                        || (dados.pessoaId >= 80000 && $('#procedimento').val().length < 15)
                    ) {
                        let msgAlerta = 'É importante que você informe os campos ';
                            msgAlerta+= '"Descreva brevemente a situação ou sua dúvida" e ';
                            msgAlerta+= '"Informe os procedimentos já realizados ou parâmetros verificados", ';
                            msgAlerta+= 'pois enquanto você aguarda para ser atendido, nossa equipe já estará ';
                            msgAlerta+= 'analisando a sua situação. Essas informações irão agilizar na solução ';
                            msgAlerta+= 'do seu atendimento.';

                        vm.$sweet.confirm(
                            msgAlerta,
                            'Atenção',
                            function(){ vm.ciente = true; },
                            function(){
                                $('.swal-button--cancel').attr('disabled', 'disabled');
                                $('.swal-button--cancel').prop('disabled', true);

                                vm.enviaForm();
                            },
                            'warning',
                            'Retornar',
                            'Continuar',
                            true
                        );
                    } else {
                        vm.enviaForm();
                    }
                }

            } else {
                vm.ciente = true;
                vm.$sweet.alert('Atenção', 'Preencher todos os campos do formulário', 'error');
            }
        },
        enviaForm: async function () {
            // Se o formulário já foi enviado mata para não enviar de novo
            if (this.formEnviado) return;

            let vm = this,
                auth = vm.$cookies.get('auth'),
                arrForm = $('form').serializeArray(),
                objForm = {};

            // Marca que o formulário já foi enviado para não dar duplicidade
            this.formEnviado = true;

            const isConnected = await new Promise((resolve) => {
                this.$socket.emit('client-connect', {}, (response) => {
                    return resolve(response);
                });
            });

            for (let key in arrForm) {
                if (arrForm[key].name === 'duvida' || 'procedimento') {
                    objForm[arrForm[key].name] = remove_html(arrForm[key].value);
                } else {
                    objForm[arrForm[key].name] = arrForm[key].value;
                }
            }

            objForm.sistema_nome = String($('#sistema :selected').data('name')).trim();
            objForm.sistema_original = String($('#sistema :selected').data('original')).trim();

            this.$socket.emit('atendente-verifica-online', {
                    sistemaId: Number($('#sistema').val()),
                    linhaSistema: Number(objForm.linha_sistema),
                    auth: auth,
                }, res => {
                    if (!res) {
                        return vm.$router.push({
                            name: 'offline',
                            params: {sistemaNome: objForm.sistema_nome}
                        });
                    } else {
                        let dados = {form: objForm};
                        auth.atendimento = {};
                        // Seta o novo valor do cookie
                        auth.atendimento.linhaEsocial = Number(objForm.linha_esocial);
                        auth.atendimento.nome = $('#nome').val();
                        auth.atendimento.sistemaNome = objForm.sistema_nome;
                        auth.atendimento.sistemaId = Number(objForm.sistema);
                        auth.atendimento.sistemaOriginal = Number($('#sistema :selected').data('original'));
                        auth.atendimento.sistemaLinha = vm.valueLinhaSistema;

                        let dadosForm = this.$route.params;

                        if (this.obrigaCompleto && Object.keys(dadosForm).length > 0) {
                            auth.atendimento.devolvido = true;
                            auth.atendimento.devolvidoPor = dadosForm.devolvido_por;
                            auth.atendimento.obsAtendente = remove_html(dadosForm.msg_obs);
                        }

                        /** Se isso for maior que 0, quer dizer que existe o select de usuário, então
                        é o usuário do cliente conectado. */
                        if ($('select#nome').length > 0) {
                            objForm.nome = $('[name="nome_atendimento"]').val();
                            auth.atendimento.nome = $('[name="nome_atendimento"]').val();
                            auth.atendimento.usuarioAdicionalId = $('[name="nome"] :selected').data('id');
                            dados.form.usuario_adicional_login = $('[name="nome"]').val();
                        }

                        // Limpa as chaves antigas e registra a nova
	                    if (process.env.VUE_APP_ENV === 'dev')
		                    vm.$cookies.set('auth', auth)
	                    else
		                    vm.$cookies.set('auth', auth, null, null, window.location.host);

                        dados.auth = vm.$cookies.get('auth');

                        /**
                         * Verifica a possibilidade de ter atendimentos repetidos
                         * quando clientes ou revendas entram ao mesmo tempo no atendimento
                         * e caso tenha, ele avisa o usuário e disconecta ele automáticamente
                         */
                        vm.$socket.emit(
                            'cliente-verifica-atendimento',
                            {auth, sistemaId: Number(objForm.sistema)},
                            nome => {
                                if (nome) {
                                    let msg = `Já existe uma solicitação de atendimento<br/>para o sistema
                                    solicitado com o usuário ${nome}<br/>Por favor, solicite atendimento
                                    para outro sistema.<br/>`;

                                    vm.$sweet.alert('Atenção', msg, 'warning');
                                    vm.formEnviado = false;

                                    return;
                                }

                                vm.$socket.emit('cliente-sala-espera', dados, ({err, atendimentoId, adicional}) => {
                                    if (err) return vm.$sweet.alert('Atenção', err, 'warning');

                                    auth.atendimentoId = atendimentoId;

                                    if (adicional) auth.adicional = adicional;

                                    vm.obrigaCompleto = false;
	                                if (process.env.VUE_APP_ENV === 'dev')
		                                vm.$cookies.set('auth', auth)
	                                else
		                                vm.$cookies.set('auth', auth, null, null, window.location.host);
                                    vm.$router.push({name: 'sala-espera', params: {'duvida': objForm['duvida']}});
                                });
                            }
                        );
                    }
                }
            );
        },
        exibeSistemaCliente: function () {
            $("#clock").remove();
            $('#nomeSistema').html('ATENDIMENTO ON-LINE - CLIENTE'+`<br> <span id="clock">00:00:00</span>`);
        },
        mensagemInfraestrutura: function (tipoAviso) {

            if (tipoAviso !== 0) {
	            this.$socket.emit('cliente-regras-atendimento', tipoAviso, (res) => {
		            let texto = res.texto;
		            this.$sweet.alert('Atenção', texto, 'warning', 'CIENTE', true);
	            });
            }
        },
        buildAtendente: function (atendimento, auth) {
            let data = {};
            // Seta o novo valor do cookie
            data.nome = atendimento.dados.nome;
            data.sistemaId = atendimento.dados.sistemaId;
            data.sistemaOriginal = atendimento.dados.sistemaOriginal;
            data.sistemaLinha = auth.dados.linha_sistema;

            return data;
        }
    },
    mounted() {
        this.sistemaLinha = this.$route.query.sistemaLinha;

        var today = new Date();

        var dia = today.getDate();
        var mes = today.getMonth() + 1;
        var ano = today.getFullYear();
        var h = today.getHours();
        var m = today.getMinutes();
        var s = today.getSeconds();

        dia = checkTime(dia);
        mes = checkTime(mes);
        ano = checkTime(ano);
        h = checkTime(h);
        m = checkTime(m);
        s = checkTime(s);

        document.getElementById("clock").innerHTML =
        dia + "/" + mes + "/" + ano + " - " + h + ":" + m + ":" + s;
        var t = setTimeout(startTime, 500);

        const { dados, adicional } = this.auth

        document.getElementById("informacao-usuario").innerHTML = `
        ${dados.pessoaId} - ${dados.nomeCompleto} - ${dados.cidade} / ${dados.uf} - [Revenda: ${dados.representanteOriginalId} - ${dados.nomeRepresentanteOriginal}] <br>
        ${adicional ? adicional.nomeAviso : ''}
        `

        document.getElementById("chat-cabecalho").className += " chat-cabecalho"

        let sistemaId = $('#sistema').val();
        if ( sistemaId === "" ) {
            $("#versao").attr('disabled','disabled');
        } else {
            $("#versao").removeAttr('disabled');
        }

        let dadosForm = this.$route.params;

        if (this.auth.dados.pessoaId < 80000) this.obrigaProcedimento = false;

        if (Object.keys(dadosForm).length > 0) {

            this.obrigaCompleto = true;

            if ($('select#nome').length > 0 && dadosForm.usuarioAdicionalLogin) {
                this.usuarioAdicionalLogin = dadosForm.usuarioAdicionalLogin;
                this.nomeAtendimento = dadosForm.usuario_nome;
                this.usuarioAdicionalId = dadosForm.usuarioAdicionalId;
            }

            this.sistemaSelecionado = dadosForm.sistema_id;
            this.sistemaAtendimentoOriginal = dadosForm.sistema_original;
            this.carregaSistemas();
            this.verificaLinha();

            if (typeof dadosForm.linha_esocial != 'undefined') {
                this.valueLinhaEsocial = dadosForm.linha_esocial;
                $('#linha_esocial').val(dadosForm.linha_esocial);
                $('#linha_esocial').trigger('change');
            }

            this.formLinhaSistema = dadosForm.linha_sistema;

            $('#versao').val(dadosForm.versao);
            $('#duvida').val(dadosForm.duvida);
            $('#procedimento').val(dadosForm.procedimento);

            setTimeout(function () {
                $('#sistema').parent()
                    .find('.select2')
                    .addClass('select2-container--disabled fake_disabled');
            }, 500);
        }
    },
	beforeMount() {
		const dados = this.auth.dados;

		if (dados.linha === 'Ambos' && dados.linha_sistema === 3)
			this.exibeLinhaSistema = true;
		else
			this.exibeLinhaSistema = false;
	},
    created() {
        this.$socket.on('enter-waiting-room', (data) => {
            let auth = this.$cookies.get('auth');
            auth.atendimento = data.atendimento;
            if (process.env.VUE_APP_ENV === 'dev') {
                this.$cookies.set('auth', auth)
            } else {
                this.$cookies.set('auth', auth, null, null, window.location.host)
            }

            let form = {};

            form.sistema_original = String($('#sistema :selected').data('original')).trim();
            form.sistema_nome = String($('#sistema :selected').data('name')).trim();

            const sistemaId = Number($('#sistema').val())
            form.sistema = sistemaId;
            form.procedimento = data.procedimento;
            form.duvida = data.duvida;
            form.versao = data.versao;
            form.nome_funcionario = data.nome_funcionario;
            form.nome_cliente = data.nome_cliente;
            form.nome = this.getClientName();

            this.$socket.emit('atendente-verifica-online', {
                    sistemaId,
                    linhaSistema: Number($('#linha_sistema').val()),
                    auth: auth,
                }, res => {
                    if (!res) {
                        return vm.$router.push({
                            name: 'offline',
                            params: {sistemaNome: form.sistema_nome}
                        });
                    } else {
                        let dados = {form: form};
                        auth.atendimento = {};
                        // Seta o novo valor do cookie
                        auth.atendimento.linhaEsocial = Number($('#linha_esocial').val());
                        auth.atendimento.nome = $('#nome').val();
                        auth.atendimento.sistemaNome = form.sistema_nome;
                        auth.atendimento.sistemaId = Number(sistemaId);
                        auth.atendimento.sistemaOriginal = Number($('#sistema :selected').data('original'));
                        auth.atendimento.sistemaLinha = Number($('#linha_sistema').val());

                        /** Se isso for maior que 0, quer dizer que existe o select de usuário, então
                        é o usuário do cliente conectado. */
                        if ($('select#nome').length > 0) {
                            dados.form.nome = $('[name="nome_atendimento"]').val();
                            auth.atendimento.nome = $('[name="nome_atendimento"]').val();
                            auth.atendimento.usuarioAdicionalId = $('[name="nome"] :selected').data('id');
                            dados.form.usuario_adicional_login = $('[name="nome"]').val();
                        }

                        // Limpa as chaves antigas e registra a nova
	                    if (process.env.VUE_APP_ENV === 'dev') {
		                    this.$cookies.set('auth', auth)
                        } else {
                            this.$cookies.set('auth', auth, null, null, window.location.host);
                        }

                        dados.auth = this.$cookies.get('auth');

                        this.$socket.emit('cliente-sala-espera', dados, ({err, atendimentoId, adicional}) => {
                            if (err) return this.$sweet.alert('Atenção', err, 'warning');

                            auth.atendimentoId = atendimentoId;

                            if (adicional) auth.adicional = adicional;

                            if (process.env.VUE_APP_ENV === 'dev')
                                this.$cookies.set('auth', auth)
                            else
                                this.$cookies.set('auth', auth, null, null, window.location.host);
                            this.$router.push({name: 'sala-espera', params: {'duvida': form.duvida}});
                        });
                    }
                }
            );
        })

        this.$socket.on('enter-chat', (data) => {
            let auth = this.$cookies.get('auth');
            auth.atendimentoId = data.atendimentoId;
            auth.atendimento = this.buildAtendente(data.atendimento, auth)
            if (process.env.VUE_APP_ENV === 'dev') {
                this.$cookies.set('auth', auth)
            } else {
                this.$cookies.set('auth', auth, null, null, window.location.host)
            }
            this.$router.push('/conversa/'+ auth.atendimentoId)
        })

        this.$socket.on('server-ping', () => {
            this.$socket.emit('client-ping');
        })

        this.$socket.on('reconnect', () => {
            let auth = this.$cookies.get('auth');
            if (auth && auth.atendimentoId) {
                this.$socket.emit('reconnected', { atendimentoId: auth.atendimentoId });
            }
        });

        this.$socket.on('room-already-closed', () => {
            this.$sweet.alert('Atenção', 'Parece que o seu atendimento acabou de ser finalizado. Por favor retorne a fila de atendimento.', 'warning');
        });
    }
}
</script>

<style>
    #versao {text-transform: lowercase;}
</style>
