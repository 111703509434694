<template>
    <div class="FormSuporteWeb">
        <fieldset v-show="mostraSuporte && !suporteCriado && finaliza">
            <div class="campo">
                <button type="button" @click="reloadInputsSuporte">Recarregar campos</button>
            </div>
            <legend>Registro de atendimento no Suporte Web</legend>
            <div class="campo obrigatorio">
                <label>Problema</label>
                <select2 name="problema">
                    <option value="">-- Selecione --</option>
                    <option v-for="p in problema" :value="p.value" :key="p.value">
                        {{ p.label }}
                    </option>
                </select2>
            </div>
            <div class="campo obrigatorio">
                <label>Funcionalidade</label>
                <select2 name="funcionalidade">
                    <option value="">-- Selecione --</option>
                    <option v-for="f in funcionalidade" :value="f.value" :key="f.value">
                        {{ f.label }}
                    </option>
                </select2>
            </div>
            <div class="campo obrigatorio">
                <label>Status</label>
                <select class="text" name="status" v-model="optStatus"
                        @input="mudaStatusSuporte" @change="optStatusChange">
                    <option value="">-- Selecione --</option>
                    <option v-for="s in status" :value="s.value" :key="s.value"> {{ s.label }} </option>
                </select>
            </div>
            <div class="campo">
                <label>Motivo</label>
                <select2 name="motivo" id="motivo" disabled>
                    <option value="">-- Selecione --</option>
                    <option v-for="m in motivo" :value="m.value" :key="m.value"> {{ m.label }} </option>
                </select2>
            </div>
            <div class="campo obrigatorio">
                <label>Alerta</label>
                <select class="text" name="alerta" v-model="optAlerta" @change="optAlertaChange">
                    <option value="">-- Selecione --</option>
                    <option v-for="a in alerta" :value="a.value" :key="a.value"> {{ a.label }} </option>
                </select>
            </div>
            <div class="campo">
                <label>Observações</label>
                <input type="text" name="observacoes"/>
            </div>
            <div class="obrigatorio campo">
                <label>Resumo da conversa
                    <a href="javascript:void(0);" class="link-ajuda"
                        title="Descreva nesse campo o resumo da conversa com o cliente no chat, para registro no suporte WEB. Atenção, não é para COPIAR E COLAR a conversa, é para indicar os assuntos tratados na conversa, para facilitar a pesquisa no Suporte WEB">
                        <span class="icon-ajuda"></span><span class="visuallyhidden">Ajuda</span>
                    </a>
                </label>
                <textarea name="resumo_conversa" maxlength="2000"></textarea>
                <br/>
                <small>Maxímo 2000 caracteres</small>
            </div>
            <avaliacao v-if="!avaliacaoEnviada" :info="info" :atendimentoId="atendimentoId"
            ></avaliacao>
            <div class="campo">
                <label>Telefone de contato</label>
                <input type="text" name="telefone_contato"/>
            </div>
            <div class="campo">
                <label>Email de contato</label>
                <input type="text" name="email_contato"/>
            </div>
            <button type="button" class="bt bt_cria_suporte_web" @click="abreSuporteWeb" v-show="chatFinalizado && !suporteCriado">
                Registrar
            </button>
            <a href="javascript:;" v-if="existeSuporte" @click="abrirConsultaSuporte">
                Existem registros em aberto no Suporte WEB para esse cliente. Clique aqui para consultar
            </a>
            <input type="hidden" name="suporte_id"/>
        </fieldset>

        <fieldset v-show="chatFinalizado && suporteCriado && finaliza">
            <legend>Registro de atendimento no Suporte WEB</legend>
            <p v-if="!novaInteracao">
                <span v-for="nSup in numSuporte" :key="nSup">
                    <b>Suporte n&ordm; {{ nSup }}</b> criado com sucesso.
                    <a :href="suporteWebUrl + '/abrirAtendimento.php?id='+ nSup" target="_blank">
                    Clique aqui para visualizar o registro.
                    </a>
                    <br/>
                </span>
                <a href="javascript:;" @click="recriarAtendimento">
                    Clique aqui para criar um novo registro para este cliente
                </a>
            </p>
            <p v-else>
                Interação cadastrada no <b>suporte n&ordm; {{ numSuporte[0] }}</b> com sucesso.
                <a :href="suporteWebUrl + '/abrirAtendimento.php?id='+ numSuporte" target="_blank">
                    Clique aqui para consultar o registro no Suporte WEB
                </a>
            </p>
        </fieldset>
    </div>
</template>

<script>
const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

import Select2 from '../Select2.vue';
import Avaliacao from './Avaliacao.vue';

export default {
    props: ['atendimentoId', 'info', 'mostraSuporte', 'suporteCriado', 'finaliza', 'chatFinalizado'],
    data() {
        let suporteWebUrl = 'https://suporte.sci.com.br';
        if (process.env.VUE_APP_ENV == 'dev') {
            suporteWebUrl = 'http://localhost:3001';
        } else if (process.env.VUE_APP_ENV == 'hml') {
            suporteWebUrl = 'https://suporteweb-hml.sci.com.br'
        }
        return {
            optStatus: 'Fechado',
            optAlerta: 0,
            novaInteracao: false,
            existeSuporte: false,
            problema: [],
            funcionalidade: [],
            status: [],
            motivo: [],
            alerta: [],
            numSuporte: [],
            avaliacaoEnviada: false,
            suporteWebUrl,
        }
    },
    components: {
        Select2, Avaliacao
    },
    methods: {
        optStatusChange: function () {
            this.optStatus = $('#'+this.atendimentoId+' [name="status"] :selected').val();
        },
        optAlertaChange: function () {
            this.optAlerta = $('#'+this.atendimentoId+' [name="alerta"] :selected').val();
        },
        abreSuporteWeb: async function () {
            let vm = this;
            let $this = $('#'+ vm.atendimentoId);
            let url = 'https://suporte.sci.com.br/webservice/registraChatSuporte.php';
            let auth = vm.$cookies.get('auth');

            if (process.env.VUE_APP_ENV == 'dev') {
                url = 'http://localhost:8003/webservice/registraChatSuporte.php';
            } else if (process.env.VUE_APP_ENV == 'hml') {
                url = 'https://suporteweb-hml.sci.com.br/webservice/registraChatSuporte.php';
            }

            if ($this.find('[name="problema"]').val() == '' ||
                $this.find('[name="funcionalidade').val() == '' ||
                $this.find('[name="status').val() == '' ||
                $this.find('[name="motivo"]:not([disabled])').val() == '' ||
                $this.find('[name="alerta"]').val() == '' ||
                $this.find('[name="resumo_conversa"]').val() == ''
            ) {
                vm.$sweet.alert('Atenção', 'Preencha todos os campos obrigatórios', 'warning');
                return;
            }

            if ($this.find('[name="resumo_conversa"]').val().length < 50) {
                vm.$sweet.alert('Atenção', 'Informe no mínimo 50 e no máximo 2000 caracteres no resumo.', 'warning');
                return;
            }

            let avaliacao = $this.find('[name="avaliacao"]:checked').val();
            let comentario = entities.encode($this.find('[name="comentario"]').val());

            if (!vm.avaliacaoEnviada) {
                if (typeof avaliacao === 'undefined' || avaliacao === null) {
                    vm.$sweet.alert('Atenção', 'Por favor faça a avaliação da conversa.', 'warning');
                    return;
                }

                if ((avaliacao == 'Ruim' || avaliacao == 'Péssimo') && (!comentario || comentario.length < 15)) {
                    vm.$sweet.alert('Atenção', `Informe no mínimo 15 caracteres sobre o motivo da avaliação ${avaliacao}.`, 'warning');
                    return;
                }
            }

            let chatRow = await new Promise((resolve) => {
                vm.$socket.emit('busca-atendimento', vm.atendimentoId, res => resolve(res));
            });

            let info = {
                'hash': 'ad4fa39e4e6ec8ef76014b3e06fc4c72',
                'sistema_id': chatRow.sistema_original,
                'chat': this.$parent.conversa,
                'duvida': chatRow.duvida,
                'procedimento': chatRow.procedimento,
                'revenda_id': chatRow.revenda_pai,
                'usuario_adicional_id': chatRow.usuario_adicional_id,
                'cliente_id': chatRow.atendido_id,
                'nome_atendido': chatRow.contato,
                'atendente_id': auth.dados.pessoaId,
                'hora_inicial': chatRow.data_atendido,
                'hora_final': chatRow.data_encerrado,
                'versao': chatRow.versao,
                'problema': $this.find('[name="problema"] :selected').val(),
                'funcionalidade': $this.find('[name="funcionalidade"] :selected').val(),
                'status': $this.find('[name="status"] :selected').val(),
                'motivo': $this.find('[name="motivo"] :selected').val(),
                'alerta': $this.find('[name="alerta"] :selected').val(),
                'observacoes': $this.find('[name="observacoes"]').val(),
                'telefone_contato': $this.find('[name="telefone_contato"]').val(),
                'email_contato': $this.find('[name="email_contato"]').val(),
                'suporte_id': $this.find('[name="suporte_id"]').val(),
                'resumo_conversa': $this.find('[name="resumo_conversa"]').val(),
            };

            if (typeof chatRow.nome_cliente != 'undefined') info.cliente = chatRow.nome_cliente;
            if (typeof chatRow.nome_funcionario != 'undefined') info.funcionario = chatRow.nome_funcionario;

            $this.find('.bt_cria_suporte_web').attr('disabled', 'disabled');

            await vm.$http.post(url, info).then(function (response) {
                if (response.ok) {
                    if (response.body != null && response.body != '' && /^\d+$/.test(response.body)) {
                        vm.numSuporte = vm.numSuporte.reverse();
                        vm.numSuporte.push(response.body);
                        vm.numSuporte = vm.numSuporte.reverse();
                        vm.$parent.suporteCriado = true;
                        if (info.suporte_id) vm.novaInteracao = true;
                    } else {
                        vm.$sweet.alert('Atenção', `Houve um erro ao registrar a avaliação do cliente ${chatRow.atendido_id}. Tente novamente dentro de alguns minutos.`, 'error');
                        console.log('Erro(1) Retorno: ' + response.body);
                    }
                } else {
                    vm.$sweet.alert('Atenção', `Houve um erro ao registrar a avaliação do cliente ${chatRow.atendido_id}. Tente novamente dentro de alguns minutos.`, 'error');
                    console.log('Erro(2) Retorno: ' + response.body);
                }
            }, function (response) {
                if (!response.ok) {
                    vm.$sweet.alert('Atenção', `Houve um erro ao registrar a avaliação do cliente ${chatRow.atendido_id}. Tente novamente dentro de alguns minutos.`, 'error');
                    console.log('Erro(3) Retorno: ' + response.body);
                }
            });

            if (avaliacao && !vm.avaliacaoEnviada) {
                // Avaliação
                let paramsAvaliacao = {
                    hash: 'ad4fa39e4e6ec8ef76014b3e06fc4c72',
                    atendimentoId: chatRow.atendimento_id,
                    avaliacao: avaliacao,
                    comentario: comentario,
                    tipo: 'Atendente',
                    suporteWebNumero: vm.numSuporte[0],
                };

                vm.$socket.emit('conversa-avaliacao', paramsAvaliacao, function(data) {
                });
            }
        },
        mudaStatusSuporte: function () {
            let motivo = $('#'+ this.atendimentoId +' [name="motivo"]');

            if ($('#'+ this.atendimentoId +' [name="status"]').val() == 'Fechado') {
                motivo.attr('disabled', 'disabled');
                motivo.parent('div').removeClass('obrigatorio');
            } else {
                motivo.removeAttr('disabled');
                motivo.parent('div').addClass('obrigatorio');
            }
        },
        abrirConsultaSuporte: function () {
            let vm = this;
            let suportewebview = new vm.$suportewebview({
                propsData: {
                    pessoaId: vm.info.atendido_id,
                    atendimentoId: vm.atendimentoId,
                    sistemaId: vm.info.sistema_original
                }
            });

            suportewebview.$mount();

            $('#box-suporte').show();

            document.getElementById('box-view-suporte').appendChild(suportewebview.$el);
        },
        recriarAtendimento: function () {
            let vm = this;

            this.$parent.suporteCriado = false;
            this.$parent.mostraSuporte = true;

            let $this = $('#'+ vm.atendimentoId);

            $this.find('.bt_cria_suporte_web').attr('disabled', '');
            $this.find('.bt_cria_suporte_web').removeAttr('disabled');
        },
        reloadInputsSuporte: async function () {
            let vm = this;
            let auth = vm.$cookies.get('auth');
            // Busca os dados dos inputs do suporte
            let inputs = await new Promise((resolve) => {
                vm.$socket.emit('busca-inputs-suporte-web', vm.info.sistema_original,
                    res => resolve(res)
                )
            });

            vm.problema = inputs.problema;
            vm.funcionalidade = inputs.funcionalidade;
            vm.status = inputs.status;
            vm.motivo = inputs.motivo;
            vm.alerta = inputs.alerta;

            // Verifica se já existe suporte em aberto para esse cliente
            vm.$socket.emit('busca-suporte-aberto', {
                pessoaId: vm.info.atendido_id,
                revendaId: auth.dados.representanteOriginalId,
                sistemaId: vm.info.sistema_original
            }, function(res) {
                if (Object.keys(res).length > 0) {
                    vm.existeSuporte = true;
                }
            });
        },
    },
    mounted: function () {
        let vm = this;
        let auth = vm.$cookies.get('auth');
        let $this = $('#'+vm.atendimentoId);

        this.$nextTick(() => {
            $this = $('#'+vm.atendimentoId);

            // Carrega inputs Suporte Web
            setTimeout(async function () {
                // Busca os dados dos inputs do suporte
                let inputs = await new Promise((resolve) => {
                    vm.$socket.emit('busca-inputs-suporte-web', vm.info.sistema_original,
                        res => resolve(res)
                    )
                });

                vm.problema = inputs.problema;
                vm.funcionalidade = inputs.funcionalidade;
                vm.status = inputs.status;
                vm.motivo = inputs.motivo;
                vm.alerta = inputs.alerta;

                // Verifica se já existe suporte em aberto para esse cliente
                vm.$socket.emit('busca-suporte-aberto', {
                    pessoaId: vm.info.atendido_id,
                    revendaId: auth.dados.representanteOriginalId,
                    sistemaId: vm.info.sistema_original
                }, function(res) {
                    if (Object.keys(res).length > 0) {
                        vm.existeSuporte = true;
                    }
                });
            }, 5000);
        });
    }
}

</script>