<template>

    <div :class="['janela-chat', {'maximizada': maximizado, 'sem-interacao': semInteracao, 'inativo-cliente': inativoCliente}]" :id="atendimentoId" :ref="atendimentoId">
        <form v-on:submit.prevent="enviaMensagem(atendimentoId)" class="datahora" :data-datetime="infoDataAtendido">

            <div class="titulo">
                <span class="nome fila_usuario">
                    <span :class="['status',{online: online, offline: !online, nova: novaMsg}]"></span>
                    {{ nomeAtendido }}
                    <span v-if="!maximizado">
                        - TEMPO EM ATENDIMENTO: <span class="tempo">00:00</span>
                    </span>
                </span>
                <span class="acoes">
                    <a href="#" @click="finalizaAtendimento(false)" title="Finalizar atendimento">
                        <span class="icon icon-close"></span>
                    </a>
                    <a href="#" @click="maximizar" v-if="!maximizado" title="Maximizar janela">
                        <span class="icon icon-newtab"></span>
                    </a>
                    <a href="#" @click="restaurar" v-if="maximizado" title="Restaurar janela">
                        <span class="icon icon-download2"></span>
                    </a>
                    <a href="#" v-if="!minimizado" @click="minimizar" title="Minimizar janela">
                        <span class="icon icon-minus"></span>
                    </a>
                    <a href="#" v-if="minimizado" @click="restaurar" title="Restaurar janela">
                        <span class="icon icon-plus"></span>
                    </a>
                    <a href="#" v-show="finaliza" @click="toggleSuporte" title="Abrir suporte">
                        <span class="icon icon-info"></span>
                    </a>
                    <a href="#" v-show="temAvisos"
                       data-fancybox="avisos" :data-src="'#avisos'+atendimentoId"
                       title="Avisos" class="bt-aviso-suporte-web"
                    >
                        <span class="icon icon-warning"></span>
                    </a>
                </span>
            </div>
            <!-- box info fixo, qualquer alteranção nessas tabelas deve ser replicada no box de baixo também -->
            <informacoes
                :info="info"
                :isUnico="isUnico"
                v-if="info && maximizado && boxInfoFixo">
            </informacoes>

            <div class="conteudo">
                <!-- box de informações dentro da conversa -->
                <informacoes
                    :info="info"
                    :isUnico="isUnico"
                    v-if="info && maximizado && !boxInfoFixo">
                </informacoes>

                <span class="mensagem" v-for="(item, index) in conversa" :key="index" v-html="converteHtml(item)"></span>
                <br/>

                <form-suporte-web
                    :info="info"
                    :atendimentoId="atendimentoId"
                    :mostraSuporte="mostraSuporte"
                    :suporteCriado="suporteCriado"
                    :finaliza="finaliza"
                    :chatFinalizado="chatFinalizado"
                ></form-suporte-web>
            </div>

            <div class="box-mensagem">
                <div class="campo flex">
                    <input type="text" name="texto" maxlength="1000" autocomplete="off"
                           placeholder="Digite sua mensagem ou copie e cole seu print aqui e aperte Enter para enviar..."/>

                    <input type="file" multiple :id="'uploader_input'+atendimentoId" @change="changeFiles"
                           accept=".txt,.TXT,.pdf,.PDF,.xml,.XML,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.mp4,.MP4,.gif,.GIF,.re,.RE,.sd,.SD,.m00,.m01,.m02,.m03,.m04,.m05,.m06,.m07,.m08,.m09,.m10,.m11,.m12,.M00,.M01,.M02,.M03,.M04,.M05,.M06,.M07,.M08,.M09,.M10,.M11,.M12"
                           style="display: none;"/>

                    <a href="#" title="Enviar arquivo (até 25MB | Tipos: TXT, PDF, XML, JPG, PNG, JPEG, MP4, GIF, Sefip (RE), Caged (M99), Seguro Desemprego (SD))"
                       :id="'uploader_button'+atendimentoId">
                        <svg class="icon icon-attachment" style="height: 23px;"><use xlink:href="#icon-attachment"></use></svg>
                    </a>

                    <a href="#" title="Transferir atendimento" @click="abreTransferir" class="bt-trans">
                        <span class="icon icon-exit"></span>
                    </a>
                    <a href="#" title="Finalizar por inatividade" @click="finalizaAtendimento(true, true)" class="bt-encerrar">
                        <span class="icon icon-clock disabled"></span>
                    </a>
                    <a href="#" title="Finalizar atendimento" @click="finalizaAtendimento(true)" class="bt-encerrar">
                        <span class="icon icon-close"></span>
                    </a>
                </div>

                <div class="campo" :style="{ display: (files && files.length > 0) ? 'block' : 'none' }">
                    <p><b>Arquivos que serão enviados</b></p>
                    <ul>
                        <li v-for="file in files" :key="file.lastModified">
                            {{ file.name }} - {{ formataBytes(file.size) }}
                            [<div :class="[ 'progresso', 'file'+ file.lastModified ]"><span class="bar"></span></div>]
                            <span :class="[ 'perc_progresso', 'file'+ file.lastModified ]">0%</span>
                        </li>
                    </ul>
                    <button :id="'uploader_submit'+atendimentoId" type="button">Enviar arquivos</button>
                </div>

                <div class="campo">
                    <div class="float-left">
                        <input type="checkbox" name="rolar" v-model="rolar" />
                        <label class="margin-right-10">&nbsp;Rolar conversa automaticamente</label>
                        <input type="checkbox" name="box_info_fixo" v-model="boxInfoFixo" @click="marcaBoxInfo" />
                        <label>&nbsp;Fixar quadro de informações</label>
                    </div>
                </div>

                <transferir-atendimento
                    :info="info"
                    :atendimentoId="atendimentoId"
                    ref="transferir"
                ></transferir-atendimento>

                <input type="submit" value="Enviar" style="display: none;"/>

            </div>

        </form>

        <box-avisos
            :atendimentoId="atendimentoId"
            :info="info"
            ref="boxavisos"
        ></box-avisos>

    </div>

</template>

<script>

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

import Select2 from './Select2.vue';
import BoxAvisos from './janela-chat/BoxAvisos';
import FormSuporteWeb from './janela-chat/FormSuporteWeb';
import Informacoes from './janela-chat/Informacoes';
import TransferirAtendimento from './janela-chat/TransferirAtendimento';

export default
{
    name: 'JanelaChat',
    props: ['atendimentoId', 'nomeAtendido', 'finaliza', 'temSciCloud'],
    data () {
        let auth = this.$cookies.get('auth');
        let boxInfoFixo = auth.config.boxInfoFixo;
        let botaoEncerraInativo = [];
            botaoEncerraInativo[this.atendimentoId] = false;

        return {
            conversa: [],
            minimizado: false,
            maximizado: true,
            online: true,
            novaMsg: false,
            rolar: true,
            files: [],
            chatFinalizado: false,
            suporteCriado: false,
            usuarioPermitido: auth.dados.pessoaId,
            mostraSuporte: false,
            isUnico: false,
            boxInfoFixo: boxInfoFixo,
            timerInativo: {},
            semInteracao: false,
            inativoCliente: false,
            atendidoId: 0,
            temAvisos: false,
            botaoEncerraInativo: botaoEncerraInativo,
            tempoAtendimento: '',
            infoDataAtendido: null,
            infoConversa: null,
        }
    },
    components: {
        Select2,
        BoxAvisos,
        FormSuporteWeb,
        Informacoes,
        TransferirAtendimento,
    },
    asyncComputed: {
        conversa: async function () {
            let vm = this;

            return await new Promise((resolve) => {
                vm.$socket.emit('conversa-carrega', vm.atendimentoId, res => {
                    if (res.dados.clienteInativo) {
                        $('#'+vm.atendimentoId+' .icon-clock.disabled').removeClass('disabled');
                        vm.botaoEncerraInativo[vm.atendimentoId] = true;
                    }

                    resolve(res.mensagens);
                })
            });
        },
        info: async function () {
            let vm = this;
            let auth = this.$cookies.get('auth');
            let chatRow = await new Promise(resolve => {
                vm.$socket.emit('busca-atendimento', vm.atendimentoId, res => {
                    resolve(res)
                });
            });

            if (chatRow.atendente_nome.indexOf(auth.dados.nome) >= 0) vm.finaliza = true;

            vm.isUnico = this._SISTEMA_ID_UNICO.indexOf(chatRow.sistema_id) >= 0;
            vm.atendidoId = chatRow.atendido_id;

            vm.infoDataAtendido = chatRow.data_atendido;
            vm.$refs.transferir.sistemaTransfere = chatRow.sistema_id;

            return chatRow;
        },
    },
    methods: {
        enviaMensagem: function (atendimentoId) {
            let vm = this;
            let auth = this.$cookies.get('auth');
            let $this = $('#'+ this.atendimentoId);
            let texto = String(remove_html($this.find('[name="texto"]').val())).trim();

            if (texto == '') return;

            vm.novaMsg = false;
            vm.$socket.emit('conversa-grava', {
                mensagem: texto,
                atendimentoId,
                auth,
                isAtendente: true,
            }, function (err) {
                vm.resetMensagem(err);
            });
        },
        resetMensagem: function (err) {
            let vm = this;
            let $this = $('#'+ this.atendimentoId);
            let auth = this.$cookies.get('auth');

            if (err) {
                vm.$sweet.alert( 'Atenção', err, 'error' );
                return;
            }

            $this.find('[name="texto"]').val('');
            $this.find('[name="texto"]').focus();

            if (vm.semInteracao) vm.$socket.emit('conversa-inativo-remove', {
                atendimentoId: vm.atendimentoId, tipo: 'atendente'
            });

            vm.semInteracao = false;

            clearInterval(vm.timerInativo[vm.atendimentoId]);
        },
        converteHtml: function (val) {
            return entities.decode(val);
        },
        converteHora: function (val) {
            let data = new Date(val);

            return data.toLocaleTimeString('pt-BR', {
                hour: '2-digit',
                minute: '2-digit'
            });
        },
        finalizaAtendimento: function (forcar = false, inatividade = false) {
            let vm = this;
            let msgInativo = '';

            if (inatividade) {
                // Se o botão ainda não tiver ativo, não faz nada
                if (!vm.botaoEncerraInativo[vm.atendimentoId]) return;

                msgInativo = ' por INATIVIDADE';
            }

            if (forcar || (vm.online && vm.finaliza)) {
                vm.$sweet.confirm('Tem certeza que deseja finalizar este atendimento'+msgInativo+'?',
                    'Atenção',
                    vm.finalizaJanela,
                    function () {},
                    'warning',
                    'Confirmar',
                    'Cancelar'
                );
            } else {

                if (vm.chatFinalizado && !vm.suporteCriado && vm.finaliza){
                    vm.$sweet.alert('Atenção', 'Você deve registrar o atendimento no Suporte Web', 'warning');
                } else {
                    let $this = $('#'+ vm.atendimentoId);

                    vm.$socket.emit('conversa-sair', vm.atendimentoId);
                    $('#'+ vm.atendimentoId).remove();

                    vm.limpaMemoria();
                }
            }
        },
        finalizaJanela: function (transferencia = false, sistema = {}) {
            let vm   = this;
            let auth = this.$cookies.get('auth');

            vm.$socket.emit('conversa-finaliza', {
                atendimentoId: vm.atendimentoId,
                inativo: vm.botaoEncerraInativo[vm.atendimentoId],
                sistema,
                transferencia
            }, err => {
                if (err) vm.$sweet.alert('Atenção', err, 'error');
            });

            vm.online = false;

            $('#'+ vm.atendimentoId +' .box-mensagem').remove();

            vm.limpaMemoria();
        },
        minimizar: function () {
            let $this = $('#'+ this.atendimentoId);

            $this.removeAttr('style');
            $this.find('.conteudo').css({ height: 0, margin: 0, overflow: 'hidden' });
            $this.find('.box-mensagem').css({ height: 0, margin: 0, overflow: 'hidden' });

            this.maximizado = false;
            this.minimizado = true;
        },
        restaurar: function () {
            let $this = $('#'+ this.atendimentoId);


            $this.removeAttr('style');
            $this.find('.conteudo').removeAttr('style');
            $this.find('.box-mensagem').removeAttr('style');

            this.maximizado = false;
            this.minimizado = false;
        },
        maximizar: function () {
            let $this = $('#'+ this.atendimentoId);

            $this.find('.conteudo').removeAttr('style');
            $this.find('.box-mensagem').removeAttr('style');

            this.maximizado = true;
            this.minimizado = false
        },
        changeFiles: function () {
            this.files = [];

            let input = document.getElementById('uploader_input'+ this.atendimentoId);
            let tamanhoTotal = 0;
            let rejeitados = 0;
            let files = [];
            let fileTypes = [
                'text/plain',
                // tipos de mime para xml
                'application/xml',
                'text/xml',
                'application/xhtml+xml',
                'application/atom+xml',
                'application/xslt+xml',
                'application/mathml+xml',
                'application/rss+xml',
                // fim xml
                'application/pdf',
                'image/jpeg',
                'image/png',
                'image/x-png',
                'image/x-citrix-png',
                'video/mp4',
                'image/gif',
            ];

            if ('files' in input) {

                if (input.files.length > 0) {
                    for (var i = 0; i < input.files.length; i++) {
                        let file = input.files[i];
                        tamanhoTotal += file.size;

                        if (file.type === '') {
                            let reExt = /(?:\.([^.]+))?$/;
                            let novaExt = ['re', 'sd', 'm01', 'm02', 'm03', 'm04', 'm05','m06', 'm07', 'm08', 'm09', 'm10', 'm11', 'm12',];
                            //Se o type tiver vazio e for as extensões acima, libera o envio
                            let fileName = String(file.name).toLowerCase();

                            if (novaExt.indexOf(reExt.exec(fileName)[1]) >= 0) {
                                files.push(file);
                            } else {
                                rejeitados++;
                            }
                        } else {
                            if (fileTypes.indexOf(file.type) >= 0) {
                                files.push(file);
                            } else {
                                rejeitados++;
                            }
                        }
                    }
                }

                if (rejeitados) {
                    let msgErro = 'Seus arquivos não foram inseridos pois alguns possuem um formato não suportado';
                    this.$sweet.alert('Atenção', msgErro, 'warning');
                    this.files = [];
                }

            }
            // Limita o tamanho máximo da transferência para 25MB
            if (tamanhoTotal > 26214400) {
                let msg = 'Tamanho total dos arquivos ultrapassa o limite permitido.<br/>';
                    msg+= 'O máximo permitido é de 25MB por transferência';

                this.$sweet.alert('Atenção', msg, 'error');
                this.files = [];
            } else {
                this.files = files;
            }
        },
        formataBytes: function (bytes, decimal) {
            if (bytes == 0) return '0 Bytes';

            let k = 1024,
                dm = decimal <= 0 ? 0 : decimal || 2,
                sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        toggleSuporte: function () {
            this.mostraSuporte = !this.mostraSuporte;
        },
        marcaBoxInfo: function () {
            let vm = this;
            let auth = vm.$cookies.get('auth');
            let boxInfoFixo = $('[name="box_info_fixo"]').is(':checked');

            vm.$socket.emit('atendente-info-box', {
                pessoaId: auth.dados.pessoaId,
                info: boxInfoFixo,
            });
        },
        avisoInativo: function (tempoInativo) {
            let vm = this;

            let msg = `O atendimento do cliente ${vm.nomeAtendido.toUpperCase()} está há mais de
                ${exibe_tempo_inativo(tempoInativo)} sem interação. Por favor, verifique o
                atendimento desse cliente.`;

            vm.$socket.emit('conversa-inativo',{atendimentoId: vm.atendimentoId, msg});

            let msg2 = 'Atendimento sem interação do ATENDENTE há mais de '+exibe_tempo_inativo(tempoInativo);

            vm.$socket.emit('conversa-alerta-inativo', {
                atendimentoId:
                vm.atendimentoId,
	            tipo: 'atendente',
	            msg: msg2
            });

            vm.semInteracao = true;
        },
        limpaMemoria: function () {
            clearInterval(this.timerInativo[this.atendimentoId]);
            clearInterval(this.tempoAtendimento);
        },
        abreTransferir: function () {
            this.$refs.transferir.abreTransferir();
        },
    },
    beforeDestroy() {
        this.limpaMemoria();
    },
    mounted() {
        let vm = this;
        let auth = vm.$cookies.get('auth');
        let $this = $('#'+vm.atendimentoId);

        let funcIniciaTimer = function() {
            // Inicia o timer de inatividade do atendente
            let tempoInativo =
                vm.info.linha_sistema == 2 ?
                    auth.dados.atendenteInativoUnicoOriginal :
                    auth.dados.atendenteInativoOriginal;

            let msTempoInativo = time_to_ms(tempoInativo);

            if (tempoInativo && msTempoInativo > 0) {
                clearInterval(vm.timerInativo[vm.atendimentoId]);

                vm.timerInativo[vm.atendimentoId] = setTimeout(
                    function () {
                        vm.avisoInativo(tempoInativo)
                    },
                    msTempoInativo
                );
            }
        }

        vm.$socket.on('conversa-atualiza', ({
                atendimentoId,
                isAtendente,
                clienteInativo,
                avisoAutomatico,
            }) => {
                if (!isAtendente) {
                    if (clienteInativo) {
                        $('#'+atendimentoId+' .icon-clock.disabled').removeClass('disabled');
                        vm.botaoEncerraInativo[atendimentoId] = true;
                        vm.clienteInativo = true;
                    } else {
                        $('#'+atendimentoId+' .icon-clock').addClass('disabled');
                        vm.botaoEncerraInativo[atendimentoId] = false;
                        vm.clienteInativo = false;
                    }
                }

                vm.$socket.emit('conversa-carrega', vm.atendimentoId, atendimento => {
                    vm.conversa = atendimento.mensagens;
                    // Verifica se a mensagem que recebeu é do cliente
                    if (!isAtendente && vm.atendimentoId === atendimentoId) {
                        // Se não for de um aviso automático, aplica as regras
                        if (!avisoAutomatico) {
                            funcIniciaTimer();

                            aviso(atendimento.mensagens, vm.nomeAtendido);
                            new Audio('/mp3/novaMensagem.wav').play().catch(error => {});
                            vm.novaMsg = true;
                        }
                    }
                });
            }
        );

        vm.$socket.on('atendimento-finalizado', data => {
            if (data.atendimentoId === vm.atendimentoId) {
                vm.$sweet.alert('Atenção', remove_html( data.msg ), 'warning');

                $this.find('.box-mensagem').remove();

                vm.online = false;
                vm.chatFinalizado = true;
                vm.mostraSuporte = true;
                vm.conversa.push(data.msg);
                vm.limpaMemoria();
            }
        });

        vm.$socket.on('atendimento-transferido-janela', atendimentoId => {
            vm.online = false;
            $('#'+atendimentoId).remove();
            vm.limpaMemoria();
        });

        this.$nextTick(() => {
            $this = $('#'+vm.atendimentoId);
            // Calcula tempo de atendimento
            vm.tempoAtendimento = setInterval(function () {
                let start = new Date($this.find('form.datahora').data('datetime'));
                let end = new Date();
                let diff = end.valueOf() - start.valueOf();
                let tempo = ms_to_time(diff);
                $this.find('.tempo').text(tempo);
            }, 5000);

            // Uploader
            let uploader = new SocketIOFileUpload(this.$socket);
                uploader.useBuffer = false; // false => usa base64 para transferência
                uploader.maxFileSize = 26214400; // Limitação de arquivos de até 25MB
                uploader.chunkSize = 1024 * 100; // Quebra o arquivo em vários pedaços de 100KB para o progressbar

            $('#uploader_button'+ vm.atendimentoId).on('click', function () {$('#uploader_input'+ vm.atendimentoId).trigger('click')});

            $('#uploader_submit'+ vm.atendimentoId).on('click', function () {
                uploader.submitFiles(document.getElementById('uploader_input'+ vm.atendimentoId).files);
            });

            uploader.addEventListener('start', event => {
                event.file.meta.atendente = 1;
                event.file.meta.atendimentoId = vm.atendimentoId;
                $('#uploader_submit'+ vm.atendimentoId).attr('disabled', 'disabled');
                vm.resetMensagem(null);
            });

            let inicioUpload = [];

            uploader.addEventListener('progress', event => {
                let loaded = event.bytesLoaded;
                let total = event.file.size;
                let perc = Math.round(loaded / total * 100);
                let arquivo = _.find(vm.files, { 'name': event.file.name });

                if (typeof inicioUpload[arquivo.name] == 'undefined') {
                    inicioUpload[arquivo.name] = new Date();
                }

                $('#'+ vm.atendimentoId +' .progresso.file'+ arquivo.lastModified +' .bar').css('width', perc +'%');

                // Tempo faltando
                let segundosPassados = (new Date().getTime() - inicioUpload[arquivo.name].getTime()) / 1000;
                let bytesSegundo = segundosPassados ? loaded / segundosPassados : 0 ;
                let bytesFaltando = total - loaded;

                let segundosFaltando = segundosPassados ? Math.ceil(bytesFaltando / bytesSegundo) : 0;
                    segundosFaltando = new Date(segundosFaltando * 1000).toISOString().substr(11, 8);

                let textoProgresso = perc +'% - Tempo estimado: ';
                    textoProgresso += segundosFaltando +' - ';
                    textoProgresso += vm.formataBytes(bytesSegundo, 2) +'/s';

                $('#'+ vm.atendimentoId +' .perc_progresso.file'+ arquivo.lastModified).text(textoProgresso);
            });

            uploader.addEventListener('error', event => {
                let msg = 'Houve um erro durante o upload dos arquivos:<br/><b>'+ event.message +'</b>';

                vm.$sweet.alert('Atenção', msg, 'error');
            });

            let uploadedFiles = 0;

            vm.$socket.on('siofu_complete', function () {
                funcIniciaTimer();
                vm.resetMensagem(null);

                uploadedFiles++;

                if (uploadedFiles >= vm.files.length) {
                    vm.files = [];
                    $('#uploader_input'+ vm.atendimentoId).val('');
                    $('#uploader_submit'+ vm.atendimentoId).removeAttr('disabled');
                }
            });
        })
    },
    updated () {
        if (this.rolar) {
            $('#'+ this.atendimentoId +' .conteudo').scrollTop(99999);
        }
    }
}

</script>
<style>
    .janela-chat .conteudo .mensagem .hide-atendente { display: none; }
    .icon-clock.disabled {color: #cecece; cursor: default;}
    .margin-right-10{margin-right: 10px;}
</style>
