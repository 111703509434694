<template>

    <div :id="'avisos'+atendimentoId" style="display:none;">
        <h3 class="h3" style="font-weight: bold; font-size: 1.5rem">Avisos</h3>
        <p v-for="(aviso, index) in avisos" :key="index" style="border-bottom: 1px solid #dedede; margin-bottom: 10px; padding-bottom: 10px">
            <span v-html="aviso.mensagem"></span><br/>
            <b style="font-size: 10px;">Aviso cadastrado por: {{ aviso.usuarioId }} - {{ aviso.nome }}<br/>
                <span v-if="aviso.usuarioAdicional">Usuário informado: {{ aviso.usuarioAdicional }}<br/></span>
                <span v-if="aviso.sistema">Sistema informado: {{ aviso.sistema }}<br/></span>
            </b>
        </p>
    </div>

</template>

<script>

export default {
    props: ['atendimentoId', 'info'],
    data() {
        return {
            avisos: []
        }
    },

    watch: {
        info: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.carregarAvisos();
                }
            }
        }
    },
    
     methods: {
        async carregarAvisos() {
            if (this.info && this.info.atendido_id < 80000) {
                this.avisos = await new Promise((resolve) => {
                    this.$socket.emit('aviso-suporteweb', {
                        pessoaId: this.info.atendido_id,
                        sistemaId: this.info.sistema_id,
                        usuarioId: this.info.usuario_adicional_id,
                    }, data => {
                        resolve(data);
                    });
                });

                if (this.avisos.length > 0) {
                    this.$parent.temAvisos = true;
                    $('#' + this.atendimentoId + ' .bt-aviso-suporte-web').trigger('click');
                }
            }
        }
    },
            
    mounted() {
        if (this.info) {
            this.carregarAvisos();
        }
    }
}

</script>