<template>

    <div id="offline">

        <div class="b-xs-12 align-center">

            <p v-if="atendimentoAlmocoHorarioInicial && atendimentoAlmocoHorarioFinal">
                Prezado cliente, <br />
                Neste momento todos os atendentes do sistema {{ $route.params.sistemaNome }} estão off-line. <br />
                Por enquanto utilize os outros canais de atendimento que a SCI disponibiliza:
	            Suporte aqui pela área do cliente SCI, e-mail e telefone. <br />
	            O horário de atendimento de sua REVENDA é das {{ this.atendimentoHoraInicial }} às {{ this.atendimentoAlmocoHorarioInicial }} e das {{ this.atendimentoAlmocoHorarioFinal }} às {{ this.atendimentoHoraFinal }}. <br />
	            Se estiveres em horário de atendimento, tente fazer o seguinte: <br />
	            - Aperte CTRL+F5 na tela anterior para atualizar o cache do navegador; <br />
	            - Entre pelo CHAT pela área do cliente e não por link direto; <br />
            </p>

			<p v-else>
				Prezado cliente, <br />
                Neste momento todos os atendentes do sistema {{ $route.params.sistemaNome }} estão off-line. <br />
                Por enquanto utilize os outros canais de atendimento que a SCI disponibiliza:
	            Suporte aqui pela área do cliente SCI, e-mail e telefone. <br />
				O horário de atendimento de sua REVENDA é das {{ atendimentoHoraInicial }} às {{ atendimentoHoraFinal }} sem fechar ao meio-dia. <br />
				Se estiveres em horário de atendimento, tente fazer o seguinte: <br />
	            - Aperte CTRL+F5 na tela anterior para atualizar o cache do navegador; <br />
	            - Entre pelo CHAT pela área do cliente e não por link direto; <br />
			</p>

        </div>

    </div>

</template>

<script>
export default {
    data() {
	    let auth = this.$cookies.get("auth");
	    let atendimentoHoraInicial;
	    let atendimentoHoraFinal;
		let atendimentoAlmocoHorarioInicial = false
		let atendimentoAlmocoHorarioFinal = false;

		function formatHour(hourToFormat) {
			const hourSplitet = hourToFormat.split(':');

			let string = `${hourSplitet[0]}h`;

			if (hourSplitet[1] !== "00")
				string = `${string}${hourSplitet[1]}`

			return string;
		}

		switch (process.env.VUE_APP_ATENDE) {
			case 'Visual': {
				atendimentoHoraInicial = formatHour(auth.dados.horaInicial);
				atendimentoHoraFinal = formatHour(auth.dados.horaFinal);

				break;
			}

			case 'Unico': {
				atendimentoHoraInicial = formatHour(auth.dados.horaInicialUnico);
				atendimentoHoraFinal = formatHour(auth.dados.horaFinalUnico);

				break;
			}

			case 'Web': {
				atendimentoHoraInicial = formatHour(auth.dados.horaInicialWeb);
				atendimentoHoraFinal = formatHour(auth.dados.horaFinalWeb);

				break;
			}
		}

	    if (auth.dados.horaAlmocoInicial)
		    atendimentoAlmocoHorarioInicial = formatHour(auth.dados.horaAlmocoInicial)

		if (auth.dados.horaAlmocoFinal)
			atendimentoAlmocoHorarioFinal = formatHour(auth.dados.horaAlmocoFinal)

        this.$cookies.remove('auth');

        return {
			atendimentoHoraInicial,
	        atendimentoHoraFinal,
	        atendimentoAlmocoHorarioInicial,
	        atendimentoAlmocoHorarioFinal
        };
    }
}
</script>
