<template>

    <div class="campo transferir" :style="[transferir ? {'display': 'block'} : {'display': 'none'}]">
        <b>Transferir atendimento:</b>
        <div class="campo flex">
            <div style="display: inline-block; width: 100%;">
                <b>Selecione o sistema:</b>
                <br class="clear" />
                <select2 v-model="sistemaTransfere" id="sistemas_cliente" @input="mudaSistemaTransferencia">
                    <option value="0">-- Selecione --</option>
                    <option v-for="sistema in sistemasCliente" :value="sistema.value"
                            :key="sistema.sistemaId"
                            :data-original="sistema.sistemaId"
                            :disabled="sistema.disabled"
                            :selected="info.sistema_original == sistema.sistemaId">
                        {{ sistema.label }}
                    </option>
                </select2>
            </div>
            <input type="button" value="OK" class="btn" @click="transfereAtendimento" v-show="!mostraAtendentes" />
        </div>
        <div class="campo flex" v-show="mostraAtendentes">
            <div style="display: inline-block; width: 100%;">
                <b>Selecione o atendente:</b> 
                <br class="clear" />
                <select name="usuario_transferir" id="usuario_transferir" v-show="sistemaTransfere > 0">
                    <option value="0">-- Selecione --</option>
                    <option v-for="atendente in atendentes" :value="atendente.pessoaId" :key="atendente.pessoaId" :data-id="atendente.pessoaId">
                        {{ atendente.nome }}
                    </option>
                </select>
            </div>
            <input type="button" value="OK" class="btn" @click="transfereAtendimento" />
        </div>
    </div>

</template>

<script>

import Select2 from '../Select2';

export default {
    props: ['atendimentoId', 'info'],
    data() {
        return {
            atendentes: [],
            listaAtendenteKey: 0,
            transferir: false,
            sistemasCliente: [],
            sistemaTransfere: 0,
            mostraAtendentes: true,
        }
    },
    components: {
        Select2,
    },
    methods: {
        transfereAtendimento: async function () {
            let vm = this;
            let $this = $('#'+ this.atendimentoId);
            let auth = this.$cookies.get('auth');
            let usuario = {
                nome: $this.find('[name="usuario_transferir"] :selected').text().trim(),
                id: $this.find('[name="usuario_transferir"] :selected').data('id'),
            };

            if ($this.find('#sistemas_cliente').val() == '0') {
                this.$sweet.alert('Atenção', 'Você deve selecionar um sistema antes de transferir', 'warning');
                return;
            }

            // Tem esse status quando o atendente vai transferir o usuário para outra fila de sistema
            if (!this.mostraAtendentes) {
                let sistemaSelecionado = $this.find('#sistemas_cliente :selected').text();
                let sistemaId = $this.find('#sistemas_cliente :selected').data('original');

                await new Promise(resolve => {
                    vm.$socket.emit('cliente-verifica-transferencia',
                        { atendimentoId: this.atendimentoId, sistemaId },
                        (nome) => {
                            if (nome) {
                                vm.$sweet.alert('Atenção', `Já existe uma solicitação de atendimento<br/>para o sistema
                                solicitado com o usuário ${nome} <br/>Por favor, solicite atendimento
                                para outro sistema.<br/>`, 'warning');
                                vm.transferir = false;
                                return;
                            }
                            resolve();
                        }
                    );
                });

                vm.$sweet.confirm(
                    `Você irá transferir o cliente para o sistema ${sistemaSelecionado}.
                    Ele irá retornar para a fila de espera automaticamente e este atendimento
                    será finalizado normalmente. Tem certeza que deseja fazer isto?`,
                    'Atenção',
                    function () {
                        if (!vm.info) {
                            vm.$sweet.alert('Atenção', `Não foi possível encontrar as informações
                                deste atendimento, por favor, recarregue sua janela`, 'warning');
                            vm.transferir = false;
                            return;
                        }

                        vm.$parent.finalizaJanela(
                            true, {
                                sistemaId: sistemaId,
                                agrupador: Number($this.find('#sistemas_cliente').val()),
                                nome: sistemaSelecionado,
                            }
                        );
                    },
                    function () { vm.transferir = false; },'warning', 'Confirmar', 'Cancelar'
                );

                return;
            }

            if ($this.find('[name="usuario_transferir"]').val() == '0') {
                this.$sweet.alert('Atenção', 'Você deve selecionar um atendente antes de transferir', 'warning');
                return;
            }

            vm.$sweet.confirm(
                `Tem certeza que deseja transferir o atendimento para ${usuario.nome}?`,
                'Atenção',
                function () {
                    if (!vm.info) {
                        let msgAviso = 'Não foi possível encontrar as informações deste atendimento, por favor, recarregue sua janela';

                        vm.$sweet.alert('Atenção', msgAviso, 'warning');
                        vm.transferir = false;
                        return;
                    }

                    vm.$socket.emit('conversa-transferir', {
                        atendimentoId: vm.atendimentoId,
                        chatRow: vm.info,
                        atendenteId: usuario.id,
                        auth
                    }, err => {
                        if (err) vm.$sweet.alert('Atenção', err, 'warning');
                    });

                    vm.$parent.limpaMemoria();
                    vm.$parent.$destroy();
                    $this.remove();
                },
            function () { vm.transferir = false; },'warning', 'Confirmar', 'Cancelar'
            );
        },
        abreTransferir: function () {
            this.transferir = !this.transferir;

            if (!this.transferir) return;

            this.carregaTransfereSistemas();
            //this.carregaTransfereAtendentes();
        },
        carregaTransfereSistemas: function () {
            let vm = this;
            let $this = $('#'+ this.atendimentoId);

            if (vm.sistemasCliente.length > 0) return;

            vm.$socket.emit('cliente-sistema-transferencia', vm.atendimentoId, res => {
                if (res.length <= 0) return [];
                vm.sistemasCliente = res;

                setTimeout(function () {
                    $this.find('#sistemas_cliente').trigger('change');
                }, 500);
            });
        },
        carregaTransfereAtendentes: function () {
            let vm = this;
            let auth = this.$cookies.get('auth');

            this.sistemaTransfere = Number($('#'+ this.atendimentoId).find('#sistemas_cliente :selected').data('original'));

            if (this.sistemaTransfere !== this.info.sistema_original) {
                this.mostraAtendentes = false;
                return;
            }
            vm.$socket.emit('atendente-disponiveis', {
                pessoaId : vm.info.atendido_id,
                sistemaId: Number($( '#'+ this.atendimentoId).find('#sistemas_cliente').val()),
                revendaId: vm.info.revenda_chat,
                linhaSistema: vm.info.linha_sistema,
                classificacao: vm.info.classificacao,
            }, res => {
                vm.atendentes = (_.orderBy(res, 'nome', 'asc')).filter(function (n) {
                    // Elimina o usuário atual do atendimento da lista
                    if (n !== null && n.connId != auth.connId) return n;
                });
            });
        },
        mudaSistemaTransferencia: function () {
            this.sistemaTransfere = Number($('#'+ this.atendimentoId)
                .find('#sistemas_cliente :selected').data('original'));

            if (this.sistemaTransfere !== this.info.sistema_original) {
                this.mostraAtendentes = false;
                return;
            }

            this.mostraAtendentes = true;
            this.carregaTransfereAtendentes();
        },
    },
    mounted() {
        let vm = this;

        /**
         * Toda vez que algum atendente logar irá recarregar o select com a lista de atendentes disponíveis para transferencia
         */
        vm.$socket.on('recarrega-select-atendentes', () => {
            vm.carregaTransfereAtendentes();
        });

    }
}

</script>