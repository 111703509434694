<template>

    <div id="w2o">

        <div class="align-center">

            <input type="submit" class="bt" value="debug" v-on:click="debug()" />

        </div>

        <br />

        <div class="b-xs-12">
            Remove Aguardando<br />
            AtendimentoId: <input type="text" v-model="agAtendimentoId" /><br />
            <br />
            <input type="button" class="bt" value="Remove Travado" v-on:click="removeAguardando()" />
        </div>

        <br />

        <div class="b-xs-12">
            Remove Fila de espera<br />
            RevendaId: <input type="text" v-model="feRevendaId" /><br />
            SistemaId: <input type="text" v-model="feSistemaId" /><br />
            AtendimentoId: <input type="text" v-model="feAtendimentoId" /><br />
            <br />
            <input type="button" class="bt" value="Remove Travado" v-on:click="removeFilaEspera()" />
        </div>

    </div>

</template>

<script>

    export default {
        name: 'debug',
        data() {
            return {
                info: '',
                retorno_db: '',
                agAtendimentoId: null,
                feRevendaId: null,
                feSistemaId: null,
                feAtendimentoId: null,
            }
        },
        methods: {
            debug() {
                this.$socket.emit('debug-ativos', null, r => console.log(r));
            },
            removeAguardando() {
                if (!this.agAtendimentoId) return;

                this.$socket.emit('debug-remove-aguardando', this.agAtendimentoId, res => console.log(res));
            },
            removeFilaEspera() {
                if (!this.feRevendaId) return;
                if (!this.feSistemaId) return;
                if (!this.feAtendimentoId) return;

                this.$socket.emit('debug-remove-fila-espera', {
                    revendaId: Number(this.feRevendaId),
                    sistemaId: Number(this.feSistemaId),
                    atendimentoId: this.feAtendimentoId
                }, res => console.log(res));
            }
        },
        mounted() {
        }
    }
</script>

