import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
import VueResource from "vue-resource";
import AsyncComputed from "vue-async-computed";
import _ from "lodash";

import JanelaClass from "./components/JanelaChat.vue";
import SuporteWebView from "./components/janela-chat/SuporteWebView.vue";
import ViewConversa from "./components/ViewConversa.vue";

import App from "./App.vue";
import LoginForm from "./components/LoginForm.vue";
import LoginCheck from "./components/LoginCheck.vue";
import FormCliente from "./components/FormCliente.vue";
import SalaEspera from "./components/SalaEspera.vue";
import Atendente from "./components/Atendente.vue";
import Atendimento from "./components/Atendimento.vue";
import Conversa from "./components/Conversa.vue";
import Offline from "./components/Offline.vue";
import Debug from "./components/Debug.vue";
import ConsultaAtendimentos from "./components/ConsultaAtendimentos";
import ConsultaAtendentes from "./components/ConsultaAtendentes";

import io from "socket.io-client";

Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(VueRouter, { history: true });
Vue.use(VueCookies);
Vue.use(AsyncComputed);
Vue.use(_);

const Pagina404 = Vue.component("pagina404", {
  render: (h) => h(App),
  beforeCreate() {
    this.$cookies.remove("auth");
    this.$cookies.remove("dados_form");
    this.$router.push("/");
  },
});

const Logout = Vue.component("logout", {
  render: (h) => h(App),
  beforeCreate() {
    this.$cookies.remove("auth");
    this.$cookies.remove("dados_form");
    this.$router.push("/");
  },
});

let router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", name: "home", component: LoginForm },
    { path: "/login", name: "login", component: LoginCheck },
    { path: "/logout", name: "logout", component: Logout },
    { path: "/cliente", name: "cliente", component: FormCliente },
    { path: "/sala-espera", name: "sala-espera", component: SalaEspera },
    { path: "/atendimento", name: "atendimento", component: Atendimento },
    { path: "/atendente", name: "atendente", component: Atendente },
    { path: "/conversa/:sala", name: "conversa", component: Conversa },
    { path: "/offline", name: "offline", component: Offline },
    {
      path: "/consulta-atendimentos",
      name: "consulta-atendimentos",
      component: ConsultaAtendimentos,
    },
    {
      path: "/consulta-atendentes",
      name: "consulta-atendentes",
      component: ConsultaAtendentes,
    },
    { path: "*", component: Pagina404 },
    // Debugs
    { path: "/debug", name: "debug", component: Debug },
    { path: "/debug/remove_travado", name: "remove_travado", component: Debug },
    { path: "/debug/teste_db", name: "teste_db", component: Debug },
    { path: "/debug/connections", name: "connections", component: Debug },
  ],
});

let sweet = require("./assets/Sweet");
let socket = io(process.env.VUE_APP_BACKEND_URL,{
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 3000,
  reconnectionDelayMax: 12000,
  reconnectionAttempts: 10,
});
socket.connect(process.env.VUE_APP_BACKEND_URL);

if (process.env.VUE_APP_ENV == "dev") {

  VueCookies.config("1d");
} else{

  VueCookies.config("1d", "/", process.env.VUE_APP_URL, true, "None");
}

Vue.prototype.$sweet = sweet;
Vue.prototype.$socket = socket;
Vue.prototype.$janelas = Vue.extend(JanelaClass);
Vue.prototype.$viewconversa = Vue.extend(ViewConversa);
Vue.prototype.$suportewebview = Vue.extend(SuporteWebView);

Vue.prototype._SISTEMA_ID_WEB = [
  30, 41, 42, 40, 43, 33, 32, 38, 31, 45, 34, 100, 101, 102, 103, 104, 105, 106,
  107, 129, 132, 999, 133, 123, 215, 212,208,209,213,214,202,203,204,206,207
];
Vue.prototype._SISTEMA_ID_UNICO = [
  50, 52, 60, 53, 54, 56, 59, 57, 58, 61, 62, 63, 64, 65, 67, 68, 69, 1500, 1501, 1502, 1503, 1504, 1505, 1506, 1507, 1508, 1509, 1511, 1516, 1512,
];
Vue.prototype._SISTEMA_ID_VISUAL = [
  8, 9, 11, 12, 13, 14, 15, 16, 18, 19, 22, 23, 25, 35, 36, 37, 39, 108, 109,
  110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 124, 500,
];

// Se não conseguir se conectar com o servidor do chat
socket.on("connect_error", function () {
  let msg =
    "O servidor do atendimento on-line está fora do ar no momento." + "\n";
  msg += "Tente entrar novamente dentro de alguns instantes";
  sweet.alert("Desculpe o transtorno", msg, "error", "OK", false);
  socket.close();
});

// Desabilita o F5 e CLTR+R
if (process.env.VUE_APP_ENV !== 'dev')
  document.onkeydown = function (event) {
    switch (event.keyCode) {
      case 116: //F5 button
        event.returnValue = false;
        return false;
      case 82: //R button
        if (event.ctrlKey) {
          event.returnValue = false;
          return false;
        }
    }
  };

new Vue({
  router,
  http: { emulateJSON: true },
  render: (h) => h(App),
}).$mount("#app");
