<template>
    <div id="view-conversa">
        <div v-html="conversa"></div>
    </div>
</template>

<script>
    const Entities = require('html-entities').AllHtmlEntities;
    const entities = new Entities();

    export default {
        name  : "ViewConversa",
        props : ['atendimentoId'],
        data() {
            return {
                conversa: ''
            }
        },
        beforeMount: async function() {
            let vm = this;

            await this.$socket.emit('busca-conversa', this.atendimentoId, data => {
                vm.conversa = entities.decode(data.mensagem);
            });
        },
    }
</script>

<style scoped>

</style>
