<template>

    <div id="ConsultaAtendimentos">

        <div id="box-configuracoes" style="display: none; width: 100%; max-width: 1200px; padding: 15px;">
            <b>Canais de atendimento: </b>
            {{ configCanal == 'P' ? 'Revendas' : ''}}
            {{ configCanal == 'C' ? 'Clientes' : ''}}
            {{ configCanal == 'A' ? 'Clientes e Revendas' : ''}}
            <br />
            <b>Sistemas:</b><br />
            <div class="atendente-sistemas" style="display: flex">
                <div v-if="_SISTEMA_ID_VISUAL.some(id => configSistemas.includes(id))" style="padding: 10px">
                    <b>VISUAL</b>:
                    <br />
                    <div v-for="s in configSistemas" :key="s">
                        {{ _SISTEMA_ID_VISUAL.indexOf(s) >= 0 ? sistema(s) : null }}
                    </div>
                    <br />
                    <b v-if="configSistemas.indexOf(9991) >= 0 || configSistemas.indexOf(9993) >= 0">
                        TI/INFRASTRUTURA/CLOUD - Visual<br />
                    </b>
                    <b v-if="configSistemas.indexOf(9992) >= 0 || configSistemas.indexOf(9993) >= 0">
                        TI/INFRASTRUTURA/CLOUD - Único
                    </b>
                </div>
                <div v-if="_SISTEMA_ID_WEB.some(id => configSistemas.includes(id))" style="padding: 10px">
                    <b>WEB</b>:
                    <br />
                    <div v-for="s in configSistemas" :key="s">
                        {{ _SISTEMA_ID_WEB.indexOf(s) >= 0 && s != 132 && s != 129 ? sistema(s) : null }}
                    </div>
                    <div v-if="configSistemas.indexOf(129) >= 0 || configSistemas.indexOf(132) >= 0">
                        SCI APP 4.0
                    </div>
                    <br />
                </div>
                <div v-if="_SISTEMA_ID_UNICO.some(id => configSistemas.includes(id))" style="padding: 10px">
                    <b>ÚNICO</b>:
                    <br />
                    <div v-for="s in configSistemas" :key="s">
                        {{ _SISTEMA_ID_UNICO.indexOf(s) >= 0 ? sistema(s) : null }}
                    </div>
                    <br />
                </div>
            </div>
        </div>

        <h2 class="h2 align-right">
            <span class="float-left">Consulta log atendentes</span>
            <a href="#" class="bt bt-voltar" style="font-size: .8rem" @click="voltarAtendente">
                Voltar
            </a>
        </h2>

        <h3 class="h3">Filtros</h3>
        <table class="parvus-table-base grid condensed vertical-separator filtros">
            <tbody>
                <tr>
                    <td width="300"><b>Atendente</b></td>
                    <td width="300"><b>Revenda</b></td>
                    <td width="300"><b>Sistema</b></td>
                    <td width="175"><b>Status</b></td>
                    <td width="175"><b>Data</b></td>
                </tr>
                <tr>
                    <td>
                        <input type="text" id="atendentes" name="atendentes" />
                    </td>
                    <td>
                        <select2 name="revenda" id="revenda">
                            <option value="">--- Selecione</option>
                            <option v-for="item in revendas" :value="item.representanteId" :key="item.representanteId">
                                {{ item.representanteId }} - {{ item.nome }}
                            </option>
                        </select2>
                    </td>
                    <td>
                        <select2 name="sistema" id="sistema">
                            <option value="">--- Selecione</option>
                            <option v-for="item in sistemas" :value="item.sistemaId" :key="item.sistemaId">
                                {{ item.sistemaId }} - {{ item.nome }}
                            </option>
                        </select2>
                    </td>
                    <td>
                        <select2 name="status" id="status">
                            <option value="">--- Selecione</option>
                            <option value="1">Fez login</option>
                            <option value="2">Entrou na fila de atendimento</option>
                            <option value="3">Fechou o atendimento</option>
                        </select2>
                    </td>
                    <td>
                        <input type="text" class="data" name="data_inicio" id="data_inicio"
                               :value="formataData(filtros.dataInicio)" style="padding-right: 5px;" />
                        &nbsp;&nbsp;à&nbsp;&nbsp;
                        <input type="text" class="data" name="data_fim" id="data_fim"
                               :value="formataData(filtros.dataFim)" style="padding-right: 5px;" />
                    </td>
                </tr>
                <tr>
                    <td colspan="6" class="align-center">
                        <button type="submit" class="bt" @click="buscaLog(true)">Filtrar</button>
                        <button type="reset" class="bt" @click="removeFiltros">Retirar filtros</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <paginacao
            :quantidade="paginacao.quantidade"
            :total="paginacao.total"
            :paginas="paginacao.paginas"
            :atual="paginacao.atual"
            :limiteName="'limite1'"
            :paginaName="'pagina1'"
            :componente="'consultaLog'"
        ></paginacao>

        <p class="aviso msgFiltro" v-if="Object.keys(filtros).length > 0">
            Os registros exibidos abaixo estão sendo filtrados.
            <a href="javascript:void(0);" class="parvus-table-filtro-remove" @click="removeFiltros">
                Clique para retirar todos os filtros.
            </a>
        </p>

        <table class="parvus-table-base grid condensed vertical-separator">
            <thead>
                <tr>
                    <th class="align-left parvus-table-coluna-ordena" width="30%" style="cursor: pointer;"
                        @click="mudaOrdem('id')"
                    >
                        <a href="javascript:;">Atendente</a>&nbsp;
                        <span class="icon-seta-baixo"
                              v-show="order.campo === 'id' && order.ordem === -1">
                        </span>
                        <span class="icon-seta-cima"
                              v-show="order.campo === 'id' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="15%" >
                        <a href="javascript:;">Status</a>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="25%" style="cursor: pointer;"
                        @click="mudaOrdem('revenda')"
                    >
                        <a href="javascript:;">Revenda</a>&nbsp;
                        <span class="icon-seta-baixo"
                              v-show="order.campo === 'revenda' && order.ordem === -1">
                        </span>
                        <span class="icon-seta-cima"
                              v-show="order.campo === 'revenda' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="center parvus-table-coluna-ordena" width="10%" style="cursor: pointer;"
                        @click="mudaOrdem('data_hora_entrada')"
                    >
                        <a href="javascript:;">Data/hora</a>&nbsp;
                        <span class="icon-seta-baixo"
                              v-show="order.campo === 'data_hora_entrada' && order.ordem === -1">
                        </span>
                        <span class="icon-seta-cima"
                              v-show="order.campo === 'data_hora_entrada' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="5%">
                        <a href="javascript:;">Configurações</a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr :class="'id_'+item.__id" v-for="item in resultados" :key="item.__id">
                    <td align="left" oldtitle="Atendente" title="">
                        {{ atendente(item.id) }}
                    </td>
                    <td align="left" oldtitle="Status" title="">
                        {{ status(item.status) }}
                    </td>
                    <td align="left" oldtitle="Revenda" title="">{{ revenda(item.revenda) }}</td>
                    <td align="center" oldtitle="Data/hora" title="" v-if="item.status !== 3">
                        {{ formataData(item.data_hora_entrada, true) }}
                    </td>
                    <td align="center" oldtitle="Data/hora" title="" v-else>
                        {{ formataData(item.data_hora_saida, true) }}
                    </td>
                    <td align="center" oldtitle="Configurações" title="">
                        <a href="javascript:;" class="acao"
                           v-show="item.sistemas.length > 0 && item.canal" @click="verConfiguracoes(item.sistemas, item.canal)"
                        >
                            <span class="icon-visualizar"></span><span class="visuallyhidden">Ver conversa</span>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

        <p class="aviso msgFiltro" v-if="Object.keys(filtros).length > 0">
            Os registros exibidos abaixo estão sendo filtrados.
            <a href="javascript:void(0);" class="parvus-table-filtro-remove" @click="removeFiltros">
                Clique para retirar todos os filtros.
            </a>
        </p>

        <paginacao
                :quantidade="paginacao.quantidade"
                :total="paginacao.total"
                :paginas="paginacao.paginas"
                :atual="paginacao.atual"
                :limiteName="'limite2'"
                :paginaName="'pagina2'"
                :componente="'consultaLog'"
        ></paginacao>

    </div>

</template>

<script>
    require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');
    require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');

    import Select2 from './Select2.vue';
    import Paginacao from './Paginacao.vue';

    let _ = require('lodash');

    export default {
        name: 'ConsultaAtendimentos',
        data() {
            let d = new Date();
            let dAtualIni = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
            let dAtualFim = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);

            return {
                filtros: {
                    dataInicio: dAtualIni,
                    dataFim:    dAtualFim,
                },
                atendentes: {},
                revendas: {},
                sistemas: {},
                resultados: {},
                paginacao: {
                    paginas: 1,
                    quantidade: 50,
                    total: 0,
                    atual: 1,
                },
                order: {
                    campo: '_id',
                    ordem: -1
                },
                configCanal: [],
                configSistemas: [],
            }
        },
        components: {
            Select2,
            Paginacao
        },
        methods: {
            voltarAtendente: function () {
                this.$router.push('/atendimento');
            },
            msToTime: function (ms) {
                if (isNaN(ms) || Number(ms) < 1000) return null;
                return ms_to_time(ms, true);
            },
            formataData: function (data, hora = false) {
                return !data ? '' : exibe_data(data, hora);
            },
            alteraFiltro: async function (data) {
                //Filtro quantidade de registros por página
                if (typeof data.quantidade != 'undefined') {
                    this.filtros.skip = 0;
                    this.filtros.limit = data.quantidade;
                    this.paginacao.atual = 1;
                }
                //Filtro pagina
                if (typeof data.pagina != 'undefined') {
                    this.filtros.skip    = (data.pagina-1) * this.paginacao.quantidade;
                    this.paginacao.atual = data.pagina;
                }
                //Filtro atendente
                if (typeof data.id != 'undefined') {
                    if (data.id != '') {
                        let split = data.id.split(' - ');
                        this.filtros.id = Number(split[0]);
                    } else {
                        delete this.filtros.id;
                    }
                }
                //Filtro revenda
                if (typeof data.revenda != 'undefined') {
                    if (data.revenda != '') {
                        this.filtros.revenda = data.revenda;
                    } else {
                        delete this.filtros.revenda;
                    }
                }
                //Filtro sistema
                if (typeof data.sistema != 'undefined') {
                    if (data.sistema != '') {
                        this.filtros.sistema = data.sistema;
                    } else {
                        delete this.filtros.sistema;
                    }
                }
                //Filtro status
                if (typeof data.status != 'undefined') {
                    if (data.status != '') {
                        this.filtros.status = data.status;
                    } else {
                        delete this.filtros.status;
                    }
                }
                //Filtro data entrada inicio
                if (typeof data.dataInicio != 'undefined') {
                    if (data.dataInicio != '') {
                        let exData = data.dataInicio.split('/');
                        let dataDb = `${exData[2]}-${exData[1]}-${exData[0]}`;
                        this.filtros.dataInicio = new Date(dataDb+'T03:00:00Z');
                    } else {
                        delete this.filtros.dataInicio;
                    }
                }
                //Filtro data saida fim
                if (typeof data.dataFim != 'undefined') {
                    if (data.dataFim != '') {
                        let exData = data.dataFim.split('/');
                        let dataDb = `${exData[2]}-${exData[1]}-${exData[0]}`;
                        this.filtros.dataFim = new Date(dataDb+'T23:59:59Z');
                    } else {
                        delete this.filtros.dataFim;
                    }
                }
            },
            removeFiltros: function () {
                this.filtros = {};

                $('.filtros input').val('');
                $('.filtros select').val('').trigger('change');

                this.buscaLog();
            },
            mudaOrdem: function (campo) {
                if (this.order.campo == campo) {
                    if (this.order.ordem === 1) {
                        this.order.ordem = -1;
                    } else {
                        this.order.ordem = 1;
                    }
                } else {
                    this.order.campo = campo;
                    this.order.ordem = 1;
                }

                this.buscaLog();
            },
            buscaLog: function (reset = false) {
                let vm = this;
                let auth = this.$cookies.get('auth');

                this.$socket.emit('busca-log-atendentes', {
                        filtros: vm.filtros, order: vm.order, revenda: auth.dados.representanteOriginalId
                    }, function (res) {
                        vm.resultados = res.results;
                        vm.paginacao.total = res.total;
                        vm.paginacao.paginas = Math.ceil(vm.paginacao.total/vm.paginacao.quantidade);

                        if (!vm.paginacao.paginas) vm.paginacao.paginas = 1;

                        if (reset) vm.paginacao.atual = 1;
                    }
                );

                delete this.filtros.skip;
            },
            verConfiguracoes: function (sistemas, canal) {
                this.configSistemas = sistemas;
                this.configCanal = canal;

                $('#box-configuracoes').fancybox().click();
            },
            atendente: function (id) {
                let a = _.find(this.atendentes, function (v, k) {
                    return k == id;
                });

                if (!a) return null;

                return id+' - '+a;
            },
            revenda: function (id) {
                let r = _.find(this.revendas, {representanteId: id});

                if (!r) return null;

                return `${id} - ${r.nome}`;
            },
            sistema: function (id) {
                if (id == 999) return 'TI/INFRAESTRUTURA/CLOUD - Visual';
                if (id == 9991) return 'TI/INFRAESTRUTURA/CLOUD - Visual';
                if (id == 9992) return 'TI/INFRAESTRUTURA/CLOUD - Único';

                if (!this.sistemas[id]) return null;

                let sistema = _.find(this.sistemas, {sistemaId: id});

                if (!sistema) return null;

                return sistema.nome;
            },
            status: function (id) {
                switch (id) {
                    case 1: return 'Fez login';
                    case 2: return 'Entrou na fila de atendimento';
                    case 3: return 'Fechou o atendimento';
                }
            },
        },
        mounted() {
            let vm = this
            let auth = this.$cookies.get('auth');

            setTimeout(function () {
                vm.buscaLog();
            }, 500);

            $('.hora').mask('99:99');
            $('.data').mask('99/99/9999');

            this.$socket.emit('busca-select-revenda', auth.dados.representanteOriginalId, res => {
                vm.revendas = res;
            });

            this.$socket.emit('busca-sistema', null, res => {
                vm.sistemas = res;
            });

            this.$socket.emit('busca-pessoa', {
                    revendaId: auth.dados.representanteOriginalId
                },
                res => {
                    let result = [];

                    vm.atendentes = {};

                    _.forEach(res, function(val) {
                        if (val.pessoaId >= 80000) {
                            result.push(val.pessoaId+' - '+val.nomeCompleto);
                            vm.atendentes[val.pessoaId] = val.nomeCompleto;
                        }
                    });

                    $('#atendentes').autocomplete({ source: result });
                }
            );

            this.$nextTick(async function () {
                await new Promise(resolve => {
                    this.$socket.emit('sistema-atendimento', {}, result => {
                        vm.sistemas = result;
                        resolve(true);
                    });
                });
            });

            //Filtros
            $('#atendentes').change(function () {
                vm.alteraFiltro({ id: $(this).val() });
            });
            $('#revenda').change(function () {
                vm.alteraFiltro({ revenda: $(this).val() });
            });
            $('#sistema').change(function () {
                vm.alteraFiltro({ sistema: $(this).val() });
            });
            $('#status').change(function () {
                vm.alteraFiltro({ status: $(this).val() });
            });
            $('#data_inicio').change(function () {
                vm.alteraFiltro({ dataInicio: $(this).val() });
            });
            $('#data_fim').change(function () {
                vm.alteraFiltro({ dataFim: $(this).val() });
            });

            $('input, select').keyup(function (e) {
                if (e.which == 13 || e.keyCode == 13) {
                    vm.buscaLog(true);
                }
            });
        }
    }
</script>

<style>
    .principal {padding-bottom: 15px !important;}
    button[type="reset"] { color: #fff; }
</style>
