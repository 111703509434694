<template>

    <div class="b-xs-12" v-if="temAvisos && mostraAvisoTodos" style="border: 1px solid #b9b9b9;background: #eaeaea;">
        <h1 class="h1" style="padding: 5px 10px 0 10px;font-size: 1.3rem;">
            Avisos importantes - (clique em cima do título para visualizar)
        </h1>
        <badger-accordion class="accordion" style="padding: 0 10px;">
            <badger-accordion-item v-for="aviso in avisos" :key="aviso.id" @open="marcaAvisoVisto(aviso.id)">
                <template slot="header">
                    <i class="aviso-novo" v-if="!aviso.visto">NOVO!</i>
                    {{converteDataAviso(aviso.dataMaior)}} - {{aviso.titulo}}
                </template>
                <template slot="content">
                    <p v-html="aviso.texto"></p>
                </template>
                <br class="clear" />
            </badger-accordion-item>
        </badger-accordion>
    </div>

</template>

<script>

import { BadgerAccordion, BadgerAccordionItem } from 'vue-badger-accordion';

export default {
    props: ['temAvisos', 'mostraAvisoTodos', 'avisos'],
    components: {
        BadgerAccordion,
        BadgerAccordionItem,
    },
    methods: {
        converteDataAviso: function (v) { return this.$parent.converteDataAviso(v) },
        marcaAvisoVisto: function (v) { this.$parent.marcaAvisoVisto(v); },
    },
}

</script>